import { CandidateProfile } from "../interfaces/profile";
import axios from "./axiosConfig";

export const fetchCandidateProfile =
  async (): Promise<CandidateProfile | void> => {
    try {
      const response = await axios.get<CandidateProfile>("individuals/me");
      return response.data;
    } catch (error) {
      console.error("Error:", error);
    }
  };
