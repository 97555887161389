import { useEffect } from "react";
import loadingPage from "../../../store/actions/loading";
import auth from "../../../utils/auth";
import useActions from "../../../utils/customHook/useActions";
import "./applicationPage.s.scss";
import ApplicationCandidatePage from "./candidate/applicationCandidate";
import ApplicationCompanyPage from "./company/applicationCompany";

interface ApplicationProps {
  handleOpen: () => void;
}

const ApplicationPage: React.FC<ApplicationProps> = ({ handleOpen }) => {
  const loadingPageAction = useActions(loadingPage);

  const isCompanyUser = auth.isCompanyUser();

  useEffect(() => {
    loadingPageAction();
  }, []);

  return (
    <>
      {isCompanyUser ? (
        <ApplicationCompanyPage handleOpen={handleOpen} />
      ) : (
        <ApplicationCandidatePage handleOpen={handleOpen} />
      )}
    </>
  );
};

export default ApplicationPage;
