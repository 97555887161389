import _ from "lodash";
import { ArrowRight } from "phosphor-react";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { PlusCircleIcon } from "../../../../assets/svg";
import ButtonComponent from "../../../../components/button/button";
import ModalComponent from "../../../../components/modal/modal";
import "./studentProfileDetails.s.scss";

interface IProps<T extends { startDate: string }> {
  title: string;
  handleAddNew?: () => void;
  items: T[];
  renderItem: (item: T, index: number) => JSX.Element;
  maxDisplay?: number;
}

const StudentProfileDetails = <T extends { startDate: string }>({
  title,
  handleAddNew,
  items,
  renderItem,
  maxDisplay = 3,
}: IProps<T>) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Sort items by startDate from newest to oldest
  const sortedItems = [...items].sort(
    (a, b) =>
      new Date(b.startDate.split("/").reverse().join("-")).getTime() -
      new Date(a.startDate.split("/").reverse().join("-")).getTime()
  );

  const displayedItems = sortedItems.slice(0, maxDisplay);
  const hasMoreItems = items.length > maxDisplay;

  // Functions to handle modal
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  return (
    <div className="student-profile-detail student-wrapper-more-info">
      <div className="student-profile-detail-title">
        <h3>{title}</h3>
        {handleAddNew && (
          <ButtonComponent
            className="add-btn"
            icon={<img src={PlusCircleIcon} alt="add-icon" />}
            onClick={handleAddNew}
          />
        )}
      </div>
      {items?.length > 0 && (
        <>
          <div className="student-profile-detail-content">
            {_.map(displayedItems, (item, index: number) =>
              renderItem(item, index)
            )}
          </div>
          {hasMoreItems && (
            <div className="show-more-section">
              <ButtonComponent
                className="show-more-btn"
                title={"Show more"}
                onClick={showModal}
                iconPosition="end"
                icon={<ArrowRight />}
              />
            </div>
          )}
        </>
      )}

      {/* Modal to show all items */}
      <ModalComponent
        title={
          <div className="header-custom">
            {title}
            {!isMobile && (
              <ButtonComponent
                className="add-new-btn"
                onClick={handleAddNew}
                title={`Add ${title}`}
              />
            )}
          </div>
        }
        width={680}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="showmore-modal"
      >
        <div className="showmore-content">
          {_.map(sortedItems, (item, index: number) => renderItem(item, index))}
        </div>
      </ModalComponent>
    </div>
  );
};

export default StudentProfileDetails;
