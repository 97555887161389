import { AxiosResponse } from "axios";
import axios from "./axiosConfig";
import { Certificate } from "../interfaces/profile";

export const handleAddCertificate = async (
  requestBody: Omit<Certificate, "id">
): Promise<boolean | void> => {
  try {
    const response: AxiosResponse<boolean> = await axios.put(
      `certificates`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
