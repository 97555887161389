import { Checkbox, Radio, RadioChangeEvent, Select, Tooltip } from "antd";
import classNames from "classnames";
import _ from "lodash";
import { CaretDown } from "phosphor-react";
import { useEffect, useState } from "react";
import { Option } from "../../interfaces";
import "./selectCustom.s.scss";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface IPropsSelectCustom {
  className?: string;
  options: Option[];
  onChange?: (array: string[]) => void;
  onChangeRadio?: (value: boolean | null) => void;
  placeholder?: string;
  multipleValue?: string[];
  value?: boolean | null;
  valueRender: string;
  type: string;
  disabled?: boolean;
}

const SelectCustom: React.FC<IPropsSelectCustom> = ({
  onChange,
  onChangeRadio,
  className,
  placeholder,
  options,
  multipleValue = [],
  value = null,
  valueRender,
  type,
  disabled = false,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedValue, setSelectedValue] = useState<boolean | null>(null);

  const isCheckbox = type === "checkbox";

  const handleChange = (selectedValues: string[]) => {
    setSelectedValues(selectedValues);
  };

  const handleChangeRadio = (event: RadioChangeEvent) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  const handleClear = () => {
    if (isCheckbox) {
      setSelectedValues([]);
      if (onChange) {
        onChange([]);
      }
    } else {
      setSelectedValue(null);
      if (onChangeRadio) {
        onChangeRadio(null);
      }
    }
  };

  const handleOnDropdownVisibleChange = (focusOn: boolean) => {
    if (!focusOn) {
      if (isCheckbox && onChange) {
        onChange(selectedValues);
      } else if (!isCheckbox && onChangeRadio) {
        onChangeRadio(selectedValue);
      }
    }
  };

  const renderRadioOptions = () => (
    <div className="custom-radio-list">
      <Radio.Group onChange={handleChangeRadio} value={selectedValue}>
        {options.map((option, index) => (
          <Radio key={index} className="custom-radio" value={option.value}>
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );

  const renderCheckboxOptions = () => (
    <div className="custom-checkbox-list">
      <Checkbox.Group onChange={handleChange} value={selectedValues}>
        {options.map((option, index) => (
          <div key={index} className="checkbox-option-wrapper">
            <Checkbox value={option.value} disabled={option.isDisabled}>
              {option.label}
            </Checkbox>
            {option.info && (
              <Tooltip title={option.info.title} placement="right">
                <a
                  href={option.info.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="guide-icon"
                >
                  <QuestionCircleOutlined />
                </a>
              </Tooltip>
            )}
          </div>
        ))}
      </Checkbox.Group>
    </div>
  );

  const renderDropdown = () => {
    if (isCheckbox) {
      return renderCheckboxOptions();
    }
    return renderRadioOptions();
  };

  useEffect(() => {
    if (!_.isEqual(selectedValues, multipleValue)) {
      setSelectedValues(multipleValue);
    }
  }, [multipleValue]);

  useEffect(() => {
    if (selectedValue !== value) {
      setSelectedValue(value);
    }
  }, [value]);

  return (
    <Select
      className={classNames(
        className,
        (!_.isEmpty(selectedValues) || !_.isNil(selectedValue)) && "has-value"
      )}
      placeholder={placeholder}
      value={valueRender}
      allowClear={!_.isEmpty(selectedValues) || !_.isNil(selectedValue)}
      dropdownRender={() => renderDropdown()}
      showSearch={false}
      suffixIcon={<CaretDown color="#0F172A" />}
      placement="bottomLeft"
      onDropdownVisibleChange={handleOnDropdownVisibleChange}
      onClear={handleClear}
      disabled={disabled}
    />
  );
};

export default SelectCustom;
