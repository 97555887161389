import { AxiosResponse } from "axios";
import axios from "./axiosConfig";

export const handleReopenJob = async (
  jobId: number
): Promise<boolean | void> => {
  try {
    const response: AxiosResponse<boolean> = await axios.post(
      `jobs/${jobId}/reopen`
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
