import { Education } from "../interfaces/profile";
import axios from "./axiosConfig";

export const fetchCandidateEducations = async (): Promise<
  Education[] | void
> => {
  try {
    const response = await axios.get<Education[]>("educations");
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
