/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  CloseOutlined,
  EnvironmentOutlined,
  ExportOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Radio,
  Skeleton,
  Space,
  Tooltip,
} from "antd";

import classNames from "classnames";
import _ from "lodash";
import {
  Briefcase,
  CaretLeft,
  Clock,
  FileX,
  Laptop,
  MapPin,
  SlidersHorizontal,
  UsersFour,
} from "phosphor-react";
import React, { ChangeEvent, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CalendarDotIcon, EmptyCompanyLogo } from "../../../assets/svg";
import Badge from "../../../components/badge/badge";
import ButtonComponent from "../../../components/button/button";
import DrawerComponent from "../../../components/drawer/drawer";
import EmptyComponent from "../../../components/empty/empty";
import ModalComponent from "../../../components/modal/modal";
import { showNotification } from "../../../components/notification/notification";
import SelectCustom from "../../../components/selectCustom/selectCustom";
import { PATH_ROUTER } from "../../../constants";
import { LOADING_TYPES } from "../../../constants/loadingTypes";
import {
  ApplicationTermsOptions,
  CompanySize,
  JobTypeOptions,
  WorkTypeOptions,
} from "../../../constants/selectOptions";
import { Action } from "../../../interfaces/application";
import { Job, JobRequest } from "../../../interfaces/home";
import { fetchApplicationDetail } from "../../../services/fetchDetailApplication";
import { fetchDetailJob } from "../../../services/fetchDetailJob";
import { fetchListJob } from "../../../services/fetchListJob";
import { fetchListLocation } from "../../../services/fetchListLocation";
import { fetchSearchComplete } from "../../../services/fetchSearchComplete";
import { handleActions } from "../../../services/handleActions";
import { handleCancelApplication } from "../../../services/handleCancelApplication";
import updateGotoData from "../../../store/actions/goto";
import loadingPage from "../../../store/actions/loading";
import updateReloadPage from "../../../store/actions/reloadPage";
import { calculateDaysDiff } from "../../../utils";
import auth from "../../../utils/auth";
import useActions from "../../../utils/customHook/useActions";
import useMergeState from "../../../utils/customHook/useMergeState";
import { formatDate, keyFormatter } from "../../../utils/formatter";
import { safeNavigate } from "../../../utils/helper";
import {
  renderStatus,
  renderStatusDescription,
  renderStatusDetail,
  renderStatusTitle,
} from "../dashboard.h";
import "./home.s.scss";

interface HomeProps {
  handleOpen: () => void;
}

const HomePage: React.FC<HomeProps> = ({ handleOpen }) => {
  const dispatch = useDispatch();
  const reloadPageRedux = useSelector((state: any) => state.reloadPage.home);
  const homeGotoRedux = useSelector((state: any) => state.goto.home);
  const applicationGotoRedux = useSelector(
    (state: any) => state.goto.application
  );
  const loadingPageAction = useActions(loadingPage);

  const location = useLocation();
  const { jobDetail: detail, indexActive } = location.state || {};

  const divRef = useRef<HTMLDivElement>(null);
  const jobCardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const jobDetailRef = useRef<HTMLDivElement>(null);
  const pageCurrent = useRef(1);
  const totalElements = useRef(10);
  const initialFilter = {
    jobTitle: "",
    jobTypeIds: [],
    negotiable: null,
    workplaceTypeIds: [],
    cityId: 0,
    stateId: 0,
    countryId: 0,
    searchOptionId: 0,
  };
  const filter = useRef<JobRequest>(initialFilter);

  const [state, setState] = useMergeState({
    searchJob: "",
    listAutoComplete: [],
    searchLocation: [],
    listLocation: [],
    valueLocation: "",
    jobType: [],
    application: null,
    workType: [],
    jobTypeBtn: [],
    applicationBtn: null,
    workTypeBtn: [],
    listJob: [],
    markSave: false,
    indexActive: 0,
    jobDetail: null,
    showBottomButton: false,
    hasShadowTop: false,
    hasShadowBottom: true,
    isLoadingList: false,
    isLoadingMore: false,
    isLoadingDetail: false,
    isOpenCancelModal: false,
    openDropdownFilter: false,
    countFilterMobile: 0,
    openDrawerFilter: false,
    openDrawerFindJob: false,
    openDrawerViewDetailJob: false,
  });

  const { jobDetail } = state || {};

  const handleOpenModal = (key: string, isOpen: boolean) => {
    setState({ [key]: isOpen });
  };

  const handleSetJobType = (data: number[]) => {
    setState({ jobTypeBtn: data });
  };

  const handleSetApplication = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ applicationBtn: e.target.value });
  };

  const handleSetWorkType = (data: number[]) => {
    setState({ workTypeBtn: data });
  };

  const handleClearJobType = () => {
    setState({ jobTypeBtn: [] });
  };

  const handleClearApplication = () => {
    setState({ applicationBtn: null });
  };

  const handleClearWorkType = () => {
    setState({ workTypeBtn: [] });
  };

  const handleApplyFilter = () => {
    let count = 0;
    if (!_.isEmpty(state.jobTypeBtn)) {
      count += state.jobTypeBtn.length;
    }
    if (_.isBoolean(state.applicationBtn)) {
      count += 1;
    }
    if (!_.isEmpty(state.workTypeBtn)) {
      count += state.workTypeBtn.length;
    }
    setState({
      jobType: state.jobTypeBtn,
      application: state.applicationBtn,
      workType: state.workTypeBtn,
      countFilterMobile: count,
    });
    handleUpdateFilter();
  };

  const handleResetFilter = () => {
    setState({
      jobType: [],
      application: null,
      workType: [],
      jobTypeBtn: [],
      applicationBtn: null,
      workTypeBtn: [],
      countFilterMobile: 0,
    });
    handleUpdateFilter();
  };

  const handleClickViewCompany = () => {
    window.open(
      window.location.origin +
        PATH_ROUTER.COMPANY +
        "?id=" +
        jobDetail.company.id,
      "_blank"
    );
  };

  const renderValue = (
    values: (string | number)[],
    options: { value: string | number; label: string }[]
  ) => {
    const selectedOptions = !_.isEmpty(values)
      ? options.filter((option) => values.includes(option.value))
      : [];

    if (selectedOptions.length === 0) return;

    if (selectedOptions.length === 1) return selectedOptions[0].label;

    const firstOption = selectedOptions[0].label;
    const moreCount = selectedOptions.length - 1;

    return `${firstOption} + ${moreCount} more`;
  };

  const renderListFilter = (
    title: string,
    type: "checkbox" | "radio",
    option: Array<any>,
    onChange: (e: any) => void,
    onClear: () => void,
    value: any
  ) => {
    if (type == "checkbox") {
      return (
        <div>
          <div className="list-filter-title">
            <p>{title}</p>
            <ButtonComponent
              title="Clear"
              className="clear-btn"
              type="link"
              onClick={onClear}
            />
          </div>
          <div className="list-filter-item">
            <Checkbox.Group onChange={onChange} value={value}>
              {option.map((option, index) => (
                <div key={index} className="checkbox-option-wrapper">
                  <Checkbox value={option.value} disabled={option.isDisabled}>
                    {option.label}
                  </Checkbox>
                  {option.info?.link && (
                    <Tooltip title={option.info.title} placement="right">
                      <a
                        href={option.info.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="guide-icon"
                      >
                        <QuestionCircleOutlined />
                      </a>
                    </Tooltip>
                  )}
                </div>
              ))}
            </Checkbox.Group>
          </div>
        </div>
      );
    } else if (type == "radio") {
      return (
        <div>
          <div className="list-filter-title">
            <p>{title}</p>
            <ButtonComponent
              title="Clear"
              className="clear-btn"
              type="link"
              onClick={onClear}
            />
          </div>
          <div className="list-filter-item">
            <Radio.Group onChange={onChange} value={value}>
              {option.map((option, index) => (
                <Radio
                  key={index}
                  className="custom-radio"
                  value={option.value}
                >
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>
      );
    }
  };

  const getListAutoComplete = async (text: string) => {
    try {
      if (_.isEmpty(text)) {
        setState({ listJob: homeGotoRedux.listJob });
      }
      const autoCompletes = await fetchSearchComplete(text, 0, 6);
      if (!_.isEmpty(autoCompletes)) {
        const listAutoComplete = _.map(autoCompletes, (item) => ({
          value: item.value,
          label: (
            <>
              <SearchOutlined style={{ marginRight: 6 }} /> {item.label}
            </>
          ),
        }));
        setState({ listAutoComplete });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getListLocation = async (text: string) => {
    try {
      const locations = await fetchListLocation(text, 0, 6);
      if (!_.isEmpty(locations)) {
        const listLocation = _.map(locations, (item) => ({
          id: item.id,
          value: item.value,
          label: (
            <>
              <EnvironmentOutlined style={{ marginRight: 6 }} /> {item.label}
            </>
          ),
        }));
        setState({ listLocation });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getListJob = async (isLoadMore: boolean = false) => {
    try {
      const page = pageCurrent.current;
      if (page * 10 <= totalElements.current) {
        const newPage = isLoadMore ? page + 1 : page;
        const data = await fetchListJob(0, 10 * newPage, filter.current);
        const newState = {};
        let history: Action[] = [];
        const updateHomeGoto = {};
        if (data && !_.isEmpty(data.content)) {
          if (isLoadMore) {
            _.assign(newState, { listJob: data.content });
            if (_.isEqual(initialFilter, filter.current)) {
              _.assign(updateHomeGoto, {
                listJob: data.content,
                page: newPage,
              });
            }
          } else {
            const dataDetail = await fetchDetailJob(data.content[0].jobId);
            if (
              !_.isEmpty(dataDetail) &&
              dataDetail?.application.applicationId
            ) {
              const dataHistory = await handleActions(
                dataDetail.application.applicationId
              );
              history = dataHistory;
            }
            _.assign(newState, {
              listJob: data.content,
              jobDetail: { ...dataDetail, history },
              markSave: dataDetail?.marked,
              indexActive: 0,
            });
            if (_.isEqual(initialFilter, filter.current)) {
              _.assign(updateHomeGoto, {
                listJob: data.content,
                jobDetail: { ...dataDetail, history },
                count: data.totalElements,
                page: newPage,
              });
            }
            totalElements.current = data.totalElements;
          }
        } else {
          _.assign(newState, {
            listJob: [],
            jobDetail: {},
          });
        }
        _.assign(newState, {
          isLoadingList: false,
          isLoadingMore: false,
          isLoadingDetail: false,
        });
        if (!_.isEmpty(updateHomeGoto)) {
          dispatch(updateGotoData("home", updateHomeGoto));
        }
        pageCurrent.current = newPage;
        setState(newState);
      }
    } catch (error) {
      setState({
        listJob: [],
        jobDetail: {},
        isLoadingList: false,
        isLoadingMore: false,
        isLoadingDetail: false,
      });
    }
  };

  const handleUpdateFilter = () => {
    const { jobType, application, workType } = state;
    const clonedFilter = _.cloneDeep(filter.current);
    const jobTypeIds = !_.isEmpty(jobType) ? jobType : [];
    const negotiable = !_.isNil(application) ? application : null;
    const workplaceTypeIds = !_.isEmpty(workType) ? workType : [];
    const newFilter = {
      ...clonedFilter,
      jobTypeIds,
      negotiable,
      workplaceTypeIds,
    };
    filter.current = newFilter;
    pageCurrent.current = 1;
    totalElements.current = 10;
    if (isMobile) {
      setState({
        openDrawerFilter: false,
      });
    }
    setState({ isLoadingList: true, isLoadingDetail: true });
    getListJob();
  };

  const handleChangeJobType = (values: string[]) => {
    const isModified = !_.isEqual(_.sortBy(state.jobType), _.sortBy(values));
    if (isModified) {
      setState({ jobType: values, jobTypeBtn: values });
      handleUpdateFilter();
    }
  };

  const handleChangeApplication = (value: boolean | null) => {
    const isModified = state.application !== value;
    if (isModified) {
      setState({ application: value, applicationBtn: value });
      handleUpdateFilter();
    }
  };

  const handleChangeWorkType = (values: string[]) => {
    const isModified = !_.isEqual(_.sortBy(state.workType), _.sortBy(values));
    if (isModified) {
      setState({ workType: values, workTypeBtn: values });
      handleUpdateFilter();
    }
  };

  // const handleMarkSave = async (id: number) => {
  //   const { listJob, jobDetail } = state;
  //   const data = await handleSaveJob(id);
  //   if (listJob[0].jobId === id) {
  //     dispatch(
  //       updateGotoData("home", {
  //         jobDetail: { ...jobDetail, marked: data.marked },
  //       })
  //     );
  //   }
  //   setState({ markSave: data.marked });
  // };

  const onChangeJob = (value: string) => {
    setState({ searchJob: value });
  };

  const onChangeLocation = (value: string, option: any) => {
    setState({ searchLocation: option.id, valueLocation: value });
  };

  const onSearch = () => {
    const { searchJob, searchLocation } = state;
    const cityId = searchLocation?.[0] | 0;
    const countryId = searchLocation?.[1] | 0;
    const clonedFilter = _.cloneDeep(filter.current);
    const newFilter = {
      ...clonedFilter,
      jobTitle: searchJob,
      cityId,
      countryId,
    };
    filter.current = newFilter;
    pageCurrent.current = 1;
    totalElements.current = 10;
    setState({ isLoadingList: true, isLoadingDetail: true });
    getListJob();
  };

  const scrollToTop = () => {
    if (jobDetailRef.current) {
      jobDetailRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleApply = () => {
    const { indexActive } = state;
    safeNavigate(PATH_ROUTER.APPLICATION_FORM + "?job=" + jobDetail.id, {
      state: { jobDetail, indexActive },
    });
  };

  const handleClickRevise = async () => {
    const { indexActive } = state;
    const { applicationId } = jobDetail.application || {};
    const jobDetailReview = await fetchApplicationDetail(applicationId);
    safeNavigate(
      PATH_ROUTER.APPLICATION_FORM_REVISE + "?application=" + applicationId,
      {
        state: {
          applicationDetailReview: jobDetailReview,
          jobDetail,
          indexActive,
        },
      }
    );
  };

  const handleActiveCard = async (index: string, jobId: number) => {
    setState({ isLoadingDetail: true });
    scrollToTop();
    let history: Action[] = [];
    const dataDetail = await fetchDetailJob(jobId);
    if (!_.isEmpty(dataDetail) && dataDetail?.application.applicationId) {
      const dataHistory = await handleActions(
        dataDetail.application.applicationId
      );
      history = dataHistory;
    }
    setState({
      indexActive: index,
      jobDetail: { ...dataDetail, history },
      markSave: dataDetail?.marked,
      isLoadingDetail: false,
    });
  };

  const handleCancel = async (applicationId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.CANCELING);
      const isSucces = await handleCancelApplication(applicationId);
      if (isSucces) {
        const { jobDetail, listJob } = state;
        const { listApplication, applicationDetail } = applicationGotoRedux;
        const updateHomeRedux = {};
        const updateApplicationRedux = {};
        const history = await handleActions(applicationId);
        //Update home page
        const listJobCloned = _.map(listJob, (job) => {
          if (job.jobId === jobDetail.id) {
            return { ...job, applicationStatusId: 6 };
          }
          return job;
        });
        const jobDetailCloned = _.cloneDeep(jobDetail);
        jobDetailCloned.application.statusId = 6;
        setState({
          jobDetail: { ...jobDetailCloned, history },
          listJob: listJobCloned,
        });
        _.assign(updateHomeRedux, { listJob: listJobCloned });
        if (listJob[0].jobId === jobDetail.id) {
          _.assign(updateHomeRedux, {
            jobDetail: { ...jobDetailCloned, history },
          });
        }
        dispatch(updateGotoData("home", updateHomeRedux));
        //Update application page
        const listApplicationCloned = _.map(listApplication, (item) => {
          if (item.jobId === jobDetail.id) {
            return { ...item, statusId: 6 };
          }
          return item;
        });
        _.assign(updateApplicationRedux, {
          listApplication: listApplicationCloned,
        });
        if (listApplication[0].jobId === jobDetail.id) {
          const applicationDetailCloned = _.cloneDeep(applicationDetail);
          applicationDetailCloned.statusId = 6;
          _.assign(updateApplicationRedux, {
            applicationDetail: { ...applicationDetailCloned, history },
          });
        }
        dispatch(updateGotoData("application", updateApplicationRedux));
        handleOpenModal("isOpenCancelModal", false);
        showNotification({
          type: "success",
          message: "Canceled successfully!",
          description: "The application was canceled successfully",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  useEffect(() => {
    if (reloadPageRedux || _.isEmpty(homeGotoRedux.listJob)) {
      setState({ isLoadingList: true, isLoadingDetail: true });
      getListJob();
      dispatch(updateReloadPage("home", false));
    } else {
      if (!_.isEmpty(location.state)) {
        setState({
          listJob: homeGotoRedux.listJob,
          indexActive,
          jobDetail: detail,
        });
        safeNavigate(location.pathname, { state: {}, replace: true });
        setTimeout(() => {
          if (jobCardRefs.current[indexActive]) {
            jobCardRefs.current[indexActive]?.scrollIntoView({
              behavior: "smooth",
              block: "center", // Đặt phần tử giữa viewport
            });
          }
        }, 0);
      } else {
        setState({
          listJob: homeGotoRedux.listJob,
          jobDetail: homeGotoRedux.jobDetail,
          markSave: homeGotoRedux.jobDetail.marked,
        });
      }
      totalElements.current = homeGotoRedux.count;
      pageCurrent.current = homeGotoRedux.page;
    }
    loadingPageAction();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const element = divRef.current;
      if (element) {
        if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
          getListJob(true);
          setState({
            isLoadingMore: pageCurrent.current * 10 < totalElements.current,
          });
        }
        const isAtTop = element.scrollTop === 0;
        const isAtBottom =
          element.scrollHeight - element.scrollTop <= element.clientHeight;
        setState({
          hasShadowTop: !isAtTop && element.scrollTop > 0,
          hasShadowBottom:
            !isAtBottom && element.scrollHeight > element.clientHeight,
        });
      }
    };

    const element = divRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <>
      <ModalComponent
        className="modal-job-cancel"
        open={state.isOpenCancelModal}
        onCancel={() => handleOpenModal("isOpenCancelModal", false)}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="confirm-btn"
              title="Confirm"
              size="large"
              type="primary"
              onClick={() => handleCancel(jobDetail.application.applicationId)}
            />
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              size="large"
              type="default"
              onClick={() => handleOpenModal("isOpenCancelModal", false)}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Cancel this application</div>
          <div className="title-content">
            Are you sure you want to cancel? Once confirmed, your application
            will be withdrawn from the process, and you will no longer be able
            to apply for this position
          </div>
        </div>
      </ModalComponent>
      <DrawerComponent
        title="Search Jobs"
        closeable
        placement="bottom"
        className="drawer-search-jobs"
        height={"92vh"}
        open={state.openDrawerFindJob}
        onclose={() => setState({ openDrawerFindJob: false })}
        content={
          <div className="search-job-content">
            <AutoComplete
              className="auto-completed-custom"
              style={{ fontWeight: 400 }}
              onSearch={(text) => getListAutoComplete(text)}
              onChange={onChangeJob}
              options={state.listAutoComplete}
              value={state.searchJob}
            >
              <Input
                allowClear
                size="large"
                placeholder="Find your perfect experience"
                style={{ fontSize: 16 }}
                prefix={
                  <SearchOutlined
                    style={{ marginRight: 6, color: "#0F172A" }}
                  />
                }
              />
            </AutoComplete>
            <AutoComplete
              className="auto-completed-custom"
              style={{ fontWeight: 400, fontSize: "16px" }}
              onSearch={(text) => getListLocation(text)}
              onChange={onChangeLocation}
              options={state.listLocation}
              value={state.valueLocation}
            >
              <Input
                allowClear
                size="large"
                placeholder="City, country"
                style={{ fontSize: 16 }}
                prefix={
                  <EnvironmentOutlined
                    style={{ marginRight: 6, color: "#0F172A" }}
                  />
                }
              />
            </AutoComplete>
          </div>
        }
        footer={
          <div className="drawer-footer-action">
            <ButtonComponent
              className="clear-btn"
              onClick={() => {
                setState({ searchJob: "", valueLocation: "" });
              }}
              title="Clear"
            />
            <ButtonComponent
              className="search-btn"
              title="Search"
              onClick={() => {
                onSearch();
                setState({ openDrawerFindJob: false });
              }}
            />
          </div>
        }
      />
      <DrawerComponent
        className="drawer-filter-job"
        onclose={() => setState({ openDrawerFilter: false })}
        title="Filters"
        placement="bottom"
        size="large"
        content={
          <div className="drawer-filter-job-body">
            <div className="list-filter">
              {renderListFilter(
                "Job Type",
                "checkbox",
                JobTypeOptions,
                handleSetJobType,
                handleClearJobType,
                state.jobTypeBtn
              )}
              {renderListFilter(
                "Application Terms",
                "radio",
                ApplicationTermsOptions,
                handleSetApplication,
                handleClearApplication,
                state.applicationBtn
              )}
              {renderListFilter(
                "Work Type",
                "checkbox",
                WorkTypeOptions,
                handleSetWorkType,
                handleClearWorkType,
                state.workTypeBtn
              )}
            </div>
          </div>
        }
        open={state.openDrawerFilter}
        footer={
          <div className="drawer-footer-action">
            <ButtonComponent
              className="reset-btn"
              onClick={handleResetFilter}
              title="Reset"
            />
            <ButtonComponent
              className="apply-btn"
              title="Apply filters"
              onClick={handleApplyFilter}
            />
          </div>
        }
      />
      <DrawerComponent
        className="drawer-view-detail-job"
        title={
          <ButtonComponent
            className="back-btn"
            title="Back"
            icon={<CaretLeft size={24} />}
            onClick={() => setState({ openDrawerViewDetailJob: false })}
          />
        }
        placement="right"
        size="large"
        content={
          state.isLoadingDetail ? (
            <Skeleton active paragraph={{ rows: 23 }} />
          ) : (
            <div className="drawer-view-detail-job-body job-detail">
              <div className="job-detail-logo" onClick={handleClickViewCompany}>
                <img
                  src={jobDetail?.company?.logoUrl || EmptyCompanyLogo}
                  alt="company-logo"
                  width={32}
                  height={32}
                />
                <div>{jobDetail?.company?.name}</div>
              </div>
              <div className="job-detail-name">
                <div className="job-title">
                  <div className="title">
                    <span>{jobDetail?.title}</span>
                    {jobDetail?.negotiable && (
                      <>
                        <span className="title-sub">(Negotiable)</span>
                        <Tooltip
                          className="tooltip"
                          placement="bottom"
                          title="This indicates that the company is willing to negotiate and adjust the job duties, working hours, duration, and location through discussion"
                        >
                          <QuestionCircleOutlined
                            style={{ fontSize: 16, color: "#0A5CD8" }}
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                  <div className="company-address">
                    {_.compact([
                      jobDetail?.location?.city,
                      jobDetail?.location?.country,
                    ]).join(", ")}
                  </div>
                </div>
              </div>
              {auth.isCandidateUser() && (
                <div className="job-detail-action">
                  <ButtonComponent
                    className="application-btn"
                    title={
                      jobDetail?.application?.applicationId
                        ? "View your application"
                        : "Apply now"
                    }
                    onClick={handleOpen}
                  />
                  {(jobDetail?.application?.statusId === 1 ||
                    jobDetail?.application?.statusId === 2) && (
                    <Tooltip
                      className="tooltip"
                      title="Cancel your application"
                      placement="bottom"
                    >
                      <Button
                        className="cancel-btn"
                        icon={<FileX size={24} />}
                        onClick={handleOpen}
                      />
                    </Tooltip>
                  )}
                  {/* <ButtonComponent
                className="save-btn"
                icon={
                  state.markSave ? (
                    <BookmarkSimple
                      size={24}
                      weight="fill"
                      color="#FF7710"
                    />
                  ) : (
                    <BookmarkSimple size={24} />
                  )
                }
                onClick={() => handleMarkSave(jobDetail.id)}
              /> */}
                </div>
              )}
              <div className="job-detail-keys">
                {_.map(
                  jobDetail?.tags,
                  (tag) =>
                    tag && <Badge title={_.replace(tag.name, /#/g, "")} />
                )}
              </div>
              {jobDetail?.application?.applicationId && (
                <div className="job-detail-update">
                  <div className="job-detail-title">The latest updated</div>
                  <div className="job-detail-content">
                    <div className="application-status-card">
                      <div className="status-left">
                        <div className="circle">
                          <div className="inner-circle"></div>
                        </div>
                        <div className="dashed-line"></div>
                      </div>
                      <div className="status-right">
                        <div className="status-action">
                          {renderStatusDetail(jobDetail?.application.statusId)}
                          <div className="status-action-date">
                            {calculateDaysDiff(
                              jobDetail?.history[0].createdDate,
                              true
                            )}
                          </div>
                        </div>
                        <div className="status-title">
                          {renderStatusTitle(jobDetail?.application.statusId)}
                        </div>
                        <div className="status-description">
                          {renderStatusDescription(
                            jobDetail?.application.statusId
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="job-detail-about">
                <div className="job-detail-title">About the job</div>
                <div className="job-detail-content">
                  {jobDetail?.description}
                </div>
                <div className="job-detail-duration">
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <Briefcase size={24} />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Job Type
                      </div>
                      <div className="apply-duration-detail-time">
                        {jobDetail?.jobType?.name}
                        {jobDetail?.jobType?.id === 1 && (
                          <Tooltip
                            className="tooltip"
                            title="The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties."
                            placement="bottom"
                          >
                            <QuestionCircleOutlined
                              style={{
                                fontSize: 16,
                                color: "#666666",
                                marginLeft: 5,
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <img src={CalendarDotIcon} alt="calendar-icon" />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Working period
                      </div>
                      <div className="apply-duration-detail-time">
                        {`${formatDate(jobDetail?.workStart)} - ${formatDate(
                          jobDetail?.workEnd
                        )}`}
                      </div>
                    </div>
                  </div>
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <Laptop size={24} />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Workplace Type
                      </div>
                      <div className="apply-duration-detail-time">
                        {jobDetail?.workplaceType?.name}
                      </div>
                    </div>
                  </div>
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <Clock size={24} />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Hours per week
                      </div>
                      <div className="apply-duration-detail-time">
                        {`${jobDetail?.hoursPerWeek} hours`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="job-detail-tasks">
                <div className="job-detail-title">Tasks</div>
                <div className="job-detail-content">
                  <ul>
                    {!_.isEmpty(jobDetail?.tasks) ? (
                      _.map(jobDetail?.tasks, (task) => (
                        <li>{_.replace(task.description, /^[-+]/, "")}</li>
                      ))
                    ) : (
                      <li>No description</li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="job-detail-qualify">
                <div className="job-detail-title">Minimum Qualifications</div>
                <div className="job-detail-content">
                  <ul>
                    {!_.isEmpty(jobDetail?.qualifications) ? (
                      _.map(jobDetail?.qualifications, (qualification) => (
                        <li>
                          {_.replace(qualification.description, /^[-+]/, "")}
                        </li>
                      ))
                    ) : (
                      <li>No description</li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="job-detail-company">
                <div className="job-detail-company-intro">
                  <div
                    className="job-detail-company-intro-logo"
                    onClick={handleClickViewCompany}
                  >
                    <img
                      src={jobDetail?.company?.logoUrl || EmptyCompanyLogo}
                      alt="company-logo"
                      className="company-logo"
                      height={32}
                      width={32}
                    />
                    <div className="company-name">
                      {jobDetail?.company?.name}
                    </div>
                  </div>
                  <div className="job-detail-company-intro-info">
                    <div className="company-address">
                      <MapPin className="icon" size={18} />
                      <span>
                        {_.compact([
                          jobDetail?.location?.city,
                          jobDetail?.location?.country,
                        ]).join(", ")}
                      </span>
                    </div>
                    <div className="company-employee">
                      <UsersFour className="icon" size={18} />
                      {
                        _.find(
                          CompanySize,
                          (size) =>
                            size.id === jobDetail?.company?.companySizeId
                        )?.name
                      }
                    </div>
                  </div>
                  <ButtonComponent
                    title="View company"
                    icon={<ExportOutlined />}
                    className="view-btn"
                    iconPosition="end"
                    type="link"
                    onClick={handleClickViewCompany}
                  />
                </div>
                {jobDetail?.company?.companyOverview && (
                  <div className="job-detail-company-overview">
                    <div className="job-detail-title">Company overview</div>
                    <div className="job-detail-content">
                      {jobDetail.company?.companyOverview}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        }
        open={state.openDrawerViewDetailJob}
        footer={null}
      />
      <div className="home-page">
        <div className="search">
          <Input
            readOnly
            className="input-search-mobile"
            allowClear
            value={state.searchJob}
            size="large"
            placeholder="Find your perfect experience"
            prefix={
              <SearchOutlined style={{ marginRight: 6, color: "#0F172A" }} />
            }
            onClick={() => {
              setState({ openDrawerFindJob: true });
            }}
          />
          <AutoComplete
            className="auto-completed-custom"
            style={{ width: 350, fontWeight: 400 }}
            onSearch={(text) => getListAutoComplete(text)}
            onChange={onChangeJob}
            options={state.listAutoComplete}
            value={state.searchJob}
          >
            <Input
              allowClear
              size="large"
              placeholder="Find your perfect experience"
              prefix={
                <SearchOutlined style={{ marginRight: 6, color: "#0F172A" }} />
              }
            />
          </AutoComplete>
          <AutoComplete
            className="auto-completed-custom"
            style={{ width: 350, fontWeight: 400 }}
            onSearch={(text) => getListLocation(text)}
            onChange={onChangeLocation}
            options={state.listLocation}
            value={state.valueLocation}
          >
            <Input
              allowClear
              size="large"
              placeholder="City, country"
              prefix={
                <EnvironmentOutlined
                  style={{ marginRight: 6, color: "#0F172A" }}
                />
              }
            />
          </AutoComplete>
          <ButtonComponent
            className="search-btn"
            title="Search"
            type="primary"
            size="large"
            onClick={onSearch}
          />
        </div>
        <div className="filter">
          <div className="filter-left">
            <Space wrap>
              <SelectCustom
                multipleValue={state.jobType}
                valueRender={
                  renderValue(state.jobType, JobTypeOptions) || "Job Type"
                }
                options={JobTypeOptions}
                onChange={handleChangeJobType}
                type="checkbox"
              />
              <SelectCustom
                value={state.application}
                valueRender={
                  _.find(
                    ApplicationTermsOptions,
                    (option) => option.value === state.application
                  )?.label || "Application Terms"
                }
                options={ApplicationTermsOptions}
                onChangeRadio={handleChangeApplication}
                type="radio"
              />
              <SelectCustom
                multipleValue={state.workType}
                valueRender={
                  renderValue(state.workType, WorkTypeOptions) || "Work Type"
                }
                options={WorkTypeOptions}
                onChange={handleChangeWorkType}
                type="checkbox"
              />
            </Space>
          </div>
          <div className="filter-right">
            <Dropdown
              open={state.openDropdownFilter}
              onOpenChange={(open) => {
                setState({ openDropdownFilter: open });
              }}
              overlayClassName="dropdown-filter"
              menu={{
                items: [
                  {
                    label: (
                      <div
                        className="filter-container"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="filter-title">
                          <h3>Filters</h3>
                          <ButtonComponent
                            onClick={() => {
                              setState({
                                openDropdownFilter: !state.openDropdownFilter,
                              });
                            }}
                            icon={<CloseOutlined />}
                            type="default"
                          />
                        </div>
                        <div className="drawer-filter-job-body">
                          <div className="list-filter">
                            {renderListFilter(
                              "Job Type",
                              "checkbox",
                              JobTypeOptions,
                              handleSetJobType,
                              handleClearJobType,
                              state.jobTypeBtn
                            )}
                            {renderListFilter(
                              "Application Terms",
                              "radio",
                              ApplicationTermsOptions,
                              handleSetApplication,
                              handleClearApplication,
                              state.applicationBtn
                            )}
                            {renderListFilter(
                              "Work Type",
                              "checkbox",
                              WorkTypeOptions,
                              handleSetWorkType,
                              handleClearWorkType,
                              state.workTypeBtn
                            )}
                          </div>
                        </div>
                        <div className="filter-footer">
                          <ButtonComponent
                            className="apply-btn"
                            title="Apply filters"
                            onClick={handleApplyFilter}
                          />
                          <ButtonComponent
                            className="reset-btn"
                            onClick={handleResetFilter}
                            title="Reset"
                          />
                        </div>
                      </div>
                    ),
                    key: 1,
                  },
                ],
              }}
              trigger={["click"]}
              placement="bottomRight"
            >
              <ButtonComponent
                className="filter-btn"
                icon={<SlidersHorizontal size={20} />}
                title="All Filter"
              />
            </Dropdown>
          </div>
          {isMobile && (
            <ButtonComponent
              className="filter-mobile-btn"
              icon={<SlidersHorizontal size={20} />}
              title={
                !state.countFilterMobile
                  ? "All Filter"
                  : `All Filter${state.countFilterMobile > 1 ? "s" : ""}: ${
                      state.countFilterMobile
                    }`
              }
              onClick={() => setState({ openDrawerFilter: true })}
            />
          )}
        </div>
        {/* <div className="count-jobs">
        <strong>50 Product intern</strong> jobs in United State
      </div> */}
        <div className="jobs">
          <div
            ref={divRef}
            className={classNames(
              "job-list",
              !_.isEmpty(state.listJob) &&
                state.listJob.length > (isMobile ? 3 : 4)
                ? state.hasShadowTop && state.hasShadowBottom
                  ? "shadow-top-bottom"
                  : state.hasShadowTop
                  ? "shadow-top"
                  : "shadow-bottom"
                : ""
            )}
          >
            {state.isLoadingList ? (
              _.map(new Array(5), (_item, index) => (
                <div className="job-card" key={index}>
                  <Skeleton active title={false} paragraph={{ rows: 3 }} />
                </div>
              ))
            ) : _.isEmpty(state.listJob) ? (
              <EmptyComponent className="empty-layout" />
            ) : (
              <>
                {_.map(state.listJob, (job: Job, index) => (
                  <div
                    className={classNames(
                      "job-card",
                      index === state.indexActive && "job-card-active"
                    )}
                    key={index}
                    ref={(el) => (jobCardRefs.current[index] = el)}
                    onClick={() => {
                      handleActiveCard(index, job.jobId);
                      if (isMobile) {
                        setState({ openDrawerViewDetailJob: true });
                      }
                    }}
                  >
                    <div className="job-card-left">
                      <div className="job-title">
                        <div className="title">
                          {job.jobTitle}
                          {/* {job.jobType.name} */}
                          {job.negotiable && (
                            <span className="title-sub">(Negotiable)</span>
                          )}
                        </div>
                      </div>
                      <div className="company">
                        <img
                          src={job?.logoUrl || EmptyCompanyLogo}
                          alt="company-logo"
                          className="company-logo"
                          width={40}
                          height={40}
                        />
                        <div className="company-info">
                          <div className="company-info-name">
                            {job.companyName}
                          </div>
                          <div className="company-info-state">
                            {_.compact([job.cityName, job.countryName]).join(
                              ", "
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="job-keys">
                        {_.map(
                          keyFormatter(job.keywords),
                          (keyword) =>
                            keyword && (
                              <Badge title={_.replace(keyword, /#/g, "")} />
                            )
                        )}
                      </div>
                      {auth.isCandidateUser() && job.applicationStatusId && (
                        <div className="job-status" style={{ marginTop: 12 }}>
                          {renderStatus(job.applicationStatusId)}
                        </div>
                      )}
                    </div>
                    <div className="job-card-right">
                      <div className="job-mark" />
                      <div className="update-time">
                        {calculateDaysDiff(job.postDateTime)}
                      </div>
                    </div>
                  </div>
                ))}
                {state.isLoadingMore && (
                  <div className="job-card">
                    <Skeleton active title={false} paragraph={{ rows: 3 }} />
                  </div>
                )}
              </>
            )}
          </div>
          <div ref={jobDetailRef} className="job-detail">
            {state.isLoadingDetail ? (
              <Skeleton active paragraph={{ rows: 13 }} />
            ) : _.isEmpty(jobDetail) ? (
              <EmptyComponent className="empty-layout" />
            ) : (
              <>
                <div className="job-detail-name">
                  <img
                    onClick={handleClickViewCompany}
                    src={jobDetail.company?.logoUrl || EmptyCompanyLogo}
                    className="company-logo active-profile"
                    alt="company-logo"
                    width={84}
                    height={84}
                  />
                  <div className="job-title">
                    <div className="title">
                      <span
                        className="active-profile"
                        onClick={handleClickViewCompany}
                      >
                        {jobDetail.title}
                      </span>
                      {jobDetail.negotiable && (
                        <>
                          <span className="title-sub">(Negotiable)</span>
                          <Tooltip
                            className="tooltip"
                            placement="bottom"
                            title="This indicates that the company is willing to negotiate and adjust the job duties, working hours, duration, and location through discussion"
                          >
                            <QuestionCircleOutlined
                              style={{ fontSize: 16, color: "#0A5CD8" }}
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                    <div className="company-info">
                      <div
                        className="company-info-name active-profile"
                        onClick={handleClickViewCompany}
                      >
                        {jobDetail.company?.name}
                      </div>
                      <div className="company-info-state">
                        <EnvironmentOutlined className="icon" />
                        {_.compact([
                          jobDetail.location?.city,
                          jobDetail.location?.country,
                        ]).join(", ")}
                      </div>
                    </div>
                  </div>
                </div>
                {auth.isCandidateUser() && (
                  <div className="job-detail-action">
                    <ButtonComponent
                      className="application-btn"
                      title={
                        jobDetail.application?.applicationId
                          ? "View your application"
                          : "Apply now"
                      }
                      onClick={
                        jobDetail.application?.applicationId
                          ? handleClickRevise
                          : handleApply
                      }
                    />
                    {(jobDetail.application?.statusId === 1 ||
                      jobDetail.application?.statusId === 2) && (
                      <Tooltip
                        className="tooltip"
                        title="Cancel your application"
                        placement="bottom"
                      >
                        <Button
                          className="cancel-btn"
                          icon={<FileX size={24} />}
                          onClick={() =>
                            handleOpenModal("isOpenCancelModal", true)
                          }
                        />
                      </Tooltip>
                    )}
                    {/* <ButtonComponent
                      className="save-btn"
                      icon={
                        state.markSave ? (
                          <BookmarkSimple
                            size={24}
                            weight="fill"
                            color="#FF7710"
                          />
                        ) : (
                          <BookmarkSimple size={24} />
                        )
                      }
                      onClick={() => handleMarkSave(jobDetail.id)}
                    /> */}
                  </div>
                )}
                <div className="job-detail-keys">
                  {_.map(
                    jobDetail.tags,
                    (tag) =>
                      tag && <Badge title={_.replace(tag.name, /#/g, "")} />
                  )}
                </div>
                {jobDetail.application?.applicationId && (
                  <div className="job-detail-update">
                    <div className="job-detail-title">The latest updated</div>
                    <div className="job-detail-content">
                      <div className="application-status-card">
                        <div className="status-left">
                          <div className="circle">
                            <div className="inner-circle"></div>
                          </div>
                          <div className="dashed-line"></div>
                        </div>
                        <div className="status-right">
                          <div className="status-action">
                            {renderStatusDetail(jobDetail.application.statusId)}
                            <div className="status-action-date">
                              {calculateDaysDiff(
                                jobDetail.history[0].createdDate,
                                true
                              )}
                            </div>
                          </div>
                          <div className="status-title">
                            {renderStatusTitle(jobDetail.application.statusId)}
                          </div>
                          <div className="status-description">
                            {renderStatusDescription(
                              jobDetail.application.statusId
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="job-detail-about">
                  <div className="job-detail-title">About the job</div>
                  <div className="job-detail-content">
                    {jobDetail.description}
                  </div>
                  <div className="job-detail-duration">
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <Briefcase size={24} />
                      </div>
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Job Type
                        </div>
                        <div className="apply-duration-detail-time">
                          {jobDetail.jobType?.name}
                          {jobDetail.jobType?.id === 1 && (
                            <Tooltip
                              className="tooltip"
                              title="The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties."
                              placement="right"
                            >
                              <QuestionCircleOutlined
                                style={{
                                  fontSize: 16,
                                  color: "#666666",
                                  marginLeft: 5,
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <img src={CalendarDotIcon} alt="calendar-icon" />
                      </div>
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Working period
                        </div>
                        <div className="apply-duration-detail-time">
                          {`${formatDate(jobDetail.workStart)} - ${formatDate(
                            jobDetail.workEnd
                          )}`}
                        </div>
                      </div>
                    </div>
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <Laptop size={24} />
                      </div>
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Workplace Type
                        </div>
                        <div className="apply-duration-detail-time">
                          {jobDetail.workplaceType?.name}
                        </div>
                      </div>
                    </div>
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <Clock size={24} />
                      </div>
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Hours per week
                        </div>
                        <div className="apply-duration-detail-time">
                          {`${jobDetail.hoursPerWeek} hours`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="job-detail-tasks">
                  <div className="job-detail-title">Tasks</div>
                  <div className="job-detail-content">
                    <ul>
                      {!_.isEmpty(jobDetail.tasks) ? (
                        _.map(jobDetail.tasks, (task) => (
                          <li>{_.replace(task.description, /^[-+]/, "")}</li>
                        ))
                      ) : (
                        <li>No description</li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="job-detail-qualify">
                  <div className="job-detail-title">Minimum Qualifications</div>
                  <div className="job-detail-content">
                    <ul>
                      {!_.isEmpty(jobDetail.qualifications) ? (
                        _.map(jobDetail.qualifications, (qualification) => (
                          <li>
                            {_.replace(qualification.description, /^[-+]/, "")}
                          </li>
                        ))
                      ) : (
                        <li>No description</li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="job-detail-company">
                  <div className="job-detail-company-intro">
                    <div className="job-detail-company-intro-left">
                      <img
                        onClick={handleClickViewCompany}
                        src={jobDetail.company?.logoUrl || EmptyCompanyLogo}
                        alt="company-logo"
                        className="company-logo-intro active-profile"
                        height={64}
                        width={64}
                      />
                      <div className="company-info">
                        <div
                          className="company-info-name active-profile"
                          onClick={handleClickViewCompany}
                        >
                          {jobDetail.company?.name}
                        </div>
                        <div className="company-info-detail">
                          <div className="company-info-detail-state">
                            <MapPin className="icon" size={18} />
                            <span>
                              {_.compact([
                                jobDetail.location?.city,
                                jobDetail.location?.country,
                              ]).join(", ")}
                            </span>
                          </div>
                          <div className="company-info-detail-employ">
                            <UsersFour className="icon" size={18} />
                            {
                              _.find(
                                CompanySize,
                                (size) =>
                                  size.id === jobDetail.company?.companySizeId
                              )?.name
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="job-detail-company-intro-right">
                      <ButtonComponent
                        title="View company"
                        icon={<ExportOutlined />}
                        className="view-btn"
                        iconPosition="end"
                        type="link"
                        onClick={handleClickViewCompany}
                      />
                    </div>
                  </div>
                  {jobDetail.company?.companyOverview && (
                    <div className="job-detail-company-overview">
                      <div className="job-detail-title">Company overview</div>
                      <div className="job-detail-content">
                        {jobDetail.company?.companyOverview}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
