import classNames from "classnames";
import Quill, { DeltaStatic, Sources } from "quill";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./inputQuillCustom.s.scss";

let Delta = Quill.import("delta");

// Custom blot để ngăn chặn việc xóa prefix
const Embed = Quill.import("blots/embed");
class PrefixBlot extends Embed {
  static create(value: string) {
    const node = super.create();
    node.innerHTML = `<strong>${value}</strong>`;
    return node;
  }

  static value(node: HTMLElement) {
    return node.innerHTML;
  }
}

PrefixBlot.blotName = "prefix";
PrefixBlot.tagName = "strong";
Quill.register(PrefixBlot);

interface InputQuillDefaultProps {
  valuePrefix?: any;
  value: string;
  onChange?: (value: string) => void;
  id?: string;
  onKeyDown?: (e: any) => void;
  className?: string;
  handleChangeMultiple?: (value: string, id: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
  idFocus?: string;
}

const InputQuillCustom = ({
  valuePrefix,
  value,
  onChange,
  id,
  onKeyDown,
  className,
  handleChangeMultiple,
  readOnly = false,
  disabled,
  idFocus,
}: InputQuillDefaultProps) => {
  const [valueHtml, setValueHtml] = useState("");
  const quillRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    if (!quillRef.current) return;
    const quill = quillRef.current.getEditor();
    const prefixLength = valuePrefix?.length || 0;

    // Xóa nội dung hiện tại và chèn prefix không thể chỉnh sửa
    if (!valueHtml && valuePrefix) {
      quill.setContents(
        new Delta([{ insert: { prefix: valuePrefix } }, { insert: value }])
      );
    } else if (!valuePrefix && value && !valueHtml) {
      quill.setContents(new Delta([{ insert: value }]));
    }

    const handleTextChange = (
      delta: DeltaStatic,
      _oldDelta: DeltaStatic,
      _source: Sources
    ) => {
      const selection = quill.getSelection();
      const currentContents = quill.getContents();
      if (!selection || !valuePrefix) return;

      // Kiểm tra nếu prefix bị xóa, khôi phục lại mà không thay đổi nội dung
      const firstOp = currentContents.ops && currentContents.ops[0];
      if (!firstOp?.insert || !firstOp.insert.prefix) {
        quill.updateContents(
          new Delta([
            { insert: { prefix: valuePrefix } },
            ...(currentContents.ops ? currentContents.ops.slice(1) : []),
          ]),
          "silent"
        );
      }

      // Giữ con trỏ sau prefix nếu nó ở trong vùng prefix
      if (selection.index <= prefixLength) {
        setTimeout(() => quill.setSelection(prefixLength, 0, "silent"), 0);
        return;
      }

      // Ngăn việc xóa prefix
      let needsReset = false;
      delta.ops?.forEach((op: any) => {
        if (op.delete && selection.index <= prefixLength) {
          needsReset = true;
        }
      });

      if (needsReset) {
        quill.updateContents(
          new Delta([{ retain: prefixLength }, { delete: delta.length }]),
          "silent"
        );
        setTimeout(() => quill.setSelection(prefixLength, 0, "silent"), 0);
      }
    };

    quill.on("text-change", handleTextChange);

    return () => {
      quill.off("text-change", handleTextChange);
    };
  }, [valuePrefix, value]);

  const handleChange = (
    _value: string,
    _delta: any,
    _source: any,
    editor: ReactQuill.UnprivilegedEditor
  ) => {
    // Lưu lại vị trí con trỏ hiện tại
    if (valuePrefix) {
      const textWithoutPrefix = editor
        .getText()
        .replace(valuePrefix as string, "")
        .trim();
      // Kiểm tra nếu nội dung chứa nhiều thẻ <p> do nhấn Enter
      let newContent = `<p><strong>${valuePrefix}</strong><span> ${textWithoutPrefix}</span></p>`;

      // Cập nhật lại nội dung vào state nếu có thay đổi
      if (valueHtml !== newContent) {
        setValueHtml(newContent);
      }
      // kiểm tra nếu có nội dung mới thì thêm class change-value vào thẻ strong
      if (textWithoutPrefix) {
        // xử lí thay thế dùng ref gọi tới editor và dom tới
        if (quillRef.current) {
          const editorRoot = quillRef.current.getEditor().root;
          const strongElement = editorRoot.querySelector(
            ".customEditor .ql-editor strong"
          );
          if (strongElement) {
            strongElement.classList.add("change-value");
          }
        }
      }
    } else {
      const textWithoutPrefix = editor.getText().trim();
      setValueHtml(`<p><span>${textWithoutPrefix}</span></p>`);
    }

    // Lấy nội dung mới đã được chỉnh sửa
    const newText = editor
      .getText()
      .replace(valuePrefix ? valuePrefix : ("" as string), "")
      .trim();

    if (value !== newText) {
      onChange && onChange(newText);
      handleChangeMultiple &&
        id &&
        handleChangeMultiple(newText, id.toString());
    }
  };

  useEffect(() => {
    const editor = quillRef.current?.getEditor();
    const keyboard = editor?.getModule("keyboard");
    keyboard.bindings["Enter"] = null;
    keyboard.bindings["13"] = null;
  }, []);

  useEffect(() => {
    if (idFocus && id === idFocus) {
      quillRef.current?.focus();
    }
  }, [idFocus]);

  return (
    <div className="customEditor">
      <ReactQuill
        className={classNames(
          className,
          readOnly && "quill-readonly",
          disabled && "quill-disabled"
        )}
        id={id ? id : ""}
        defaultValue={valueHtml}
        readOnly={readOnly}
        ref={quillRef}
        theme="snow"
        onKeyDown={(event) => {
          // Kiểm tra nếu người dùng nhấn Enter
          if (event.key === "Enter" || event.key === "Tab") {
            event.preventDefault(); // Ngăn không cho xuống dòng mới

            // Lấy nội dung hiện tại từ editor và cập nhật thẻ <p>
            const editor = quillRef.current?.getEditor();
            if (editor && valuePrefix) {
              const textWithoutPrefix = editor
                .getText()
                .replace(valuePrefix as string, "")
                .trim();
              // Kiểm tra nếu nội dung chứa nhiều thẻ <p> do nhấn Enter
              const newContent = `<p><strong>${valuePrefix}</strong><span> ${textWithoutPrefix}</span></p>`;
              if (valueHtml !== newContent) {
                setValueHtml(newContent);
              }
              editor.setSelection(valuePrefix.length, 0, "silent");
            }
          }
          // Gọi lại sự kiện onKeyDown từ props nếu có
          onKeyDown && event && onKeyDown(event);
        }}
        onChange={handleChange} // Theo dõi sự thay đổi nội dung
        modules={{
          toolbar: false, // Ẩn toolbar để không sử dụng toolbar
        }}
      />
    </div>
  );
};

export default InputQuillCustom;
