import { CandidateProfile } from "../interfaces/profile";
import axios from "./axiosConfig";

export const fetchCandidateProfileId = async (
  studentId: number
): Promise<CandidateProfile | void> => {
  try {
    const response = await axios.get<CandidateProfile>(
      `individuals/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
