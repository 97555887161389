/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  EnvironmentOutlined,
  ExportOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import _ from "lodash";
import { ArrowLeft, CaretDown } from "phosphor-react";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { CheckIcon, EditFormIcon, EmptyCompanyLogo } from "../../assets/svg";
import ButtonComponent from "../../components/button/button";
import InputDefault from "../../components/input/inputDefault/inputDefault";
import InputPrefix from "../../components/input/inputPrefix/inputPrefix";
import ModalComponent from "../../components/modal/modal";
import { FORMAT_DATE, PATH_ROUTER } from "../../constants";
import { getCountry, listCountry } from "../../constants/countries";
import { LOADING_TYPES } from "../../constants/loadingTypes";
import { JobTypeOptions, WorkTypeOptions } from "../../constants/selectOptions";
import { Application } from "../../interfaces/application";
import { fetchApplicationHistories } from "../../services/fetchApplicationHistories";
import { handleDownloadFile } from "../../services/handleDownloadFile";
import { handleResubmitLApplicationForm } from "../../services/handleResubmitApplicationForm";
import loadingPage from "../../store/actions/loading";
import { getLabelByValue } from "../../utils";
import useActions from "../../utils/customHook/useActions";
import useMergeState from "../../utils/customHook/useMergeState";
import { formatDate } from "../../utils/formatter";
import { safeNavigate } from "../../utils/helper";
import { renderStatus } from "../dashboard/dashboard.h";
import { newFormDataFormatter } from "./applicationForm.h";
import "./applicationFormRevise.s.scss";
import NegotiableForm from "./form/negotiable";
import ResumeForm from "./form/resume";

const ApplicationFormRevise = () => {
  const loadingPageAction = useActions(loadingPage);
  const location = useLocation();
  const { applicationDetailReview, jobDetail, indexActive } =
    location.state || {};
  const dataHistory = JSON.parse(
    sessionStorage.getItem("queryHistory") || "[]"
  );
  const countriesOption = listCountry();

  const newForm = useRef({
    step1: {
      currentJobTitle: "",
      currentJobType: "",
      currentStartDate: "",
      currentEndDate: "",
      currentWorkplaceType: "",
      currentHoursPerWeek: "",
      currentDescription: "",
      currentTasks: [],
      currentQualifications: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
      workplaceType: "",
      hoursPerWeek: "",
      negotiable: false,
    },
    step2: {
      email: "",
      phoneNumber: "",
      portfolio: "",
      personalWebsite: [],
      selfIntroduction: "",
      listAttachment: [],
      selectedResumeId: null,
      selectedCountry: {
        countryCode: "",
        phoneCode: "",
        flag: "",
      },
    },
    jobId: null,
    jobTypeId: null,
    applicationId: 0,
  });

  const [state, setState] = useMergeState({
    step: 1,
    detailApplication: applicationDetailReview,
    isOpenModal: false,
    isOpenGuideModal: false,
    isRevising: true,
    selectedVersion: applicationDetailReview.histories.length,
    listHistory: applicationDetailReview.histories,
    isAbledToRevise:
      applicationDetailReview.statusId === 1 ||
      applicationDetailReview.statusId === 2,
  });

  const {
    detailApplication,
    isAbledToRevise,
  }: { detailApplication: Application; isAbledToRevise: boolean } = state || {};

  const createIntitialData = async () => {
    const { detailApplication } = state;
    const country = getCountry(
      detailApplication.phoneNumber.countryCode || "US"
    );
    _.assign(newForm.current, {
      step1: {
        currentJobTitle: detailApplication.jobTitle?.delta?.company || "",
        currentJobType: detailApplication.jobType?.id,
        currentStartDate: detailApplication.workPeriodStart?.delta?.company
          ? dayjs(detailApplication.workPeriodStart.delta.company).toISOString()
          : "",
        currentEndDate: detailApplication.workPeriodEnd?.delta?.company
          ? dayjs(detailApplication.workPeriodEnd.delta.company).toISOString()
          : "",
        currentWorkplaceType: getLabelByValue(
          WorkTypeOptions,
          detailApplication.workplaceType?.delta?.company
        ),
        currentHoursPerWeek:
          detailApplication.workHoursPerWeek?.delta?.company || "",
        currentDescription: detailApplication.job?.description || "",
        currentTasks: _.map(detailApplication.tasks, (task) => ({
          id: task?.delta?.company?.id || "",
          idNewTask: task?.delta?.candidate?.id || null,
          description: task?.delta?.company?.description || "",
          newTask: task?.delta?.candidate?.description || "",
          isRemove:
            task.negotiable && _.isEmpty(task.delta.candidate.description),
        })),
        currentQualifications: _.map(
          detailApplication.qualifications,
          (qualification) => qualification.description
        ).join("\n"),
        jobTitle: detailApplication.jobTitle?.delta?.candidate || "",
        startDate: detailApplication.workPeriodStart?.delta?.candidate || "",
        endDate: detailApplication.workPeriodEnd?.delta?.candidate || "",
        workplaceType: getLabelByValue(
          WorkTypeOptions,
          detailApplication.workplaceType?.delta?.candidate
        ),
        hoursPerWeek:
          detailApplication.workHoursPerWeek?.delta?.candidate || "",
        negotiable: detailApplication.job.negotiable,
        negotiableTitle: detailApplication.job.negotiableTitle,
        negotiableWorkStart: detailApplication.job.negotiableWorkStart,
        negotiableWorkEnd: detailApplication.job.negotiableWorkEnd,
        negotiableWorkplaceType: detailApplication.job.negotiableWorkplaceType,
        negotiableHoursPerWeek: detailApplication.job.negotiableHoursPerWeek,
        negotiableTasks: detailApplication.job.negotiableTasks,
      },
      step2: {
        email: detailApplication.email || "",
        phoneNumber: detailApplication.phoneNumber.phoneValue || "",
        portfolio: detailApplication.portfolio || "",
        personalWebsite: !_.isEmpty(detailApplication.personalWebsites)
          ? detailApplication.personalWebsites
          : [""],
        selfIntroduction: detailApplication.introduction || "",
        attachment: detailApplication.attachments,
        selectedResumeId: detailApplication.selectedAttachment?.id || null,
        selectedCountry: {
          countryCode: country?.countryCode,
          phoneCode: country?.phoneCode,
          flag: country?.flag,
        },
      },
      jobId: detailApplication.job?.id,
      jobTypeId: detailApplication.jobType?.id || 0,
      applicationId: detailApplication.applicationId || 0,
    });
    loadingPageAction();
  };

  const onBackToHome = () => {
    const targetPaths = [PATH_ROUTER.HOME, PATH_ROUTER.APPLICATION];

    const pathname = _.findLast(dataHistory, (item) =>
      targetPaths.includes(item.pathname)
    );
    safeNavigate(pathname, { state: { jobDetail, indexActive } });
  };

  const handleClickViewCompany = () => {
    window.open(
      window.location.origin +
        PATH_ROUTER.COMPANY +
        "?id=" +
        detailApplication.job.company.id,
      "_blank"
    );
  };

  const handleRevise = () => {
    setState({ isRevising: false, isOpenModal: false });
  };

  const handleApply = async (input: any) => {
    try {
      loadingPageAction(LOADING_TYPES.APPLYING);
      const isSuccess = await handleResubmitLApplicationForm(
        newForm.current.applicationId,
        input
      );
      setState({ isSuccess });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleFetchVersionApplication = async (historyId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      const detailApplication = await fetchApplicationHistories(historyId);
      setState({ detailApplication });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleViewResume = async (resumeId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      await handleDownloadFile(resumeId);
    } finally {
      loadingPageAction();
    }
  };

  const handleClick = (stepData: any, isClickNext: boolean) => {
    if (!_.isEmpty(stepData)) {
      _.merge(newForm.current, stepData);
    }
    if (isClickNext) {
      if (state.step < 2) {
        setState({ step: state.step + 1 });
      } else {
        const formData = newFormDataFormatter(newForm.current);
        handleApply(formData);
      }
    } else {
      setState({ step: state.step - 1 });
    }
  };

  const handleOpenModal = (isOpenModal: boolean) => {
    setState({ isOpenModal });
  };

  const handleOpenSuccessModal = (isSuccess: boolean) => {
    setState({ isSuccess });
  };

  const createVersionMenu = (datas: any[]): MenuProps["items"] => {
    const getDaySuffix = (day: number) => {
      if (day === 1 || day === 21 || day === 31) return "st";
      if (day === 2 || day === 22) return "nd";
      if (day === 3 || day === 23) return "rd";
      return "th";
    };
    return _.map(datas, (data, index) => {
      const day = dayjs(data.createdDate).date();
      const formattedDate = `${dayjs(data.createdDate).format(
        FORMAT_DATE.MMM_D
      )}${getDaySuffix(day)}`;

      return {
        key: datas.length - index,
        label: (
          <div
            className={classNames(
              "menu-version-item",
              state.selectedVersion === datas.length - index &&
                "menu-version-item-active"
            )}
          >
            <div className="item-date">{formattedDate}</div>
            <div className="item-version">Version {datas.length - index}</div>
          </div>
        ),
        onClick: async () => {
          await handleFetchVersionApplication(data.historyId);
          setState({
            selectedVersion: datas.length - index,
          });
        },
      };
    });
  };

  const versionMenu: MenuProps["items"] = createVersionMenu(state.listHistory);

  const handleBackCurrentVerion = async () => {
    await handleFetchVersionApplication(
      applicationDetailReview.histories[0].historyId
    );
    setState({
      selectedVersion: applicationDetailReview.histories.length,
    });
  };

  const renderStep = (step: number) => {
    switch (step) {
      case 2: {
        return (
          <ResumeForm
            defaultData={newForm.current.step2}
            handleClick={handleClick}
            handleCancel={onBackToHome}
            handleOpenSuccessModal={handleOpenSuccessModal}
            isSuccess={state.isSuccess}
            jobId={newForm.current.jobId}
          />
        );
      }
      default: {
        return (
          <NegotiableForm
            defaultData={newForm.current.step1}
            handleClick={handleClick}
            handleCancel={onBackToHome}
            isLoading={state.isLoading}
          />
        );
      }
    }
  };

  useEffect(() => {
    createIntitialData();
    loadingPageAction();
  }, [detailApplication]);

  useEffect(() => {
    const element = document.getElementsByClassName(
      "content-title"
    )[0] as HTMLElement;
    if (element) {
      const elementHeight = element.offsetHeight;
      const elementForm = document.getElementsByClassName(
        "form-application"
      )[0] as HTMLElement;
      if (elementForm) {
        const heightForm = `calc(100vh - 377px - ${elementHeight}px)`;
        elementForm.style.height = heightForm;
      }
    }
  }, []);

  return (
    <>
      <ModalComponent
        className="modal-revise"
        open={state.isOpenModal}
        onCancel={() => handleOpenModal(false)}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="revise-btn"
              title="Start revising"
              size="large"
              type="primary"
              onClick={handleRevise}
            />
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              size="large"
              type="default"
              onClick={() => handleOpenModal(false)}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <img src={EditFormIcon} alt="edit-form" />
          <div className="title">Will you start revising your application?</div>
          <div className="title-content">
            You can revise your application here and submit again.
          </div>
          <div className="title-caution">
            <strong>Cautious:</strong> Resubmitting your application after
            making changes without consulting the company may lead to issues in
            the process.
          </div>
        </div>
      </ModalComponent>
      <div className="background-application-form-revise">
        <div className="header">
          <ButtonComponent
            className="back-btn"
            title="Application"
            type="link"
            onClick={onBackToHome}
            icon={<ArrowLeft size={24} />}
            iconPosition="start"
          />
        </div>
        {state.isRevising ? (
          <div className="content-revise">
            <div className="content-title">
              <div className="content-title-left">
                <div className="job-title">
                  {detailApplication.jobTitle.delta.company}
                  {"  "}
                  {detailApplication.job.negotiable && (
                    <span className="title-sub">(Negotiable)</span>
                  )}
                </div>
                {state.listHistory.length > 1 && (
                  <div className="revised-history">
                    <div className="revised-history-title">
                      Revised application version history:
                    </div>
                    <Dropdown
                      overlayClassName="menu-version"
                      menu={{ items: versionMenu }}
                      trigger={["click"]}
                    >
                      <div className="revised-history-version">
                        Version {state.selectedVersion}
                        <span className="caret-down-icon">
                          <CaretDown size={12} color="#fff" />
                        </span>
                      </div>
                    </Dropdown>
                  </div>
                )}
              </div>
              <div className="content-title-right">
                {renderStatus(applicationDetailReview.statusId)}
              </div>
            </div>
            <div className="info-company">
              <div className="info-detail">
                <img
                  src={
                    detailApplication.job?.company?.logoUrl || EmptyCompanyLogo
                  }
                  alt="avatar"
                  className="company-avatar"
                  width={56}
                  height={56}
                />
                <div className="company-description">
                  <div className="title">
                    {detailApplication.job.company?.name}
                  </div>
                  {detailApplication.job?.location?.city && (
                    <div className="company-info-position">
                      <EnvironmentOutlined color="#FF7710" />
                      {_.compact([
                        detailApplication.job.location.city,
                        detailApplication.job.location.country,
                      ]).join(", ")}
                    </div>
                  )}
                </div>
              </div>
              <ButtonComponent
                className="view-btn"
                title="View profile"
                icon={<ExportOutlined />}
                iconPosition="end"
                onClick={handleClickViewCompany}
              />
            </div>
            <div className="form-application">
              <InputPrefix
                value={detailApplication.jobTitle.delta.candidate}
                title="Job Title"
                subTitle={
                  detailApplication.job.negotiableTitle && "(Negotiable)"
                }
                valuePrefix={detailApplication.jobTitle.delta.company}
                disabled={!detailApplication.job.negotiableTitle}
                type="input"
                onClick={
                  detailApplication.job.negotiableTitle && isAbledToRevise
                    ? () => handleOpenModal(true)
                    : undefined
                }
                readOnly
              />
              <InputDefault
                title="Job Type"
                value={getLabelByValue(
                  JobTypeOptions,
                  detailApplication.jobType.id
                )}
                type="input"
                readOnly
                info={
                  detailApplication.jobType.id === 1
                    ? {
                        title: "What is the O-CA Program",
                        description:
                          "The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties.",
                      }
                    : undefined
                }
              />
              <div className="double-input">
                <InputPrefix
                  value={
                    detailApplication.workPeriodStart.delta.candidate
                      ? formatDate(
                          detailApplication.workPeriodStart.delta.candidate
                        )
                      : ""
                  }
                  title="Start working date"
                  placeholder=""
                  subTitle={
                    detailApplication.job.negotiableWorkStart && "(Negotiable)"
                  }
                  type="input"
                  readOnly
                  valuePrefix={formatDate(
                    detailApplication.workPeriodStart.delta.company
                  )}
                  onClick={
                    detailApplication.job.negotiableWorkStart && isAbledToRevise
                      ? () => handleOpenModal(true)
                      : undefined
                  }
                />
                <InputPrefix
                  value={
                    detailApplication.workPeriodEnd.delta.candidate
                      ? formatDate(
                          detailApplication.workPeriodEnd.delta.candidate
                        )
                      : ""
                  }
                  title="End working date"
                  subTitle={
                    detailApplication.job.negotiableWorkEnd && "(Negotiable)"
                  }
                  type="input"
                  readOnly
                  placeholder=""
                  valuePrefix={formatDate(
                    detailApplication.workPeriodEnd.delta.company
                  )}
                  onClick={
                    detailApplication.job.negotiableWorkEnd && isAbledToRevise
                      ? () => handleOpenModal(true)
                      : undefined
                  }
                />
              </div>
              <div className="double-input">
                <InputPrefix
                  value={getLabelByValue(
                    WorkTypeOptions,
                    detailApplication.workplaceType.delta.candidate
                  )}
                  readOnly
                  title="Workplace type"
                  subTitle={
                    detailApplication.job.negotiableWorkplaceType &&
                    "(Negotiable)"
                  }
                  type="input"
                  valuePrefix={getLabelByValue(
                    WorkTypeOptions,
                    detailApplication.workplaceType.delta.company
                  )}
                  onClick={
                    detailApplication.job.negotiableWorkplaceType &&
                    isAbledToRevise
                      ? () => handleOpenModal(true)
                      : undefined
                  }
                />
                <InputPrefix
                  value={detailApplication.workHoursPerWeek.delta?.candidate}
                  title="Hours per week"
                  subTitle={
                    detailApplication.job.negotiableHoursPerWeek &&
                    "(Negotiable)"
                  }
                  valuePrefix={
                    detailApplication.workHoursPerWeek.delta?.company
                  }
                  disabled={!detailApplication.job.negotiableHoursPerWeek}
                  readOnly
                  type="input"
                  onClick={
                    detailApplication.job.negotiableHoursPerWeek &&
                    isAbledToRevise
                      ? () => handleOpenModal(true)
                      : undefined
                  }
                />
              </div>
              <InputDefault
                value={detailApplication.job.description}
                title="About the job"
                readOnly
                type="text-area"
              />
              <div
                onClick={
                  detailApplication.job.negotiableTasks && isAbledToRevise
                    ? () => handleOpenModal(true)
                    : undefined
                }
              >
                <InputPrefix
                  title="Task"
                  type="multiple-input-quill"
                  disabled={!detailApplication.job.negotiableTasks}
                  readOnly={!detailApplication.job.negotiableTasks}
                  value={_.map(detailApplication.tasks, (task) => {
                    return {
                      ...task.delta.company,
                      newTask: task.delta.candidate?.description || "",
                      taskId: uuidv4(),
                      isRemove:
                        task.negotiable &&
                        _.isEmpty(task.delta.candidate.description),
                    };
                  })}
                  subTitle={
                    detailApplication.job.negotiableTasks && "(Negotiable)"
                  }
                />
              </div>
              <InputDefault
                value={_.map(
                  detailApplication.job.qualifications,
                  (qualification) => qualification.description
                ).join("\n")}
                title="Minimum Qualifications"
                readOnly
                type="text-area"
              />
              <div className="resume">
                <div className="resume-title">Resume</div>
                <div className="resume-content">
                  <div className="resume-content-left">
                    <div className="resume-name">
                      {detailApplication.selectedAttachment.name}
                    </div>
                    <div className="resume-upload-date">
                      Upload on{" "}
                      {formatDate(
                        detailApplication.selectedAttachment.uploadDate
                      )}
                    </div>
                  </div>
                  <div className="resume-content-right">
                    <ButtonComponent
                      className="view-btn"
                      icon={<EyeOutlined style={{ fontSize: 20 }} />}
                      onClick={() =>
                        handleViewResume(
                          detailApplication.selectedAttachment.id
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <InputDefault
                value={detailApplication.email}
                title="Email"
                type="input"
                readOnly
              />
              {detailApplication.phoneNumber.phoneValue && (
                <InputDefault
                  onClick={
                    isAbledToRevise ? () => handleOpenModal(true) : undefined
                  }
                  value={detailApplication.phoneNumber.phoneValue}
                  valueSelect={
                    detailApplication.phoneNumber.countryCode
                      ? _.find(
                          countriesOption,
                          (country) =>
                            country.countryCode ===
                            detailApplication.phoneNumber.countryCode
                        )
                      : _.find(
                          countriesOption,
                          (country) => country.countryCode === "US"
                        )
                  }
                  option={countriesOption}
                  title="Phone number"
                  type="phone-number"
                  readOnly
                />
              )}
              {detailApplication.portfolio && (
                <InputDefault
                  onClick={
                    isAbledToRevise ? () => handleOpenModal(true) : undefined
                  }
                  value={detailApplication.portfolio}
                  title="Portfolio"
                  type="input"
                  addonBefore="https://"
                  readOnly
                />
              )}
              {_.map(detailApplication.personalWebsites, (website) => (
                <InputDefault
                  onClick={
                    isAbledToRevise ? () => handleOpenModal(true) : undefined
                  }
                  value={website}
                  title="Personal website"
                  type="input"
                  addonBefore="https://"
                  readOnly
                />
              ))}
              {detailApplication.introduction && (
                <InputDefault
                  onClick={
                    isAbledToRevise ? () => handleOpenModal(true) : undefined
                  }
                  value={detailApplication.introduction}
                  title="Self-Introduction"
                  type="text-area"
                  readOnly
                />
              )}
            </div>
            <div className="action">
              <div className="action-left"></div>
              <div className="action-right">
                {state.selectedVersion ===
                applicationDetailReview.histories.length ? (
                  <>
                    <ButtonComponent
                      className="cancel-btn"
                      title="Cancel"
                      onClick={onBackToHome}
                    />
                    <ButtonComponent
                      className="revise-btn"
                      type="primary"
                      title="Revising"
                      disabled={!isAbledToRevise}
                      onClick={() => handleOpenModal(true)}
                    />
                  </>
                ) : (
                  <ButtonComponent
                    className="cancel-btn"
                    title="Back to current verision"
                    onClick={handleBackCurrentVerion}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="content-form">
            <div className="switch-component switch-background">
              <div
                className={classNames(
                  "switch-item",
                  state.step === 1 && "active"
                )}
              >
                <div className="switch-item-index">
                  {state.step === 2 ? (
                    <img src={CheckIcon} alt="check-icon" />
                  ) : (
                    1
                  )}
                </div>
                <div className="switch-item-title">Negotiable</div>
              </div>
              <div
                className={classNames(
                  "switch-item",
                  state.step === 2 && "active"
                )}
              >
                <div className="switch-item-index">2</div>
                <div className="switch-item-title">Resume</div>
              </div>
            </div>
            {renderStep(state.step)}
          </div>
        )}
      </div>
    </>
  );
};

export default ApplicationFormRevise;
