import { ApplicationDetail } from "../interfaces/application";
import axios from "./axiosConfig";

export const fetchApplicationHistories = async (
  historiesId: number
): Promise<ApplicationDetail | void> => {
  try {
    const response = await axios.get(`application-histories/${historiesId}`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
