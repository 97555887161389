import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, MenuProps } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BackRightIcon, Logo, UserIcon } from "../../assets/svg";
import { PATH_ROUTER } from "../../constants";
import { LOADING_TYPES } from "../../constants/loadingTypes";
import { handleSignOut } from "../../services/handleSignOut";
import updateGotoData from "../../store/actions/goto";
import loadingPage from "../../store/actions/loading";
import { clearAllCookies } from "../../utils";
import auth from "../../utils/auth";
import useActions from "../../utils/customHook/useActions";
import useMergeState from "../../utils/customHook/useMergeState";
import { safeNavigate } from "../../utils/helper";
import ButtonComponent from "../button/button";
import "./header.s.scss";

interface IPropsHeader {
  toggleDrawer?: () => void;
}

const Header: React.FC<IPropsHeader> = ({ toggleDrawer }) => {
  const dispatch = useDispatch();
  const loadingPageAction = useActions(loadingPage);
  const meGotoRedux = useSelector((state: any) => state.goto.me);

  const [state, setState] = useMergeState({
    avatar: auth.avatar(),
    listNotification: [],
  });

  const handleViewProfile = () => {
    safeNavigate(PATH_ROUTER.PROFILE);
  };

  // const handleNewNotification = (notification: NotificationMessage) => {
  //   setState({
  //     listNotification: [notification, ...state.listNotification].slice(0, 10),
  //   });
  //   dispatch(
  //     updateGotoData("me", {
  //       notifications: [notification, ...state.listNotification].slice(0, 10),
  //     })
  //   );
  // };

  const handleLogout = async () => {
    try {
      loadingPageAction(LOADING_TYPES.LOGGING_OUT);
      await handleSignOut();
      safeNavigate(PATH_ROUTER.LANDING_PAGE);
      const updateHomeRedux = {
        cityId: 0,
        stateId: 0,
        countryId: 1,
        listJob: [],
        jobDetail: {},
        count: null,
        page: 1,
      };
      const updateApplicationRedux = {
        statusId: -1,
        listApplication: [],
        applicationDetail: {},
        count: null,
      };
      dispatch(updateGotoData("home", updateHomeRedux));
      dispatch(updateGotoData("application", updateApplicationRedux));
      dispatch(updateGotoData("me", {}));
      auth.clearLocalStorage();
      clearAllCookies();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  // const getListNotification = async () => {
  //   try {
  //     const result = await fetchListNotification(0, 10);
  //     if (result && result.content) {
  //       const data = result.content;
  //       setState({ listNotification: data });
  //       dispatch(updateGotoData("me", { notifications: data }));
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const items: MenuProps["items"] = [
    {
      className: "menu-user-item",
      label: (
        <>
          <img src={UserIcon} alt="user-icon" /> Profile
        </>
      ),
      key: "0",
      onClick: handleViewProfile,
    },
    {
      className: "menu-user-item",
      label: (
        <>
          <img src={BackRightIcon} alt="back-right-icon" /> Log out
        </>
      ),
      key: "1",
      onClick: handleLogout,
    },
  ];

  // const itemsNoticed: MenuProps["items"] = [
  //   {
  //     key: 0,
  //     label: (
  //       <div
  //         className="dropdown-noticed-header"
  //         onClick={(e) => e.stopPropagation()}
  //       >
  //         Notifications
  //       </div>
  //     ),
  //   },
  //   ..._.map(state.listNotification.slice(0, 6), (item, index) => {
  //     return {
  //       key: index + 1,
  //       label: (
  //         <div
  //           className="dropdown-noticed-item"
  //           onClick={() => safeNavigate(PATH_ROUTER.APPLICATION)}
  //         >
  //           <div className="noticed-item-icon">
  //             <img src={ApplicationInactiveIcon} alt="" />
  //             {!item.viewed && <div className="shape-unread" />}
  //           </div>
  //           <div className="dropdown-noticed-item-content">
  //             <div className="dropdown-noticed-item-title">
  //               <h5>{item.title}</h5>
  //               <span>
  //                 {dayjs(item.createdDate).isValid()
  //                   ? dayjs(item.createdDate).format(FORMAT_DATE.MMM_D_YYYY)
  //                   : "Invalid date"}
  //               </span>
  //             </div>
  //             <p>{item.text}</p>
  //           </div>
  //         </div>
  //       ),
  //     };
  //   }),
  //   ...(state.listNotification.length > 6
  //     ? [
  //         {
  //           key: state.listNotification.length + 1,
  //           label: (
  //             <ButtonComponent
  //               className="dropdown-noticed-action"
  //               title="See all notifications"
  //               onClick={() => safeNavigate(PATH_ROUTER.NOTICE)}
  //             />
  //           ),
  //         },
  //       ]
  //     : []),
  // ];

  // useEffect(() => {
  // Check connection status before connecting
  // console.log(
  //   "WebSocket Connected:",
  //   notificationService.getConnectionStatus()
  // );

  // getListNotification();
  // notificationService.setMessageCallback(handleNewNotification);
  // notificationService.connect();

  // return () => {
  //   notificationService.disconnect();
  // };
  // }, []);

  // useEffect(() => {
  //   // Check connection status before connecting
  //   console.log(
  //     "WebSocket Connected:",
  //     notificationService.getConnectionStatus()
  //   );

  //   getListNotification();
  //   notificationService.setMessageCallback(handleNewNotification);
  //   notificationService.connect();

  //   return () => {
  //     notificationService.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    if (auth.isCandidateUser()) {
      if (meGotoRedux?.avatarUrl && meGotoRedux.avatarUrl !== state.avatar) {
        setState({ avatar: meGotoRedux.avatarUrl });
      }
    } else {
      if (meGotoRedux?.logoUrl && meGotoRedux.logoUrl !== state.avatar) {
        setState({ avatar: meGotoRedux.logoUrl });
      }
    }
  }, [meGotoRedux]);

  useEffect(() => {
    setState({ listNotification: meGotoRedux?.notifications || [] });
  }, [meGotoRedux?.notifications]);

  return (
    <div className="header">
      <div className="header-left">
        <Link to="/dash-board/home">
          <img src={Logo} alt="logo" />
        </Link>
      </div>
      <div className="header-right">
        {/* <Dropdown
            overlayClassName="dropdown-noticed"
            menu={{ items: itemsNoticed }}
            trigger={["click"]}
          >
            <div className="bell">
              <Bell size={20} />
              {_.filter(state.listNotification, { viewed: false }).length >
                0 && <div className="shape-unread" />}
            </div>
          </Dropdown> */}
        <Dropdown className="menu-user" menu={{ items }} trigger={["click"]}>
          {state.avatar !== "null" ? (
            <img
              className="avatar"
              width={32}
              height={32}
              src={state.avatar}
              alt="avatar"
            />
          ) : (
            <Avatar size={32} icon={<UserOutlined />} className="avatar" />
          )}
        </Dropdown>
        <ButtonComponent
          className="drawer-menu-icon"
          icon={<MenuOutlined size={18} />}
          onClick={toggleDrawer}
        />
      </div>
    </div>
  );
};

export default Header;
