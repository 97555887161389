import { notification } from "antd";

interface INotificationProps {
  type: "success" | "info" | "warning" | "error";
  message: string;
  description?: string;
  duration?: number;
  placement?: "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
}

export const showNotification = ({
  type,
  message,
  description,
  duration = 3,
  placement = "topRight",
}: INotificationProps) => {
  notification[type]({
    message,
    description,
    duration,
    placement,
  });
};
