import { AxiosResponse } from "axios";
import { Career } from "../interfaces/profile";
import axios from "./axiosConfig";

export const handleUpdateCareer = async (
  id: Career["id"],
  requestBody: Partial<Career>
): Promise<boolean | void> => {
  try {
    const response: AxiosResponse<boolean> = await axios.patch(
      `careers/${id}`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
