import { AxiosResponse, isAxiosError } from "axios";
import { showNotification } from "../components/notification/notification";
import { UpdateCompanyProfileRequest } from "../interfaces/profile";
import axios from "./axiosConfig";

export const handleUpdateCompanyProfile = async (
  requestBody: UpdateCompanyProfileRequest
): Promise<boolean | void> => {
  try {
    const response: AxiosResponse<boolean> = await axios.patch(
      "companies/me",
      requestBody
    );
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred.";
      console.error("Error:", errorMessage);
      showNotification({
        type: "error",
        message: "Update Failed!",
        description: errorMessage,
      });
      return errorMessage;
    } else {
      console.error("Error:", error);
      showNotification({
        type: "error",
        message: "Update Failed!",
        description: "An unexpected error occurred.",
      });
    }
  }
};
