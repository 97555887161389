import { Form } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import InputDefault from "../../../../../components/input/inputDefault/inputDefault";
import { Education } from "../../../../../interfaces/profile";
import { parseDateString } from "../../../../../utils/formatter";

interface FormAddEducationProps {
  form?: any;
  initialValues?: Partial<Education>;
  onSubmit?: (values: Partial<Education>) => void;
}

const FormAddEducation: React.FC<FormAddEducationProps> = ({
  form,
  initialValues,
  onSubmit,
}) => {
  useEffect(() => {
    if (initialValues) {
      const formattedInitialValues = {
        ...initialValues,
        startDate: parseDateString(initialValues.startDate),
        endDate: parseDateString(initialValues.endDate),
      };
      form.setFieldsValue(formattedInitialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues, form]);

  // Validate start date
  const validateStartDate = (_: any, value: any) => {
    if (!value) {
      return Promise.reject("Please select start date");
    }

    if (value.isAfter(dayjs())) {
      return Promise.reject("Start date cannot be in the future");
    }

    return Promise.resolve();
  };

  // Validate end date
  const validateEndDate = (_: any, value: any) => {
    const startDate = form.getFieldValue("startDate");

    if (!value) {
      return Promise.reject("Please select end date");
    }

    if (startDate && value.isBefore(startDate)) {
      return Promise.reject("End date cannot be before start date");
    }

    // Allow end dates up to 10 years in the future for expected graduation
    if (value.isAfter(dayjs().add(10, "year"))) {
      return Promise.reject("End date is too far in the future");
    }

    return Promise.resolve();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <div className="field">
        <Form.Item
          name="schoolName"
          rules={[
            {
              required: true,
              message: "Please enter school name",
            },
          ]}
        >
          <InputDefault
            title="School name"
            type="input"
            placeholder="Ex. Boston University"
            required
          />
        </Form.Item>
      </div>

      <div className="field">
        <Form.Item
          name="degree"
          rules={[
            {
              required: true,
              message: "Please enter degree",
            },
          ]}
        >
          <InputDefault
            title="Degree"
            type="input"
            placeholder="Ex. Bachelor's"
            required
          />
        </Form.Item>
      </div>

      <div className="field">
        <Form.Item
          name="major"
          rules={[
            {
              required: true,
              message: "Please enter major",
            },
          ]}
        >
          <InputDefault
            title="Major"
            type="input"
            placeholder="Ex. Business"
            required
          />
        </Form.Item>
      </div>

      <div className="double-field">
        <Form.Item
          name="startDate"
          rules={[
            {
              validator: validateStartDate,
            },
          ]}
        >
          <InputDefault
            title="Start month"
            type="month-picker"
            placeholder="MM/YYYY"
            required
          />
        </Form.Item>

        <Form.Item
          name="endDate"
          dependencies={["startDate"]}
          rules={[
            {
              validator: validateEndDate,
            },
          ]}
        >
          <InputDefault
            title="End month (or expected)"
            type="month-picker"
            placeholder="MM/YYYY"
            required
          />
        </Form.Item>
      </div>
      <div className="field-optional">
        <Form.Item name="gradePoint">
          <InputDefault title="GPA" type="input" placeholder="Ex. 3.75/4.00" />
        </Form.Item>
      </div>

      <div className="field-optional">
        <Form.Item name="description">
          <InputDefault
            title="Description"
            type="text-area"
            maxLength={300}
            showCount
            maxRows={5}
            placeholder="Describe your education experience (e.g., degree obtained, major, key accomplishments)"
          />
        </Form.Item>
      </div>
    </Form>
  );
};

export default FormAddEducation;
