import { CompanyProfile } from "../interfaces/profile";
import axios from "./axiosConfig";

export const fetchCompanyProfile = async (): Promise<CompanyProfile | void> => {
  try {
    const response = await axios.get<CompanyProfile>("companies/me");
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
