import { AxiosResponse } from "axios";
import { Action } from "../interfaces/application";
import axios from "./axiosConfig";

export const handleActions = async (
  applicationId: number
): Promise<Action[]> => {
  try {
    const response: AxiosResponse<Action[]> = await axios.post(
      `applications/${applicationId}/actions`
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};
