import axios from "./axiosConfig";

export const handleUpdateStatusCandidate = async (statusId: number) => {
  try {
    const response = await axios.post(`individuals/me?status=${statusId}`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
