import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import _ from "lodash";
import { Option } from "../interfaces";
import axios from "./axiosConfig";

dayjs.extend(utc);
dayjs.extend(timezone);

const isDST = (timeZone: string) => {
  const currentYear = dayjs().year();

  const januaryOffset = dayjs.tz(`${currentYear}-01-01`, timeZone).utcOffset();
  const julyOffset = dayjs.tz(`${currentYear}-07-01`, timeZone).utcOffset();

  return januaryOffset !== julyOffset;
};

export const fetchTimezones = async (): Promise<Option[]> => {
  try {
    const response = await axios.get("timezones");
    const timezones = response.data;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const isUserInDST = isDST(userTimeZone);

    const timezoneData = _.map(timezones, (timezone) => {
      const offset = isUserInDST
        ? timezone.utfOffsetDst
        : timezone.utcOffsetSdt;
      return {
        id: timezone.id,
        offset: parseFloat(offset),
        value: `${_.replace(timezone.tzIdentifier, /_/g, " ")} (UTC${offset})`,
        label: `${_.replace(timezone.tzIdentifier, /_/g, " ")} (UTC${offset})`,
      };
    });
    const sortedTimezoneData = _.sortBy(timezoneData, ["offset"]);
    return sortedTimezoneData.map(({ offset, ...rest }) => rest);
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};
