import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import store from "./store/index.ts";
import "./styles/globals.scss";

window.onload = () => {
  const loader = document.querySelector(".loading-container") as HTMLElement;
  if (loader) {
    loader.style.display = "none"; // Ẩn loading
  }
};

const root = createRoot(document.getElementById("root")!);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
