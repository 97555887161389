export default {
  loading: {
    loadingText: "Loading...",
  },
  reloadPage: {
    home: false,
    application: false,
  },
  goto: {
    home: {
      cityId: 0,
      stateId: 0,
      countryId: 1,
      listJob: [],
      jobDetail: {},
      count: null,
      page: 1,
    },
    application: {
      statusId: -1,
      listApplication: [],
      applicationDetail: {},
      count: null,
      page: 1,
    },
    me: {},
  },
};
