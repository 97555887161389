import { AxiosResponse } from "axios";
import axios from "./axiosConfig";
import { Career } from "../interfaces/profile";

export const handleAddCareer = async (
  requestBody: Omit<Career, "id">
): Promise<boolean | void> => {
  try {
    const response: AxiosResponse<boolean> = await axios.put(
      `careers`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
