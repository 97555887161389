/* eslint-disable @typescript-eslint/no-explicit-any */

import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import ButtonComponent from "../../../components/button/button";
import InputDefault from "../../../components/input/inputDefault/inputDefault";
import InputPrefix from "../../../components/input/inputPrefix/inputPrefix";
import ModalComponent from "../../../components/modal/modal";
import {
  JobTypeOptions,
  WorkTypeOptions,
} from "../../../constants/selectOptions";
import { getLabelByValue } from "../../../utils";
import useMergeState from "../../../utils/customHook/useMergeState";
import { formatDate } from "../../../utils/formatter";

interface NegotiableFormProps {
  defaultData: any;
  handleClick: (stepData: any, isClickNext: boolean) => void;
  handleCancel: () => void;
  isLoading: boolean;
}

const NegotiableForm: React.FC<NegotiableFormProps> = ({
  defaultData,
  handleClick,
  handleCancel,
  isLoading,
}) => {
  const [state, setState] = useMergeState({
    errorMsg: "",
    idFocus: "",
  });

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const validInput = /^[0-9]*$/.test(value);
    if (validInput) {
      setState({ hoursPerWeek: value });
    }
  };

  const handleChangeMultiple = (value: string, id: string) => {
    const { currentTasks } = state;
    if (currentTasks.length > 0) {
      const updateTask = _.map(currentTasks, (task) => {
        return task.taskId == id
          ? { ...task, newTask: value, isRemove: false }
          : task;
      });
      setState({ currentTasks: updateTask });
    }
  };

  const handleDateChange = (keyValue: string, date: dayjs.Dayjs | null) => {
    if (date) {
      const isoDate = dayjs(date).toISOString();
      setState({ [keyValue]: isoDate });
    } else {
      setState({ [keyValue]: null });
    }
    setState({ errorMsg: "" });
  };

  const handleSelectChange = (keyValue: string, value: string) => {
    setState({ [keyValue]: value });
  };

  const handleAddTaskBelow = useCallback(
    (id: string) => {
      const newId = uuidv4();
      const newTask = {
        id: null,
        description: "",
        newTask: "",
        isRemove: false,
        taskId: newId,
      };

      const currentIndex = _.findIndex(state.currentTasks, { taskId: id });

      if (currentIndex !== -1) {
        const updatedTasks = _.cloneDeep(state.currentTasks);
        updatedTasks.splice(currentIndex + 1, 0, newTask);
        setState({ currentTasks: updatedTasks, idFocus: newId });
      }
    },
    [state.currentTasks]
  );

  const handleKeyDown = useCallback(
    (id: string, e: React.KeyboardEvent<HTMLInputElement>) => {
      const { currentTasks } = state;
      // Handling task removal Backspace
      if (e.key === "Backspace") {
        const task = _.find(currentTasks, (item) => item?.taskId === id);
        if (task) {
          if (task.id !== null && task.newTask === "") {
            const updatedTasks = _.map(currentTasks, (t) =>
              t.taskId === id ? { ...t, isRemove: true } : t
            );
            setState({ currentTasks: updatedTasks });
          } else if (task.id === null && task.newTask === "") {
            const updatedTasks = _.filter(currentTasks, (t) => t.taskId !== id);
            const index = _.findIndex(currentTasks, { taskId: id });
            setState({
              currentTasks: updatedTasks,
              idFocus: updatedTasks[index - 1].taskId,
            });
          }
        }
      }

      if (e.key === "Enter") {
        e.preventDefault();
        handleAddTaskBelow(id); // Add a new task below the current one
      }
    },
    [state.currentTasks, setState, handleAddTaskBelow]
  );

  const handleOpenGuideModal = (isOpenGuideModal: boolean) => {
    setState({ isOpenGuideModal });
  };

  const handleNext = () => {
    const { startDate, currentStartDate, endDate, currentEndDate } = state;
    const isValidRange =
      startDate && endDate
        ? dayjs(startDate).isBefore(dayjs(endDate), "day")
        : startDate
        ? dayjs(startDate).isBefore(dayjs(currentEndDate), "day")
        : endDate
        ? dayjs(currentStartDate).isBefore(dayjs(endDate), "day")
        : true;
    if (isValidRange) {
      handleClick({ step1: state }, true);
    } else {
      setState({ errorMsg: "End date must be after the start date" });
    }
  };

  useEffect(() => {
    if (_.isEmpty(defaultData.currentTasks)) {
      const newId = uuidv4();
      const newTask = {
        id: null,
        description: "",
        newTask: "",
        isRemove: false,
        taskId: newId,
      };
      const newDefaultData = defaultData;
      newDefaultData.currentTasks?.push(newTask);
      setState(newDefaultData);
    } else {
      // map lại currentTasks để thêm taskId bằng uuid
      const newTasks = _.map(defaultData.currentTasks, (task) => {
        return { ...task, taskId: uuidv4() };
      });
      defaultData.currentTasks = newTasks;
      setState(defaultData);
    }
  }, [defaultData]);

  return (
    <>
      <ModalComponent
        className="modal-confirm"
        open={!isLoading ? state.isOpenGuideModal : false}
        onOk={() => handleOpenGuideModal(false)}
        onCancel={() => handleOpenGuideModal(false)}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="confirm-btn"
              title="Got It"
              size="large"
              type="primary"
              onClick={() => handleOpenGuideModal(false)}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="modal-top"></div>
          <div className="modal-bottom">
            <div className="title">Customize Your Application</div>
            <div className="title-content">
              Fields marked as &apos;
              <span style={{ color: "#0A5CD8" }}>Negotiable</span>&apos; allow
              you to revise your offer. Update these fields to customize your
              application to match your counteroffer needs.
            </div>
          </div>
        </div>
      </ModalComponent>
      <div className="content-title">
        <div className="title-step">Customize Your Application</div>
        <div className="subtitle-step">
          Adjust the fields below to propose changes and negotiate terms that
          better fit your needs
          {state.negotiable && (
            <span className="question-btn">
              <QuestionCircleOutlined
                style={{ color: "#0A5CD8", cursor: "pointer" }}
                onClick={() => handleOpenGuideModal(true)}
              />
            </span>
          )}
        </div>
      </div>
      <div className="form-application">
        <InputPrefix
          value={state.jobTitle}
          title="Job Title"
          subTitle={state.negotiableTitle && "(Negotiable)"}
          valuePrefix={state.currentJobTitle}
          disabled={!state.negotiableTitle}
          type="input-quill"
          handleChangeInputQuill={(value) => {
            setState({ jobTitle: value });
          }}
        />
        <InputDefault
          title="Job Type"
          type="input"
          value={getLabelByValue(JobTypeOptions, state.currentJobType)}
          disabled
          info={
            state.currentJobType === 1
              ? {
                  title: "What is the O-CA Program",
                  description:
                    "The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties.",
                }
              : undefined
          }
        />
        <div className="double-input">
          <InputPrefix
            value={state.startDate}
            title="Start working date"
            subTitle={state.negotiableWorkStart && "(Negotiable)"}
            type="date"
            placeholder=""
            allowClear
            disabled={!state.negotiableWorkStart}
            valuePrefix={formatDate(state.currentStartDate)}
            onChange={(date) => handleDateChange("startDate", date)}
          />
          <InputPrefix
            value={state.endDate}
            title="End working date"
            subTitle={state.negotiableWorkEnd && "(Negotiable)"}
            type="date"
            disabled={!state.negotiableWorkEnd}
            allowClear
            placeholder=""
            errorMsg={state.errorMsg}
            disabledDateBefore={state.startDate}
            valuePrefix={formatDate(state.currentEndDate)}
            onChange={(date) => handleDateChange("endDate", date)}
          />
        </div>
        <div className="double-input">
          <InputPrefix
            value={state.workplaceType}
            title="Workplace type"
            subTitle={state.negotiableWorkplaceType && "(Negotiable)"}
            type="select"
            readOnly={!state.negotiableWorkplaceType}
            valuePrefix={state.currentWorkplaceType}
            options={WorkTypeOptions}
            onChange={(value) => handleSelectChange("workplaceType", value)}
          />
          <InputPrefix
            value={state.hoursPerWeek}
            title="Hours per week"
            subTitle={state.negotiableHoursPerWeek && "(Negotiable)"}
            valuePrefix={state.currentHoursPerWeek}
            disabled={!state.negotiableHoursPerWeek}
            type="input"
            onChange={handleNumberChange}
          />
        </div>
        <InputDefault
          value={state.currentDescription}
          title="About the job"
          minRows={5}
          maxRows={5}
          disabled
          type="text-area"
        />
        <InputPrefix
          value={state.currentTasks}
          title="Task"
          type="multiple-input-quill"
          disabled={!state.negotiableTasks}
          onKeyDown={(e, id) => handleKeyDown(id, e)}
          subTitle={state.negotiableTasks && "(Negotiable)"}
          handleChangeMultiple={handleChangeMultiple}
          idFocus={state.idFocus}
        />
        <InputDefault
          value={state.currentQualifications}
          title="Minimum Qualifications"
          minRows={5}
          maxRows={5}
          disabled
          type="text-area"
        />
      </div>
      <div className="action">
        <div className="action-left"></div>
        <div className="action-right">
          <ButtonComponent
            className="cancel-btn"
            title="Cancel"
            size="large"
            onClick={handleCancel}
          />
          <ButtonComponent
            className="continue-btn"
            type="primary"
            size="large"
            title="Continue"
            onClick={handleNext}
          />
        </div>
      </div>
    </>
  );
};

export default NegotiableForm;
