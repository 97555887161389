import { useCallback, useState } from "react";

interface UseCopyReturn {
  copied: boolean;
  copy: (text: string) => Promise<boolean>;
}

export const useCopy = (): UseCopyReturn => {
  const [copied, setCopied] = useState<boolean>(false);

  const copy = useCallback(async (text: string): Promise<boolean> => {
    if (!navigator.clipboard) {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);

      // Reset copied state after 2 seconds
      setTimeout(() => {
        setCopied(false);
      }, 2000);

      return true;
    } catch (error) {
      console.error("Failed to copy:", error);
      setCopied(false);
      return false;
    }
  }, []);

  return { copied, copy };
};
