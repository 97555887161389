import axios from "./axiosConfig";

export const handleUploadImage = async (file: any) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.put(`media-files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
