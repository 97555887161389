import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import InputDefault from "../../../components/input/inputDefault/inputDefault";
import { fetchListTag } from "../../../services/fetchListTag";
import "./addTagModal.s.scss";

interface SelectOption {
  id: number | null;
  name: string;
  value: string;
  label: string;
}

type AddTagModalProps = {
  typeId: number;
  description: string;
  titleSelect: string;
  placeholder: string;
  onChange: (values: SelectOption[]) => void;
  value: SelectOption[];
  entityTypeId: number;
};

const AddTagModal: React.FC<AddTagModalProps> = ({
  typeId,
  description,
  titleSelect,
  placeholder,
  onChange,
  value,
  entityTypeId,
}) => {
  const [valueSelected, setValueSelected] = useState<SelectOption[]>(value);
  const [option, setOption] = useState<SelectOption[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const fetchSearch = useCallback(
    _.debounce(async (text: string) => {
      try {
        const filter = { tag: text, entityTypeId, categoryId: typeId };
        const data = await fetchListTag(0, 10, filter);
        if (data && !_.isEmpty(data)) {
          const options = _.map(data.content, (tag) => ({
            id: tag.tagId,
            name: tag.tagName,
            value: tag.tagName,
            label: tag.tagName,
          }));
          setOption(options);
        } else {
          setOption([]);
        }
      } catch (error) {
        console.error("Error:", error);
        setOption([]);
      }
    }, 500),
    [typeId]
  );

  const handleSearch = (text: string) => {
    if (valueSelected.length >= 5) return;
    setSearchValue(text);
    fetchSearch(text);
  };

  const handleSelect = (value: string) => {
    if (valueSelected.length >= 5) return;
    const existingOption = option.find((opt) => opt.value === value);
    const selectedOption = existingOption || {
      id: null,
      name: value,
      label: value,
      value: value,
    };

    const updatedSelected = [...valueSelected, selectedOption];
    setValueSelected(updatedSelected);
    onChange(updatedSelected);

    if (!existingOption) {
      setOption((prevOptions: any) => [...prevOptions, selectedOption]);
    }
  };

  const handleDeselect = (value: string) => {
    const updatedSelected = valueSelected.filter((tag) => tag.name !== value);
    setValueSelected(updatedSelected);
    onChange(updatedSelected);
  };

  useEffect(() => {
    setValueSelected(value);
    setOption([]);
  }, [value, typeId]);

  return (
    <div>
      <p>{description}</p>
      <InputDefault
        type="select"
        mode="tags"
        className="select-add-tag"
        title={titleSelect}
        placeholder={placeholder}
        onSearch={handleSearch}
        onSelect={handleSelect}
        onDeselect={handleDeselect}
        option={option}
        value={_.map(valueSelected, "name")}
        filterOption={undefined}
        onInputKeyDown={(e) => {
          if (
            e.key === "Enter" &&
            !option.some((opt) => opt.value === searchValue)
          ) {
            handleSelect(searchValue);
          }
        }}
      />
      <div className="count-tag">Tags: {valueSelected.length}/5</div>
    </div>
  );
};

export default AddTagModal;
