import { AxiosResponse } from "axios";
import { SendEmailToCompanyRequest } from "../interfaces";
import axios from "./axiosConfig";

export const handleSendEmailToCompany = async (
  requestBody: SendEmailToCompanyRequest
): Promise<boolean | void> => {
  try {
    const response: AxiosResponse<boolean> = await axios.post(
      `users/me/send-email`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
