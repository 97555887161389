import { Application } from "../interfaces/application";
import axios from "./axiosConfig";

export const fetchApplicationDetail = async (
  applicationId: number
): Promise<Application | void> => {
  try {
    const response = await axios.get<Application>(
      `applications/${applicationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
