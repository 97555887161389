import { PlusOutlined } from "@ant-design/icons";
import { Tabs, Upload, UploadFile, type TabsProps } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import { Plus, XCircle } from "phosphor-react";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import {
  AssociationLogo,
  CareerLogo,
  CertificateLogo,
  EducationLogo,
  EmptyBackground,
  EmptyStudentAvatar,
  VolunteerLogo,
} from "../../../../assets/svg";
import ButtonComponent from "../../../../components/button/button";
import InputDefault from "../../../../components/input/inputDefault/inputDefault";
import { showNotification } from "../../../../components/notification/notification";
import { FORMAT_DATE, MAX_FILE_SIZE_IMAGE } from "../../../../constants";
import { LOADING_TYPES } from "../../../../constants/loadingTypes";
import { StudentStatus } from "../../../../constants/selectOptions";
import {
  Association,
  Career,
  Certificate,
  Education,
  VolunteerWork,
} from "../../../../interfaces/profile";
import { fetchListLocation } from "../../../../services/fetchListLocation";
import { fetchListTag } from "../../../../services/fetchListTag";
import { handleDownloadImage } from "../../../../services/handleDownloadImage";
import { handleUploadImage } from "../../../../services/handleUploadImage";
import loadingPage from "../../../../store/actions/loading";
import useActions from "../../../../utils/customHook/useActions";
import useMergeState from "../../../../utils/customHook/useMergeState";
import "./editProfileModal.s.scss";
import { EditRef } from "./studentProfile.d";

interface EditProfileModalProps {
  defaultData: any;
  skillToolTagsTemp: any[];
  languageTagsTemp: any[];
  professionalTagsTemp: any[];
  educations: any[];
  careers: any[];
  certificates: any[];
  associations: any[];
  volunteerWorks: any[];
  errors: any;
  timesOpen: number;
  onEdit: (type: string, item: any) => void;
  onDelete: (type: string, id: number) => void;
  onAdd: (type: string) => void;
}

const EditProfileModal = forwardRef<EditRef, EditProfileModalProps>(
  (
    {
      defaultData,
      skillToolTagsTemp,
      languageTagsTemp,
      professionalTagsTemp,
      educations,
      careers,
      certificates,
      associations,
      volunteerWorks,
      errors,
      timesOpen,
      onEdit,
      onDelete,
      onAdd,
    },
    ref
  ) => {
    const loadingPageAction = useActions(loadingPage);

    const nameFieldRef = useRef<HTMLDivElement>(null);
    const emailFieldRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useMergeState({
      activeKey: "1",
      profile: {},
      isOpenUpdateJobModal: false,
      isClosing: false,
      clickedId: null,
      isOpenSuccessModal: false,
      skillToolOptions: [],
      languageOptions: [],
      professionalOptions: [],
      skillToolTags: [],
      languageTags: [],
      professionalTags: [],
      errors: {},
      educations: [],
      careers: [],
      certificates: [],
      associations: [],
      volunteerWorks: [],
      listCity: [],
    });

    useImperativeHandle(ref, () => ({
      getActiveKey: () => state.activeKey,
      getTagsData: () => ({
        skillToolTags: state.skillToolTags,
        languageTags: state.languageTags,
        professionalTags: state.professionalTags,
      }),
      getProfileData: () => state.profile,
      scrollToError,
    }));

    const { profile } = state;

    // const handleOpenModal = (keyModal: string, isOpen: boolean) => {
    //   setState({ [keyModal]: isOpen });
    // };

    const scrollToError = useCallback(() => {
      if (!_.isEmpty(state.errors)) {
        // Get first error field
        const firstError = Object.keys(state.errors)[0];

        // Map field names to refs
        const fieldRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {
          name: nameFieldRef,
          email: emailFieldRef,
        };

        // Get corresponding ref
        const errorRef = fieldRefs[firstError];

        if (errorRef?.current) {
          // Set active tab to profile tab first
          setState({ activeKey: "1" });

          // Scroll to error field with offset for header
          setTimeout(() => {
            errorRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 100); // Small delay to ensure tab switch is complete
        }
      }
    }, [state.errors]);

    const handleUploadChange = async (
      info: { file: UploadFile },
      keyUuid: string,
      keyUpdate: string
    ) => {
      const { status, originFileObj } = info.file;

      if (status === "uploading") {
        return;
      }

      if (originFileObj) {
        const isJpgOrPng =
          originFileObj.type === "image/jpeg" ||
          originFileObj.type === "image/png";
        const isWithinSize = originFileObj.size < MAX_FILE_SIZE_IMAGE;

        if (!isJpgOrPng) {
          showNotification({
            type: "error",
            message: "Invalid File Type",
            description: "You can only upload JPG or PNG files!",
          });
          return;
        }

        if (!isWithinSize) {
          showNotification({
            type: "error",
            message: "File Size Exceeded",
            description: "Image must be smaller than 2MB!",
          });
          return;
        }

        try {
          loadingPageAction(LOADING_TYPES.UPLOADING);
          const result = await handleUploadImage(originFileObj);

          if (result) {
            const url = await handleDownloadImage(result);
            setState({
              profile: {
                ...state.profile,
                [keyUuid]: result,
                [keyUpdate]: url,
              },
            });
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          loadingPageAction();
        }
      }
    };

    const handleSearch = useCallback(
      _.debounce(async (text: string, typeId: number, optionKey: string) => {
        try {
          // Sửa lại typeId theo đúng category
          const filter = { tag: text, entityTypeId: 8, categoryId: typeId };
          const data = await fetchListTag(0, 10, filter);
          const options =
            data?.content?.map((tag: any) => ({
              id: tag.tagId,
              name: tag.tagName,
              value: tag.tagName,
              label: tag.tagName,
            })) || [];
          setState({ [optionKey]: options });
        } catch (error) {
          console.error("Error:", error);
          setState({ [optionKey]: [] });
        }
      }, 500),
      []
    );

    const handleSelect = (
      value: string,
      currentList: any[],
      setListKey: string,
      option: any[]
    ) => {
      // Kiểm tra value không được rỗng
      if (!value?.trim()) {
        return;
      }

      // Kiểm tra định dạng tag (bắt đầu bằng #)
      // if (!value.startsWith("#")) {
      //   value = `#${value}`;
      // }

      const cleanValue = value.startsWith("#") ? value.slice(1) : value;

      // Lấy categoryId dựa vào setListKey
      const categoryId = {
        skillToolTags: 5,
        languageTags: 3,
        professionalTags: 6,
      }[setListKey];

      const existingOption = _.find(option, (opt) => opt.value === cleanValue);
      const selectedOption = existingOption
        ? {
            id: existingOption.id,
            name: existingOption.name,
            value: existingOption.value,
            label: existingOption.label,
            categoryId: categoryId,
          }
        : {
            id: null,
            name: value,
            value,
            label: value,
            categoryId: categoryId,
          };

      if (!_.some(currentList, (opt) => opt.value === value)) {
        const updatedList = [...currentList, selectedOption];
        setState({ [setListKey]: updatedList });
      }
    };

    const handleDeselect = (
      value: string,
      currentList: any[],
      setListKey: string
    ) => {
      const updatedList = currentList.filter((tag) => tag.name !== value);
      setState({ [setListKey]: updatedList });
    };

    const onInputKeyDown = (
      e: React.KeyboardEvent<HTMLInputElement>,
      searchValue: string,
      setListKey: string,
      currentList: any[],
      option: any[]
    ): void => {
      if (
        e.key === "Enter" &&
        searchValue?.trim() !== "" &&
        !_.some(option, (opt) => opt.value === searchValue)
      ) {
        handleSelect(searchValue, currentList, setListKey, option);
      }
    };

    const onChangeInput = (key: string, value: any) => {
      if (key === "name" || key === "email") {
        if (value) {
          setState({
            profile: { ...state.profile, [key]: value },
            errors: { ...state.errors, [key]: "" },
          });
        } else {
          setState({
            profile: { ...state.profile, [key]: "" },
            errors: { ...state.errors, [key]: "" },
          });
        }
      } else {
        setState({ profile: { ...state.profile, [key]: value } });
      }
    };

    const handleMultipleInputChange = (index: number, value: string) => {
      const websitesCloned = _.cloneDeep(state.profile?.websites);
      websitesCloned[index] = value;
      setState({ profile: { ...state.profile, websites: websitesCloned } });
    };

    const handleAddMore = () => {
      const { profile } = state;
      if (profile?.websites?.length < 3) {
        setState({
          profile: { ...profile, websites: [...profile.websites, ""] },
        });
      }
    };

    const handleDestroy = (index: number) => {
      const { profile } = state;
      const websites = _.filter(
        profile.websites,
        (_, i: number) => i !== index
      );
      setState({ profile: { ...profile, websites } });
    };

    const handleCitySearch = useCallback(async (text: string) => {
      try {
        const locations = await fetchListLocation(text, 0, 6);
        if (!_.isEmpty(locations)) {
          const listCity = _.map(locations, (item) => ({
            id: item.id,
            value: item.value,
            label: item.label,
          }));
          setState({ listCity });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }, []);

    const handleCityChange = (value: string, option: any) => {
      setState({
        profile: { ...state.profile, city: { cityId: option.id?.[0], value } },
      });
    };

    const items: TabsProps["items"] = [
      {
        key: "1",
        label: "My profile",
        children: (
          <div className="edit-student-profile-content">
            {/* <div className="content-item">
              <div className="title">Sync your Linkedin</div>
              <ButtonComponent
                title="Continue with Linkedin"
                icon={<img src={LinkedinIcon} />}
                className="btn-continue-linkedin"
              />
              <div className="title-sub">
                Sync with Linkedin to automatically autofill your information.
              </div>
            </div> */}
            <div className="content-item">
              <div className="title">Profile photo</div>
              <Upload
                name="profile"
                className="photo-upload"
                accept=".jpg,.png"
                onChange={(info) =>
                  handleUploadChange(info, "avatarUuid", "avatarUrl")
                }
                showUploadList={false}
              >
                <img
                  src={profile?.avatarUrl || EmptyStudentAvatar}
                  alt="avatar"
                  width={80}
                  height={80}
                />
                <span>Upload photo</span>
              </Upload>
              <div className="title-sub">JPG or PNG. Max size of 2MB</div>
            </div>
            <div className="content-item">
              <div className="title">Background photo</div>
              <Upload
                name="profile"
                className="photo-upload"
                accept=".jpg,.png"
                onChange={(info) =>
                  handleUploadChange(info, "backgroundUuid", "backgroundUrl")
                }
                showUploadList={false}
              >
                <img
                  src={profile?.backgroundUrl || EmptyBackground}
                  alt="background"
                  width={354}
                  height={80}
                />
                <span>Upload photo</span>
              </Upload>
              <div className="title-sub">JPG or PNG. Max size of 2MB</div>
            </div>
            <div ref={nameFieldRef}>
              <InputDefault
                type="input"
                placeholder="Enter your name"
                title="Name"
                value={profile?.name}
                onChange={(e) => onChangeInput("name", e.target.value)}
                errorMsg={state.errors?.name}
              />
            </div>

            <InputDefault
              type="input"
              title="One line profile"
              maxLength={100}
              showCount
              placeholder="Enter a brief description or tagline..."
              value={profile?.oneLineProfile}
              onChange={(e) => onChangeInput("oneLineProfile", e.target.value)}
            />
            <InputDefault
              value={profile?.introduction}
              title="About"
              minRows={3}
              maxRows={5}
              maxLength={500}
              showCount
              placeholder="Enter a description..."
              type="text-area"
              onChange={(e) => onChangeInput("introduction", e.target.value)}
            />
            <InputDefault
              title="Status"
              type="select"
              value={profile?.statusId}
              option={_.map(StudentStatus, (item) => ({
                value: item.id,
                label: item.name,
              }))}
              placeholder="Select your status"
              onChangeSelect={(value) => onChangeInput("statusId", value)}
            />
            <div ref={emailFieldRef}>
              <InputDefault
                type="input"
                title="Email address"
                value={profile?.email}
                onChange={(e) => onChangeInput("email", e.target.value)}
                disabled
              />
            </div>

            <div>
              <div className="multiple-input">
                {_.map(profile?.websites, (item, index: number) => (
                  <div className="multiple-input-row">
                    <InputDefault
                      value={item}
                      type="input"
                      title={index === 0 ? "Website" : undefined}
                      addonBefore="https://"
                      placeholder="Enter your website URL (e.g., www.facebook.com/nnn)"
                      onChange={(e) =>
                        handleMultipleInputChange(index, e.target.value)
                      }
                    />
                    {index !== 0 && (
                      <span className="detroy-icon">
                        <XCircle
                          size={24}
                          color="#8F8F8F"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDestroy(index)}
                        />
                      </span>
                    )}
                  </div>
                ))}
              </div>
              {profile?.websites?.length < 3 && (
                <ButtonComponent
                  className="add-btn"
                  title="Add more"
                  type="link"
                  onClick={handleAddMore}
                  icon={<Plus size={24} />}
                  iconPosition="start"
                />
              )}
            </div>
            <InputDefault
              title="Address (city name)"
              type="auto-complete"
              value={profile?.city}
              option={state.listCity}
              onSearch={(text) => handleCitySearch(text)}
              placeholder="Enter your address"
              onChange={handleCityChange}
            />
            {/* <div className="noticed">
              *This info will be visible on your profile
            </div> */}
          </div>
        ),
      },
      {
        key: "2",
        label: "Customize tags",
        children: (
          <div className="edit-student-profile-content">
            <div className="input-tag">
              <InputDefault
                type="select"
                mode="tags"
                className="select-add-tag"
                title="Skills & tools"
                placeholder="Type a skills & Tools and press Enter to create a tag."
                filterOption={undefined}
                onSearch={(text) => {
                  if (state.skillToolTags.length >= 5) return;
                  handleSearch(text, 5, "skillToolOptions");
                }}
                onSelect={(value) => {
                  if (state.skillToolTags.length >= 5) return;
                  handleSelect(
                    value,
                    state.skillToolTags,
                    "skillToolTags",
                    state.skillToolOptions
                  );
                }}
                onDeselect={(value) =>
                  handleDeselect(value, state.skillToolTags, "skillToolTags")
                }
                option={state.skillToolOptions}
                value={_.map(state.skillToolTags, "name")}
                onInputKeyDown={(e) =>
                  onInputKeyDown(
                    e,
                    state.searchValue,
                    "skillToolTags",
                    state.skillToolTags,
                    state.skillToolOptions
                  )
                }
              />
              <div className="count-tag">
                Tags: {state.skillToolTags.length}/5
              </div>
            </div>
            <div className="input-tag">
              <InputDefault
                type="select"
                mode="tags"
                className="select-add-tag"
                title="Languages"
                placeholder="Type a language and press Enter to create a tag"
                filterOption={undefined}
                onSearch={(text) => {
                  if (state.languageOptions.length >= 5) return;
                  handleSearch(text, 3, "languageOptions");
                }}
                onSelect={(value) => {
                  if (state.languageOptions.length >= 5) return;
                  handleSelect(
                    value,
                    state.languageTags,
                    "languageTags",
                    state.languageOptions
                  );
                }}
                onDeselect={(value) =>
                  handleDeselect(value, state.languageTags, "languageTags")
                }
                option={state.languageOptions}
                value={_.map(state.languageTags, "name")}
                onInputKeyDown={(e) =>
                  onInputKeyDown(
                    e,
                    state.searchValue,
                    "languageTags",
                    state.languageTags,
                    state.languageOptions
                  )
                }
              />
              <div className="count-tag">
                Tags: {state.languageTags.length}/5
              </div>
            </div>
            <div className="input-tag">
              <InputDefault
                type="select"
                mode="tags"
                className="select-add-tag"
                title="Professional areas"
                placeholder="Type a Professional Areas and press Enter to create a tag"
                filterOption={undefined}
                onSearch={(text) => {
                  if (state.professionalTags.length >= 5) return;
                  handleSearch(text, 6, "professionalOptions");
                }}
                onSelect={(value) => {
                  if (state.professionalTags.length >= 5) return;
                  handleSelect(
                    value,
                    state.professionalTags,
                    "professionalTags",
                    state.professionalOptions
                  );
                }}
                onDeselect={(value) =>
                  handleDeselect(
                    value,
                    state.professionalTags,
                    "professionalTags"
                  )
                }
                option={state.professionalOptions}
                value={_.map(state.professionalTags, "name")}
                onInputKeyDown={(e) =>
                  onInputKeyDown(
                    e,
                    state.searchValue,
                    "professionalTags",
                    state.professionalTags,
                    state.professionalOptions
                  )
                }
              />
              <div className="count-tag">
                Tags: {state.professionalTags.length}/5
              </div>
            </div>
          </div>
        ),
      },
      {
        key: "3",
        label: "Education",
        children: (
          <div className="edit-student-profile-content others-info">
            <div className="student-content">
              {state.educations.length > 0 ? (
                _.map(state.educations, (item: Education) => (
                  <div className="student-content-item">
                    <div className="content-item-logo">
                      <img
                        src={EducationLogo}
                        width={56}
                        height={56}
                        alt="logo"
                      />
                    </div>
                    <div className="content-item-text">
                      <div>
                        <h4>{item.schoolName}</h4>
                        <div className="content-item-text-location">
                          {item.degree}
                        </div>
                        <div className="content-item-text-duration">
                          <span>
                            {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                              FORMAT_DATE.MMM_YYYY
                            )}
                          </span>
                          <span> - </span>
                          <span>
                            {item?.endDate
                              ? dayjs(item.endDate, FORMAT_DATE.MM_YYYY).format(
                                  FORMAT_DATE.MMM_YYYY
                                )
                              : "Present"}
                          </span>
                        </div>
                      </div>
                      <div className="content-item-action">
                        <ButtonComponent
                          className="close-btn"
                          title="Edit"
                          onClick={() => onEdit("education", item)}
                        />
                        <ButtonComponent
                          className="delete-btn"
                          title="Delete"
                          onClick={() => onDelete("education", item.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-profile">
                  <div className="title">
                    Add your educational background to complete your profile.
                  </div>
                  <ButtonComponent
                    className="add-edit-profile-btn"
                    title="Add education"
                    icon={<PlusOutlined />}
                    onClick={() => onAdd("education")}
                  />
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        key: "4",
        label: "Career History",
        children: (
          <div className="edit-student-profile-content others-info">
            <div className="student-content">
              {state.careers.length > 0 ? (
                _.map(state.careers, (item: Career) => (
                  <div className="student-content-item">
                    <div className="content-item-logo">
                      <img src={CareerLogo} width={56} height={56} alt="logo" />
                    </div>
                    <div className="content-item-text">
                      <div>
                        <h4>{item.position}</h4>
                        <div className="content-item-text-location">
                          <span>{item.companyName}</span>
                          <span>{item.employmentType}</span>
                        </div>
                        <div className="content-item-text-duration">
                          <span>
                            {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                              FORMAT_DATE.MMM_YYYY
                            )}
                          </span>
                          <span> - </span>
                          <span>
                            {item?.endDate
                              ? dayjs(item.endDate, FORMAT_DATE.MM_YYYY).format(
                                  FORMAT_DATE.MMM_YYYY
                                )
                              : "Present"}
                          </span>
                        </div>
                      </div>
                      <div className="content-item-action">
                        <ButtonComponent
                          className="close-btn"
                          title="Edit"
                          onClick={() => onEdit("career-history", item)}
                        />
                        <ButtonComponent
                          className="delete-btn"
                          title="Delete"
                          onClick={() => onDelete("career-history", item.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-profile">
                  <div className="title">
                    Add your career history to complete your profile.
                  </div>
                  <ButtonComponent
                    className="add-edit-profile-btn"
                    title="Add career history"
                    icon={<PlusOutlined />}
                    onClick={() => onAdd("career-history")}
                  />
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        key: "5",
        label: "Certificate",
        children: (
          <div className="edit-student-profile-content others-info">
            <div className="student-content">
              {state.certificates.length > 0 ? (
                _.map(state.certificates, (item: Certificate) => (
                  <div className="student-content-item">
                    <div className="content-item-logo">
                      <img
                        src={CertificateLogo}
                        width={56}
                        height={56}
                        alt="logo"
                      />
                    </div>
                    <div className="content-item-text">
                      <div>
                        <h4>{item.name}</h4>
                        <div className="content-item-text-location">
                          {item.issuingOrganization}
                        </div>
                        <div className="content-item-text-duration">
                          <span>
                            Published:{" "}
                            {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                              FORMAT_DATE.MMM_YYYY
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="content-item-action">
                        <ButtonComponent
                          className="close-btn"
                          title="Edit"
                          onClick={() => onEdit("certificate", item)}
                        />
                        <ButtonComponent
                          className="delete-btn"
                          title="Delete"
                          onClick={() => onDelete("certificate", item.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-profile">
                  <div className="title">
                    Add your certificate to complete your profile.
                  </div>
                  <ButtonComponent
                    className="add-edit-profile-btn"
                    title="Add certificate"
                    icon={<PlusOutlined />}
                    onClick={() => onAdd("certificate")}
                  />
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        key: "6",
        label: "Club & Organization",
        children: (
          <div className="edit-student-profile-content others-info">
            <div className="student-content">
              {state.associations.length > 0 ? (
                _.map(state.associations, (item: Association) => (
                  <div className="student-content-item">
                    <div className="content-item-logo">
                      <img
                        src={AssociationLogo}
                        width={56}
                        height={56}
                        alt="logo"
                      />
                    </div>
                    <div className="content-item-text">
                      <div>
                        <h4>{item.clubName}</h4>
                        <div className="content-item-text-location">
                          {item.role}
                        </div>
                        <div className="content-item-text-duration">
                          <span>
                            {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                              FORMAT_DATE.MMM_YYYY
                            )}
                          </span>
                          <span> - </span>
                          <span>
                            {item?.endDate
                              ? dayjs(item.endDate, FORMAT_DATE.MM_YYYY).format(
                                  FORMAT_DATE.MMM_YYYY
                                )
                              : "Present"}
                          </span>
                        </div>
                      </div>
                      <div className="content-item-action">
                        <ButtonComponent
                          className="close-btn"
                          title="Edit"
                          onClick={() => onEdit("club-and-organization", item)}
                        />
                        <ButtonComponent
                          className="delete-btn"
                          title="Delete"
                          onClick={() =>
                            onDelete("club-and-organization", item.id)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-profile">
                  <div className="title">
                    Add your club & organization to complete your profile.
                  </div>
                  <ButtonComponent
                    className="add-edit-profile-btn"
                    title="Add club & organization"
                    icon={<PlusOutlined />}
                    onClick={() => onAdd("club-and-organization")}
                  />
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        key: "7",
        label: "Volunteer work",
        children: (
          <div className="edit-student-profile-content others-info">
            <div className="student-content">
              {state.volunteerWorks.length > 0 ? (
                _.map(state.volunteerWorks, (item: VolunteerWork) => (
                  <div className="student-content-item">
                    <div className="content-item-logo">
                      <img
                        src={VolunteerLogo}
                        width={56}
                        height={56}
                        alt="logo"
                      />
                    </div>
                    <div className="content-item-text">
                      <div>
                        <h4>{item.organizationName}</h4>
                        <div className="content-item-text-location">
                          {item.role}
                        </div>
                        <div className="content-item-text-duration">
                          <span>
                            {dayjs(item.startDate, FORMAT_DATE.MM_YYYY).format(
                              FORMAT_DATE.MMM_YYYY
                            )}
                          </span>
                          <span> - </span>
                          <span>
                            {item?.endDate
                              ? dayjs(item.endDate, FORMAT_DATE.MM_YYYY).format(
                                  FORMAT_DATE.MMM_YYYY
                                )
                              : "Present"}{" "}
                            {item?.frequency && <>({item.frequency})</>}
                          </span>
                        </div>
                      </div>
                      <div className="content-item-action">
                        <ButtonComponent
                          className="close-btn"
                          title="Edit"
                          onClick={() => onEdit("volunteer-work", item)}
                        />
                        <ButtonComponent
                          className="delete-btn"
                          title="Delete"
                          onClick={() => onDelete("volunteer-work", item.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-profile">
                  <div className="title">
                    Add your volunteer work to complete your profile.
                  </div>
                  <ButtonComponent
                    className="add-edit-profile-btn"
                    title="Add volunteer work"
                    icon={<PlusOutlined />}
                    onClick={() => onAdd("volunteer-work")}
                  />
                </div>
              )}
            </div>
          </div>
        ),
      },
    ];

    useEffect(() => {
      setState({
        profile: {
          ...defaultData,
          city: defaultData?.location?.cityId
            ? _.compact([
                defaultData.location.city,
                defaultData.location.state,
              ]).join(", ")
            : "",
          websites:
            defaultData?.websites?.length === 0 ? [""] : defaultData.websites,
        },
        // Đảm bảo giữ nguyên cấu trúc data của tags bao gồm cả categoryId
        skillToolTags: skillToolTagsTemp.map((tag) => ({
          ...tag,
          categoryId: 5,
        })),
        languageTags: languageTagsTemp.map((tag) => ({
          ...tag,
          categoryId: 3,
        })),
        professionalTags: professionalTagsTemp.map((tag) => ({
          ...tag,
          categoryId: 6,
        })),
        educations,
        careers,
        certificates,
        associations,
        volunteerWorks,
        errors: {},
        // activeKey: "1",
      });
    }, [
      skillToolTagsTemp,
      languageTagsTemp,
      professionalTagsTemp,
      defaultData,
      educations,
      careers,
      certificates,
      associations,
      volunteerWorks,
      timesOpen,
    ]);

    useEffect(() => {
      setState({ errors });
    }, [errors]);

    return (
      <div>
        <Tabs
          className="edit-modal-tabs"
          items={items}
          activeKey={state.activeKey}
          onChange={(key) => setState({ activeKey: key })}
        />
      </div>
    );
  }
);

export default EditProfileModal;
