import { Association } from "../interfaces/profile";
import axios from "./axiosConfig";

export const fetchCandidateAssociations = async (): Promise<
  Association[] | void
> => {
  try {
    const response = await axios.get<Association[]>("associations");
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
