/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  EnvironmentOutlined,
  ExportOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, notification, Skeleton, Tooltip } from "antd";

import classNames from "classnames";
import _ from "lodash";
import {
  Briefcase,
  CaretLeft,
  Clock,
  FileX,
  Laptop,
  MapPin,
  UsersFour,
} from "phosphor-react";
import React, { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CalendarDotIcon, EmptyCompanyLogo } from "../../../../assets/svg";
import Badge from "../../../../components/badge/badge";
import ButtonComponent from "../../../../components/button/button";
import DrawerComponent from "../../../../components/drawer/drawer";
import EmptyComponent from "../../../../components/empty/empty";
import ModalComponent from "../../../../components/modal/modal";
import { PATH_ROUTER } from "../../../../constants";
import { LOADING_TYPES } from "../../../../constants/loadingTypes";
import {
  ApplicationTab,
  CompanySize,
} from "../../../../constants/selectOptions";
import { Action, ApplicationDetail } from "../../../../interfaces/application";
import { fetchApplicationDetail } from "../../../../services/fetchDetailApplication";
import { fetchListApplication } from "../../../../services/fetchListApplication";
import { handleActions } from "../../../../services/handleActions";
import { handleCancelApplication } from "../../../../services/handleCancelApplication";
import { handleViewNotification } from "../../../../services/handleViewNotification";
import updateGotoData from "../../../../store/actions/goto";
import loadingPage from "../../../../store/actions/loading";
import updateReloadPage from "../../../../store/actions/reloadPage";
import { calculateDaysDiff } from "../../../../utils";
import useActions from "../../../../utils/customHook/useActions";
import useMergeState from "../../../../utils/customHook/useMergeState";
import { formatDate } from "../../../../utils/formatter";
import { safeNavigate } from "../../../../utils/helper";
import {
  renderStatus,
  renderStatusDescription,
  renderStatusDetail,
  renderStatusTitle,
} from "../../dashboard.h";
import "./applicationCandidate.s.scss";

interface ApplicationProps {
  handleOpen: () => void;
}

interface Application extends ApplicationDetail {
  history: Action[];
}

const ApplicationCandidatePage: React.FC<ApplicationProps> = ({
  handleOpen,
}) => {
  const dispatch = useDispatch();
  const reloadPageRedux = useSelector(
    (state: any) => state.reloadPage.application
  );
  const homeGotoRedux = useSelector((state: any) => state.goto.home);
  const applicationGotoRedux = useSelector(
    (state: any) => state.goto.application
  );
  const meGotoRedux = useSelector((state: any) => state.goto.me);
  const loadingPageAction = useActions(loadingPage);

  const location = useLocation();

  const divRef = useRef<HTMLDivElement>(null);
  const applicationDetailRef = useRef<HTMLDivElement>(null);
  const pageCurrent = useRef(1);
  const totalElements = useRef(10);
  const filter = useRef<any>({
    statusId: applicationGotoRedux.statusId,
  });

  const [state, setState] = useMergeState({
    listApplication: [],
    markSave: false,
    indexActive: 0,
    applicationDetail: null,
    showBottomButton: false,
    hasShadowTop: false,
    hasShadowBottom: true,
    isLoadingList: false,
    isLoadingMore: false,
    isLoadingDetail: false,
    selectTab: applicationGotoRedux.statusId,
    isOpenCancelModal: false,
    openDrawerViewDetailJob: false,
  });

  const { applicationDetail }: { applicationDetail: Application } = state || {};

  const handleOpenModal = (key: string, isOpen: boolean) => {
    setState({ [key]: isOpen });
  };

  const getListApplication = async (isLoadMore: boolean = false) => {
    try {
      const page = pageCurrent.current;
      if (page * 10 <= totalElements.current) {
        const newPage = isLoadMore ? page + 1 : page;
        const data = await fetchListApplication(
          0,
          10 * newPage,
          filter.current
        );
        const newState = {};
        let history: Action[] = [];
        const updateApplicationGoto = { ...filter.current };
        if (data && !_.isEmpty(data.content)) {
          if (isLoadMore) {
            _.assign(newState, { listApplication: data.content });
            _.assign(updateApplicationGoto, {
              listApplication: data.content,
              page: newPage,
            });
          } else {
            const dataDetail = await fetchApplicationDetail(
              data.content[0].applicationId
            );
            if (!_.isEmpty(dataDetail) && dataDetail?.applicationId) {
              const dataHistory = await handleActions(dataDetail.applicationId);
              history = dataHistory;
            }
            _.assign(newState, {
              listApplication: data.content,
              applicationDetail: { ...dataDetail, history },
              markSave: dataDetail?.job.marked,
              indexActive: 0,
            });
            _.assign(updateApplicationGoto, {
              listApplication: data.content,
              applicationDetail: { ...dataDetail, history },
              count: data.totalElements,
              page: newPage,
            });
            totalElements.current = data.totalElements;
          }
        } else {
          _.assign(newState, {
            listApplication: [],
            applicationDetail: {},
          });
          _.assign(updateApplicationGoto, {
            listApplication: [],
            applicationDetail: {},
          });
        }
        _.assign(newState, {
          isLoadingList: false,
          isLoadingMore: false,
          isLoadingDetail: false,
        });
        dispatch(updateGotoData("application", updateApplicationGoto));
        pageCurrent.current = newPage;
        setState(newState);
      }
    } catch (error) {
      setState({
        listApplication: [],
        applicationDetail: {},
        isLoadingList: false,
        isLoadingMore: false,
        isLoadingDetail: false,
      });
    }
  };

  // const handleMarkSave = async (id: number) => {
  //   const { listApplication, applicationDetail } = state;
  //   const data = await handleSaveJob(id);
  //   if (listApplication[0].jobId === id) {
  //     dispatch(
  //       updateGotoData("application", {
  //         applicationDetail: { ...applicationDetail, marked: data.marked },
  //       })
  //     );
  //   }
  //   setState({ markSave: data.marked });
  // };

  const handleOnclick = () => {
    const { applicationDetail } = state;
    safeNavigate(
      PATH_ROUTER.APPLICATION_FORM_REVISE +
        "?application=" +
        applicationDetail.applicationId,
      {
        state: {
          ...location.state,
          applicationDetailReview: applicationDetail,
        },
      }
    );
  };

  const scrollToTop = () => {
    if (applicationDetailRef.current) {
      applicationDetailRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleActiveCard = async (
    index: string,
    applicationId: number,
    isViewed: boolean
  ) => {
    setState({ isLoadingDetail: true });
    scrollToTop();
    let history: Action[] = [];
    const dataDetail = await fetchApplicationDetail(applicationId);
    if (!_.isEmpty(dataDetail) && dataDetail?.applicationId) {
      const dataHistory = await handleActions(dataDetail.applicationId);
      history = dataHistory;
    }
    if (!isViewed) {
      const isSuccess = await handleViewNotification(applicationId);
      if (isSuccess) {
        const { listApplication } = state;
        //Update notification
        const { notifications } = meGotoRedux || {};
        const listNotificationCloned = _.map(notifications, (item) => {
          if (item.entityId === applicationId) {
            return { ...item, viewed: true };
          }
          return item;
        });
        dispatch(
          updateGotoData("me", { notifications: listNotificationCloned })
        );
        //Update application page
        const listApplicationCloned = _.map(listApplication, (item) => {
          if (item.applicationId === applicationId) {
            return { ...item, isViewed: true };
          }
          return item;
        });
        setState({
          listApplication: listApplicationCloned,
        });
        dispatch(
          updateGotoData("application", {
            listApplication: listApplicationCloned,
          })
        );
      }
    }
    setState({
      indexActive: index,
      applicationDetail: { ...dataDetail, history },
      isLoadingDetail: false,
    });
  };

  const handleClickViewCompany = () => {
    window.open(
      window.location.origin +
        PATH_ROUTER.COMPANY +
        "?id=" +
        applicationDetail.job.company.id,
      "_blank"
    );
  };

  const handleSelectTab = (selectTab: number) => {
    const isModified = !_.isEqual(state.selectTab, selectTab);
    if (isModified) {
      setState({ selectTab });
      const newFilter = { statusId: selectTab };
      filter.current = newFilter;
      pageCurrent.current = 1;
      totalElements.current = 10;
      setState({ isLoadingList: true, isLoadingDetail: true });
      getListApplication();
    }
  };

  const handleCancel = async (applicationId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.CANCELING);
      const isSucces = await handleCancelApplication(applicationId);
      if (isSucces) {
        const { applicationDetail, listApplication } = state;
        const { listJob, jobDetail } = homeGotoRedux;
        const updateHomeRedux = {};
        const updateApplicationRedux = {};
        const history = await handleActions(applicationId);
        //Update application page
        const listApplicationCloned = _.map(listApplication, (item) => {
          if (item.jobId === applicationDetail.job.id) {
            return { ...item, statusId: 6 };
          }
          return item;
        });
        const applicationDetailCloned = _.cloneDeep(applicationDetail);
        applicationDetailCloned.statusId = 6;
        setState({
          applicationDetail: { ...applicationDetailCloned, history },
          listApplication: listApplicationCloned,
        });
        _.assign(updateApplicationRedux, {
          listApplication: listApplicationCloned,
        });
        if (listApplication[0].applicationId === applicationId) {
          _.assign(updateApplicationRedux, {
            applicationDetail: { ...applicationDetailCloned, history },
          });
        }
        dispatch(updateGotoData("application", updateApplicationRedux));
        //Update home page
        const listJobCloned = _.map(listJob, (item) => {
          if (item.jobId === applicationDetail.job.id) {
            return { ...item, applicationStatusId: 6 };
          }
          return item;
        });
        _.assign(updateHomeRedux, { listJob: listJobCloned });
        if (jobDetail.application.applicationId === applicationId) {
          const jobDetailCloned = _.cloneDeep(jobDetail);
          jobDetailCloned.application.statusId = 6;
          _.assign(updateHomeRedux, {
            jobDetail: { ...jobDetailCloned, history },
          });
        }
        dispatch(updateGotoData("home", updateHomeRedux));
        handleOpenModal("isOpenCancelModal", false);
        notification.success({
          message: "Canceled successfully!",
          description: "The application was canceled successfully",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  useEffect(() => {
    if (reloadPageRedux || _.isEmpty(applicationGotoRedux.listApplication)) {
      setState({ isLoadingList: true, isLoadingDetail: true });
      getListApplication();
      dispatch(updateReloadPage("application", false));
    } else {
      setState({
        listApplication: applicationGotoRedux.listApplication,
        applicationDetail: applicationGotoRedux.applicationDetail,
        selectTab: applicationGotoRedux.statusId,
        // markSave: applicationGotoRedux.applicationDetail.job.marked,
      });
      totalElements.current = applicationGotoRedux.count;
      pageCurrent.current = applicationGotoRedux.page;
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const element = divRef.current;
      if (element) {
        if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
          getListApplication(true);
          setState({
            isLoadingMore: pageCurrent.current * 10 < totalElements.current,
          });
        }
        const isAtTop = element.scrollTop === 0;
        const isAtBottom =
          element.scrollHeight - element.scrollTop <= element.clientHeight;
        setState({
          hasShadowTop: !isAtTop && element.scrollTop > 0,
          hasShadowBottom:
            !isAtBottom && element.scrollHeight > element.clientHeight,
        });
      }
    };

    const element = divRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <>
      <ModalComponent
        className="modal-job-cancel"
        open={state.isOpenCancelModal}
        onCancel={() => handleOpenModal("isOpenCancelModal", false)}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="confirm-btn"
              title="Confirm"
              size="large"
              type="primary"
              onClick={() => handleCancel(applicationDetail?.applicationId)}
            />
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              size="large"
              type="default"
              onClick={() => handleOpenModal("isOpenCancelModal", false)}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Cancel this application</div>
          <div className="title-content">
            Are you sure you want to cancel? Once confirmed, your application
            will be withdrawn from the process, and you will no longer be able
            to apply for this position
          </div>
        </div>
      </ModalComponent>
      <DrawerComponent
        className="drawer-view-detail-job"
        title={
          <ButtonComponent
            className="back-btn"
            title="Back"
            icon={<CaretLeft size={24} />}
            onClick={() => setState({ openDrawerViewDetailJob: false })}
          />
        }
        placement="right"
        size="large"
        content={
          state.isLoadingDetail ? (
            <Skeleton active paragraph={{ rows: 23 }} />
          ) : (
            <div className="drawer-view-detail-job-body job-detail">
              <div className="job-detail-logo" onClick={handleClickViewCompany}>
                <img
                  src={
                    applicationDetail?.job?.company?.logoUrl || EmptyCompanyLogo
                  }
                  alt="company-logo"
                  width={32}
                  height={32}
                />
                <div>{applicationDetail?.job?.company?.name}</div>
              </div>
              <div className="job-detail-name">
                <div className="job-title">
                  <div className="title">
                    <span>{applicationDetail?.job?.title}</span>
                    {applicationDetail?.job?.negotiable && (
                      <>
                        <span className="title-sub">(Negotiable)</span>
                        <Tooltip
                          className="tooltip"
                          placement="bottom"
                          title="This indicates that the company is willing to negotiate and adjust the job duties, working hours, duration, and location through discussion"
                        >
                          <QuestionCircleOutlined
                            style={{ fontSize: 16, color: "#0A5CD8" }}
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                  <div className="company-address">
                    {_.compact([
                      applicationDetail?.job?.location?.city,
                      applicationDetail?.job?.location?.country,
                    ]).join(", ")}
                  </div>
                </div>
              </div>
              <div className="job-detail-action">
                <ButtonComponent
                  className="application-btn"
                  title="View your application"
                  onClick={handleOpen}
                />
                {(applicationDetail?.statusId === 1 ||
                  applicationDetail?.statusId === 2) && (
                  <Tooltip
                    className="tooltip"
                    title="Cancel your application"
                    placement="bottom"
                  >
                    <Button
                      className="cancel-btn"
                      icon={<FileX size={24} />}
                      onClick={handleOpen}
                    />
                  </Tooltip>
                )}
                {/* <ButtonComponent
                className="save-btn"
                icon={
                  state.markSave ? (
                    <BookmarkSimple
                      size={24}
                      weight="fill"
                      color="#FF7710"
                    />
                  ) : (
                    <BookmarkSimple size={24} />
                  )
                }
                onClick={() => handleMarkSave(applicationDetail.id)}
              /> */}
              </div>
              <div className="job-detail-keys">
                {_.map(
                  applicationDetail?.job?.tags,
                  (tag) =>
                    tag && <Badge title={_.replace(tag.name, /#/g, "")} />
                )}
              </div>
              <div className="job-detail-update">
                <div className="job-detail-title">The latest updated</div>
                <div className="job-detail-content">
                  <div className="application-status-card">
                    <div className="status-left">
                      <div className="circle">
                        <div className="inner-circle"></div>
                      </div>
                      <div className="dashed-line"></div>
                    </div>
                    <div className="status-right">
                      <div className="status-action">
                        {renderStatusDetail(applicationDetail?.statusId)}
                        <div className="status-action-date">
                          {calculateDaysDiff(
                            applicationDetail?.history?.[0]?.createdDate,
                            true
                          )}
                        </div>
                      </div>
                      <div className="status-title">
                        {renderStatusTitle(applicationDetail?.statusId)}
                      </div>
                      <div className="status-description">
                        {renderStatusDescription(applicationDetail?.statusId)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="job-detail-about">
                <div className="job-detail-title">About the job</div>
                <div className="job-detail-content">
                  {applicationDetail?.job?.description}
                </div>
                <div className="job-detail-duration">
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <Briefcase size={24} />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Job Type
                      </div>
                      <div className="apply-duration-detail-time">
                        {applicationDetail?.job?.jobType?.name}
                        {applicationDetail?.job?.jobType?.id === 1 && (
                          <Tooltip
                            className="tooltip"
                            title="The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties."
                            placement="bottom"
                          >
                            <QuestionCircleOutlined
                              style={{
                                fontSize: 16,
                                color: "#666666",
                                marginLeft: 5,
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <img src={CalendarDotIcon} alt="calendar-icon" />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Working period
                      </div>
                      <div className="apply-duration-detail-time">
                        {`${formatDate(
                          applicationDetail?.job?.workStart
                        )} - ${formatDate(applicationDetail?.job?.workEnd)}`}
                      </div>
                    </div>
                  </div>
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <Laptop size={24} />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Workplace Type
                      </div>
                      <div className="apply-duration-detail-time">
                        {applicationDetail?.job?.workplaceType?.name}
                      </div>
                    </div>
                  </div>
                  <div className="apply-duration">
                    <div className="apply-duration-icon">
                      <Clock size={24} />
                    </div>
                    <div className="apply-duration-detail">
                      <div className="apply-duration-detail-title">
                        Hours per week
                      </div>
                      <div className="apply-duration-detail-time">
                        {`${applicationDetail?.job?.hoursPerWeek} hours`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="job-detail-tasks">
                <div className="job-detail-title">Tasks</div>
                <div className="job-detail-content">
                  <ul>
                    {!_.isEmpty(applicationDetail?.job?.tasks) ? (
                      _.map(applicationDetail?.job?.tasks, (task) => (
                        <li>{_.replace(task.description, /^[-+]/, "")}</li>
                      ))
                    ) : (
                      <li>No description</li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="job-detail-qualify">
                <div className="job-detail-title">Minimum Qualifications</div>
                <div className="job-detail-content">
                  <ul>
                    {!_.isEmpty(applicationDetail?.job?.qualifications) ? (
                      _.map(
                        applicationDetail?.job?.qualifications,
                        (qualification) => (
                          <li>
                            {_.replace(qualification.description, /^[-+]/, "")}
                          </li>
                        )
                      )
                    ) : (
                      <li>No description</li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="job-detail-company">
                <div className="job-detail-company-intro">
                  <div
                    className="job-detail-company-intro-logo"
                    onClick={handleClickViewCompany}
                  >
                    <img
                      src={
                        applicationDetail?.job?.company?.logoUrl ||
                        EmptyCompanyLogo
                      }
                      alt="company-logo"
                      className="company-logo"
                      height={32}
                      width={32}
                    />
                    <div className="company-name">
                      {applicationDetail?.job?.company?.name}
                    </div>
                  </div>
                  <div className="job-detail-company-intro-info">
                    <div className="company-address">
                      <MapPin className="icon" size={18} />
                      <span>
                        {_.compact([
                          applicationDetail?.job?.location?.city,
                          applicationDetail?.job?.location?.country,
                        ]).join(", ")}
                      </span>
                    </div>
                    <div className="company-employee">
                      <UsersFour className="icon" size={18} />
                      {
                        _.find(
                          CompanySize,
                          (size) =>
                            size.id ===
                            applicationDetail?.job?.company?.companySizeId
                        )?.name
                      }
                    </div>
                  </div>
                  <ButtonComponent
                    title="View company"
                    icon={<ExportOutlined />}
                    className="view-btn"
                    iconPosition="end"
                    type="link"
                    onClick={handleClickViewCompany}
                  />
                </div>
                {applicationDetail?.job?.company?.companyOverview && (
                  <div className="job-detail-company-overview">
                    <div className="job-detail-title">Company overview</div>
                    <div className="job-detail-content">
                      {applicationDetail?.job?.company?.companyOverview}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        }
        open={state.openDrawerViewDetailJob}
        footer={null}
      />
      <div className="application-candidate-page">
        <div className="filter-tab">
          {_.map(ApplicationTab, (tab) => (
            <div
              className={classNames(
                "tab",
                state.selectTab === tab.value && "tab-active"
              )}
              onClick={() => handleSelectTab(tab.value)}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <div className="count-application">
          {state.isLoadingList ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <>
              <strong>
                {
                  _.filter(state.listApplication, (item) => !item.isViewed)
                    .length
                }
              </strong>{" "}
              application{" "}
              {_.filter(state.listApplication, (item) => !item.isViewed)
                .length < 2
                ? "status"
                : "statuses"}{" "}
              were updated.
            </>
          )}
        </div>
        <div className="application-jobs">
          <div
            ref={divRef}
            className={classNames(
              "application-list",
              !_.isEmpty(state.listApplication) &&
                state.listApplication.length > (isMobile ? 3 : 4)
                ? state.hasShadowTop && state.hasShadowBottom
                  ? "shadow-top-bottom"
                  : state.hasShadowTop
                  ? "shadow-top"
                  : "shadow-bottom"
                : ""
            )}
          >
            {state.isLoadingList ? (
              _.map(new Array(5), (_item, index) => (
                <div className="application-card" key={index}>
                  <Skeleton active title={false} paragraph={{ rows: 3 }} />
                </div>
              ))
            ) : _.isEmpty(state.listApplication) ? (
              <EmptyComponent className="empty-layout" />
            ) : (
              <>
                {_.map(state.listApplication, (item, index) => (
                  <div
                    className={classNames(
                      "application-card",
                      index === state.indexActive && "application-card-active"
                    )}
                    key={index}
                    onClick={() => {
                      handleActiveCard(
                        index,
                        item.applicationId,
                        item.isViewed
                      );
                      if (isMobile) {
                        setState({ openDrawerViewDetailJob: true });
                      }
                    }}
                  >
                    <div className="application-card-left">
                      {!item.isViewed && <div className="red-dot" />}
                    </div>
                    <div className="application-card-mid">
                      <div className="job-title">
                        <div className="title">
                          {item.jobTitle}
                          {item.negotiable && (
                            <span className="title-sub">(Negotiable)</span>
                          )}
                        </div>
                      </div>
                      <div className="company">
                        <img
                          src={item?.companyAvatarUrl || EmptyCompanyLogo}
                          alt="company-logo"
                          className="company-logo"
                          width={40}
                          height={40}
                        />
                        <div className="company-info">
                          <div className="company-info-name">
                            {item.companyName}
                          </div>
                          <div className="company-info-state">
                            {_.compact([item.cityName, item.countryName]).join(
                              ", "
                            )}
                          </div>
                        </div>
                      </div>
                      {renderStatus(item.statusId)}
                    </div>
                    <div className="application-card-right">
                      <div className="update-time">
                        {calculateDaysDiff(item.lastUpdateDate)}
                      </div>
                    </div>
                  </div>
                ))}
                {state.isLoadingMore && (
                  <div className="application-card">
                    <Skeleton active title={false} paragraph={{ rows: 3 }} />
                  </div>
                )}
              </>
            )}
          </div>
          <div ref={applicationDetailRef} className="application-detail">
            {state.isLoadingDetail ? (
              <Skeleton active paragraph={{ rows: 13 }} />
            ) : _.isEmpty(applicationDetail) ? (
              <EmptyComponent className="empty-layout" />
            ) : (
              <>
                <div className="application-detail-name">
                  <img
                    src={
                      applicationDetail.job?.company?.logoUrl ||
                      EmptyCompanyLogo
                    }
                    alt="company-logo"
                    className="company-logo active-profile"
                    width={84}
                    height={84}
                    onClick={handleClickViewCompany}
                  />
                  <div className="job-title">
                    <div className="title">
                      <span
                        className="active-profile"
                        onClick={handleClickViewCompany}
                      >
                        {applicationDetail.job.title}
                      </span>
                      {applicationDetail.job.negotiable && (
                        <>
                          <span className="title-sub">(Negotiable)</span>
                          <Tooltip
                            className="tooltip"
                            placement="bottom"
                            title="This indicates that the company is willing to negotiate and adjust the job duties, working hours, duration, and location through discussion"
                          >
                            <QuestionCircleOutlined
                              style={{ fontSize: 16, color: "#0A5CD8" }}
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                    <div className="company-info">
                      <div className="company-info-name">
                        {applicationDetail.job.company.name}
                      </div>
                      <div className="company-info-state">
                        <EnvironmentOutlined className="icon" />
                        {_.compact([
                          applicationDetail.job.location.city,
                          applicationDetail.job.location.country,
                        ]).join(", ")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="application-detail-action">
                  <ButtonComponent
                    className="application-btn"
                    title="View your application"
                    onClick={handleOnclick}
                  />
                  {(applicationDetail.statusId === 1 ||
                    applicationDetail.statusId === 2) && (
                    <Tooltip
                      className="tooltip"
                      title="Cancel your application"
                      placement="bottom"
                    >
                      <Button
                        className="cancel-btn"
                        icon={<FileX size={24} />}
                        onClick={() =>
                          handleOpenModal("isOpenCancelModal", true)
                        }
                      />
                    </Tooltip>
                  )}
                  {/* <ButtonComponent
                    className="save-btn"
                    icon={
                      state.markSave ? (
                        <BookmarkSimple
                          size={24}
                          weight="fill"
                          color="#FF7710"
                        />
                      ) : (
                        <BookmarkSimple size={24} />
                      )
                    }
                    onClick={() => handleMarkSave(applicationDetail.job.id)}
                  /> */}
                </div>
                <div className="application-detail-keys">
                  {_.map(
                    applicationDetail.job.tags,
                    (keyword) =>
                      keyword && (
                        <Badge title={_.replace(keyword.name, /#/g, "")} />
                      )
                  )}
                </div>
                <div className="application-detail-update">
                  <div className="application-detail-title">
                    The latest updated
                  </div>
                  <div className="application-detail-content">
                    <div className="application-status-card">
                      <div className="status-left">
                        <div className="circle">
                          <div className="inner-circle"></div>
                        </div>
                        <div className="dashed-line"></div>
                      </div>
                      <div className="status-right">
                        <div className="status-action">
                          {renderStatusDetail(applicationDetail.statusId)}
                          <div className="status-action-date">
                            {calculateDaysDiff(
                              applicationDetail.history[0].createdDate,
                              true
                            )}
                          </div>
                        </div>
                        <div className="status-title">
                          {renderStatusTitle(applicationDetail.statusId)}
                        </div>
                        <div className="status-description">
                          {renderStatusDescription(applicationDetail.statusId)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="application-detail-about">
                  <div className="application-detail-title">About the job</div>
                  <div className="application-detail-content">
                    {applicationDetail.job.description}
                  </div>
                  <div className="application-detail-duration">
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <Briefcase size={24} />
                      </div>
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Job Type
                        </div>
                        <div className="apply-duration-detail-time">
                          {applicationDetail?.job?.jobType?.name}
                          {applicationDetail?.job?.jobType?.id === 1 && (
                            <Tooltip
                              className="tooltip"
                              title="The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties."
                              placement="right"
                            >
                              <QuestionCircleOutlined
                                style={{
                                  fontSize: 16,
                                  color: "#666666",
                                  marginLeft: 5,
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <img src={CalendarDotIcon} alt="calendar-icon" />
                      </div>
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Working period
                        </div>
                        <div className="apply-duration-detail-time">
                          {`${formatDate(
                            applicationDetail.job.workStart
                          )} - ${formatDate(applicationDetail.job.workEnd)}`}
                        </div>
                      </div>
                    </div>
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <Laptop size={24} />
                      </div>
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Workplace Type
                        </div>
                        <div className="apply-duration-detail-time">
                          {applicationDetail.job.workplaceType.name}
                        </div>
                      </div>
                    </div>
                    <div className="apply-duration">
                      <div className="apply-duration-icon">
                        <Clock size={24} />
                      </div>
                      <div className="apply-duration-detail">
                        <div className="apply-duration-detail-title">
                          Hours per week
                        </div>
                        <div className="apply-duration-detail-time">
                          {applicationDetail.job.hoursPerWeek &&
                            `${applicationDetail.job.hoursPerWeek} hours`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="application-detail-tasks">
                  <div className="application-detail-title">Tasks</div>
                  <div className="application-detail-content">
                    <ul>
                      {!_.isEmpty(applicationDetail.job.tasks) ? (
                        _.map(applicationDetail.job.tasks, (task) => (
                          <li>{_.replace(task.description, /^[-+]/, "")}</li>
                        ))
                      ) : (
                        <li>No description</li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="application-detail-qualify">
                  <div className="application-detail-title">
                    Minimum Qualifications
                  </div>
                  <div className="application-detail-content">
                    <ul>
                      {!_.isEmpty(applicationDetail.job.qualifications) ? (
                        _.map(
                          applicationDetail.job.qualifications,
                          (qualification) => (
                            <li>{qualification.description}</li>
                          )
                        )
                      ) : (
                        <li>No description</li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="application-detail-company">
                  <div className="application-detail-company-intro">
                    <div className="application-detail-company-intro-left">
                      <img
                        src={
                          applicationDetail.job.company?.logoUrl ||
                          EmptyCompanyLogo
                        }
                        alt="company-logo"
                        className="company-logo-intro active-profile"
                        height={64}
                        width={64}
                        onClick={handleClickViewCompany}
                      />
                      <div className="company-info">
                        <div
                          className="company-info-name active-profile"
                          onClick={handleClickViewCompany}
                        >
                          {applicationDetail.job.company.name}
                        </div>
                        <div className="company-info-detail">
                          <div className="company-info-detail-state">
                            <MapPin className="icon" size={18} />
                            <span>
                              {_.compact([
                                applicationDetail.job.location.city,
                                applicationDetail.job.location.country,
                              ]).join(", ")}
                            </span>
                          </div>
                          <div className="company-info-detail-employ">
                            <UsersFour className="icon" size={18} />
                            {
                              _.find(
                                CompanySize,
                                (size) =>
                                  size.id ===
                                  applicationDetail.job.company?.companySizeId
                              )?.name
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="application-detail-company-intro-right">
                      <ButtonComponent
                        title="View company"
                        icon={<ExportOutlined />}
                        className="view-btn"
                        iconPosition="end"
                        type="link"
                        onClick={handleClickViewCompany}
                      />
                    </div>
                  </div>
                  {applicationDetail.job.company.companyOverview && (
                    <div className="application-detail-company-overview">
                      <div className="application-detail-title">
                        Company overview
                      </div>
                      <div className="application-detail-content">
                        {applicationDetail.job.company.companyOverview}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationCandidatePage;
