import axios from "./axiosConfig";

export const handleViewNotification = async (applicationId: number) => {
  try {
    const response = await axios.post(
      `applications/${applicationId}/clear-alert`
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
