import { VolunteerWork } from "../interfaces/profile";
import axios from "./axiosConfig";

export const fetchCandidateVolunteerWorks = async (): Promise<
  VolunteerWork[] | void
> => {
  try {
    const response = await axios.get<VolunteerWork[]>("volunteer-works");
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
