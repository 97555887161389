import { APP_FLOW_ACTIONS } from "../../constants/appFlowAction";
import initialState from "./initialState";

const reloadPage = (state: any = initialState.loading, action: any) => {
  switch (action.type) {
    case APP_FLOW_ACTIONS.UPDATE_RELOAD_PAGE: {
      const { key, data } = action.payload || {};
      return {
        ...state,
        [key]: data,
      };
    }
    default: {
      return state;
    }
  }
};

export default reloadPage;
