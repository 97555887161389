import axios from "./axiosConfig";

export const handleDownloadImage = async (uuid: string) => {
  try {
    const response = await axios.get(`media-files/${uuid}`, {
      responseType: "blob",
      headers: {
        Accept: "image/png",
      },
    });
    const imageUrl = window.URL.createObjectURL(new Blob([response.data]));

    return imageUrl;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};
