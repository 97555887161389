/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  ExportOutlined,
  EyeOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { Dropdown, MenuProps, Tooltip } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import _ from "lodash";
import {
  ArrowLeft,
  CalendarPlus,
  CaretDown,
  CaretUp,
  EnvelopeSimple,
  GraduationCap,
  Phone,
} from "phosphor-react";
import { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  CheckIcon,
  Congratulation,
  EmptyStudentAvatar,
  SuccessIcon,
} from "../../assets/svg";
import ButtonComponent from "../../components/button/button";
import InputDefault from "../../components/input/inputDefault/inputDefault";
import InputPrefix from "../../components/input/inputPrefix/inputPrefix";
import ModalComponent from "../../components/modal/modal";
import { showNotification } from "../../components/notification/notification";
import {
  ACTION_TYPE_COMPANY_USER,
  FORMAT_DATE,
  PATH_ROUTER,
} from "../../constants";
import { getCountry, listCountry } from "../../constants/countries";
import { LOADING_TYPES } from "../../constants/loadingTypes";
import { JobTypeOptions, WorkTypeOptions } from "../../constants/selectOptions";
import { Application } from "../../interfaces/application";
import { fetchApplicationHistories } from "../../services/fetchApplicationHistories";
import { fetchTimezones } from "../../services/fetchTimezones";
import { handleAccept } from "../../services/handleAccept";
import { handleActions } from "../../services/handleActions";
import { handleCallPhone } from "../../services/handleCallPhone";
import { handleDownloadFile } from "../../services/handleDownloadFile";
import { handleReject } from "../../services/handleReject";
import { handleScheduleInterview } from "../../services/handleScheduleInterview";
import { handleSendEmail } from "../../services/handleSendEmail";
import updateGotoData from "../../store/actions/goto";
import loadingPage from "../../store/actions/loading";
import { formatPhoneNumberWithHyphens, getLabelByValue } from "../../utils";
import useActions from "../../utils/customHook/useActions";
import { useSetState } from "../../utils/customHook/useSetState";
import { formatDate } from "../../utils/formatter";
import { safeNavigate } from "../../utils/helper";
import { validateEmail, validatePhoneNumber } from "../../utils/validation";
import { renderStatus } from "../dashboard/dashboard.h";
import "../dashboard/dashboard.s.scss";
import "./applicationFormReview.s.scss";

const applicationFormReview = () => {
  const dispatch = useDispatch();
  const applicationGotoRedux = useSelector(
    (state: any) => state.goto.application
  );
  const loadingPageAction = useActions(loadingPage);
  const location = useLocation();
  const { applicationDetailReview } = location.state || {};
  const dataHistory = JSON.parse(
    sessionStorage.getItem("queryHistory") || "[]"
  );
  const email = decodeURIComponent(Cookies.get("user_email") || "");
  const countriesOption = listCountry();

  const country = getCountry("US");

  const [state, setState] = useSetState({
    timezonesOption: [],
    detailApplication: applicationDetailReview,
    isOpenRejectModal: false,
    isOpenSuccessRejectModal: false,
    isOpenPhoneModal: false,
    isOpenEmailModal: false,
    isOpenScheduleModal: false,
    isOpenSuccessModal: false,
    isOpenGuidelineModal: false,
    isOpenConfirmModal: false,
    isCalledPhone: false,
    isSentEmail: false,
    isSentScheduleInterview: false,
    isAccepted: false,
    selectedVersion: applicationDetailReview.histories.length,
    listHistory: applicationDetailReview.histories,
    statusId: applicationDetailReview.statusId,
    emailModal: {
      selectedCountry: {
        countryCode: country?.countryCode,
        phoneCode: country?.phoneCode,
        flag: country?.flag,
      },
      phoneValue: "",
      email,
      emailMsg:
        "We would like to schedule a phone call before the interview. Please let us know your availability.",
      errors: {},
    },
    scheduleModal: {
      selectedCountry: {
        countryCode: country?.countryCode,
        phoneCode: country?.phoneCode,
        flag: country?.flag,
      },
      position:
        applicationDetailReview.jobTitle.delta.candidate ||
        applicationDetailReview.jobTitle.delta.company,
      interviewDate: "",
      interviewTime: "",
      timezone: "",
      interviewLocation: "",
      phoneValue: "",
      email,
      emailMsg: "",
      errors: {},
    },
  });

  const { detailApplication }: { detailApplication: Application } = state || {};

  const fetchListTimezone = async () => {
    try {
      const result = await fetchTimezones();
      setState({ timezonesOption: result });
      return result;
    } catch (error) {
      console.error("Error:", error);
      setState({ timezonesOption: [] });
    }
  };

  const validatesEmailModal = () => {
    const { selectedCountry, phoneValue, email, emailMsg, errors } =
      state.emailModal;
    if (phoneValue) {
      if (!validatePhoneNumber(phoneValue, selectedCountry.countryCode)) {
        _.assign(errors, { phoneValue: "Phone number is invalid." });
      } else {
        _.unset(errors, "phoneValue");
      }
    } else {
      _.assign(errors, { phoneValue: "Field is required." });
    }
    if (email) {
      if (!validateEmail(email)) {
        _.assign(errors, { email: "Email is invalid." });
      } else {
        _.unset(errors, "email");
      }
    } else {
      _.assign(errors, { email: "Field is required." });
    }
    if (emailMsg) {
      _.unset(errors, "emailMsg");
    } else {
      _.assign(errors, { emailMsg: "Field is required." });
    }
    setState({ emailModal: { ...state.emailModal, errors } });
    return _.isEmpty(errors);
  };

  const validatesScheduleModal = () => {
    const {
      interviewDate,
      interviewTime,
      timezone,
      interviewLocation,
      selectedCountry,
      phoneValue,
      email,
      emailMsg,
    } = state.scheduleModal;

    const errors: Record<string, string> = {};
    if (_.isEmpty(interviewDate)) {
      errors.interviewDate = "Field is required.";
    }
    if (_.isEmpty(interviewTime)) {
      errors.interviewTime = "Field is required.";
    }
    if (!timezone) {
      errors.timezone = "Field is required.";
    }
    if (!interviewLocation?.trim()) {
      errors.interviewLocation = "Field is required.";
    }
    if (phoneValue) {
      if (!validatePhoneNumber(phoneValue, selectedCountry.countryCode)) {
        errors.phoneValue = "Phone number is invalid.";
      }
    } else {
      errors.phoneValue = "Field is required.";
    }
    if (email) {
      if (!validateEmail(email)) {
        errors.email = "Email is invalid.";
      }
    } else {
      errors.email = "Field is required.";
    }
    if (!emailMsg) {
      errors.emailMsg = "Field is required.";
    }
    setState({ scheduleModal: { ...state.scheduleModal, errors } });
    return _.isEmpty(errors);
  };

  const handleFetchVersionApplication = async (historyId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      const detailApplication = await fetchApplicationHistories(historyId);
      setState({ detailApplication });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleGetActions = async () => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      const actions = await handleActions(detailApplication.applicationId);
      _.forEach(actions, (item) => {
        switch (item.action) {
          case ACTION_TYPE_COMPANY_USER.CALL_PHONE:
            setState({ isCalledPhone: true });
            break;
          case ACTION_TYPE_COMPANY_USER.SEND_EMAIL_APPLICATION:
            setState({ isSentEmail: true });
            break;
          case ACTION_TYPE_COMPANY_USER.SCHEDULE_INTERVIEW:
            setState({ isSentScheduleInterview: true });
            break;
          case ACTION_TYPE_COMPANY_USER.ACCEPT_APPLICATION:
            setState({ isAccepted: true });
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleRejectApplication = async () => {
    try {
      loadingPageAction(LOADING_TYPES.REJECTING);
      const isSuccess = await handleReject(detailApplication.applicationId);
      if (isSuccess) {
        handleOpenModal("isOpenSuccessRejectModal", true);
        setState({
          detailApplication: { ...detailApplication, statusId: 4 },
        });
        const updateApplicationRedux = {};
        const { applicationDetail, listApplication } = applicationGotoRedux;
        const listApplicationCloned = _.map(listApplication, (item) => {
          if (item.applicationId === detailApplication.applicationId) {
            return { ...item, statusId: 4 };
          }
          return item;
        });
        _.assign(updateApplicationRedux, {
          listApplication: listApplicationCloned,
        });
        if (
          detailApplication.applicationId === applicationDetail.applicationId
        ) {
          _.assign(updateApplicationRedux, {
            applicationDetail: { ...applicationDetail, statusId: 4 },
          });
        }
        dispatch(updateGotoData("application", updateApplicationRedux));
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
      handleOpenModal("isOpenRejectModal", false);
    }
  };

  const handlePhoneCall = async () => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      const isSuccess = await handleCallPhone(detailApplication.applicationId);
      if (isSuccess) {
        setState({ isCalledPhone: true });
        handleOpenModal("isOpenPhoneModal", false);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleSendingEmail = async () => {
    try {
      loadingPageAction(LOADING_TYPES.SENDING);
      const validation = validatesEmailModal();
      if (validation) {
        const { emailModal, detailApplication } = state;
        const requestBody = {
          applicationId: detailApplication.applicationId,
          contactNumber: {
            phoneValue: emailModal.phoneValue,
            extension: emailModal.selectedCountry.phoneCode,
            countryCode: emailModal.selectedCountry.countryCode,
          },
          contactEmail: emailModal.email,
          message: emailModal.emailMsg,
        };
        const isSuccess = await handleSendEmail(
          detailApplication.applicationId,
          requestBody
        );
        if (isSuccess) {
          setState({ isSentEmail: true });
          handleOpenModal("isOpenEmailModal", false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleSendingScheduleInterview = async () => {
    try {
      loadingPageAction(LOADING_TYPES.SENDING);
      const validation = validatesScheduleModal();
      if (validation) {
        const { scheduleModal, detailApplication, timezonesOption } = state;
        const timezone = _.find(
          timezonesOption,
          (tz) => tz.value === scheduleModal.timezone
        );
        const requestBody = {
          applicationId: detailApplication.applicationId,
          position: scheduleModal.position,
          interviewDate: [
            scheduleModal.interviewDate.year(),
            scheduleModal.interviewDate.month() + 1,
            scheduleModal.interviewDate.date(),
          ],
          interviewTime: [
            scheduleModal.interviewTime.hour(),
            scheduleModal.interviewTime.minute(),
          ],
          location: scheduleModal.interviewLocation,
          timezoneId: timezone.id,
          contactNumber: {
            phoneValue: scheduleModal.phoneValue,
            extension: scheduleModal.selectedCountry.phoneCode,
            countryCode: scheduleModal.selectedCountry.countryCode,
          },
          contactEmail: scheduleModal.email,
          message: scheduleModal.emailMsg,
        };
        const isSuccess = await handleScheduleInterview(
          detailApplication.applicationId,
          requestBody
        );
        if (isSuccess) {
          setState({ isSentScheduleInterview: true });
          showNotification({
            type: "success",
            message: "Interview schedule successfully sent",
          });
          handleOpenModal("isOpenScheduleModal", false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleAccepting = async () => {
    try {
      loadingPageAction(LOADING_TYPES.SENDING);
      const isSuccess = await handleAccept(detailApplication.applicationId);
      if (isSuccess) {
        setState({
          isAccepted: true,
          detailApplication: { ...detailApplication, statusId: 3 },
        });
        const updateApplicationRedux = {};
        const { applicationDetail, listApplication } = applicationGotoRedux;
        const listApplicationCloned = _.map(listApplication, (item) => {
          if (item.applicationId === detailApplication.applicationId) {
            return { ...item, statusId: 3 };
          }
          return item;
        });
        _.assign(updateApplicationRedux, {
          listApplication: listApplicationCloned,
        });
        if (
          detailApplication.applicationId === applicationDetail.applicationId
        ) {
          _.assign(updateApplicationRedux, {
            applicationDetail: { ...applicationDetail, statusId: 3 },
          });
        }
        dispatch(updateGotoData("application", updateApplicationRedux));
      }
      handleOpenModal("isOpenSuccessModal", true);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleViewResume = async (resumeId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      await handleDownloadFile(resumeId);
    } finally {
      loadingPageAction();
    }
  };

  const onBackToHome = () => {
    const pathname = dataHistory[0].pathname;
    safeNavigate(pathname);
  };

  const handleOpenModal = (modalKey: string, isOpen: boolean) => {
    setState({ [modalKey]: isOpen });
  };

  const handleOpenRejectModal = (isOpenRejectModal: boolean) => {
    setState({ isOpenRejectModal });
  };

  const normalize = (str: string) => str?.toLowerCase().replace(/\s+/g, "");

  const handleSearch = (input: string, option: any) => {
    return normalize(option.value).includes(normalize(input));
  };

  const createVersionMenu = (datas: any[]): MenuProps["items"] => {
    const getDaySuffix = (day: number) => {
      if (day === 1 || day === 21 || day === 31) return "st";
      if (day === 2 || day === 22) return "nd";
      if (day === 3 || day === 23) return "rd";
      return "th";
    };
    return _.map(datas, (data, index) => {
      const day = dayjs(data.createdDate).date();
      const formattedDate = `${dayjs(data.createdDate).format(
        FORMAT_DATE.MMM_D
      )}${getDaySuffix(day)}`;

      return {
        key: datas.length - index,
        label: (
          <div
            className={classNames(
              "menu-version-item",
              state.selectedVersion === datas.length - index &&
                "menu-version-item-active"
            )}
          >
            <div className="item-date">{formattedDate}</div>
            <div className="item-version">Version {datas.length - index}</div>
          </div>
        ),
        onClick: async () => {
          await handleFetchVersionApplication(data.historyId);
          setState({
            selectedVersion: datas.length - index,
          });
        },
      };
    });
  };

  const versionMenu: MenuProps["items"] = createVersionMenu(state.listHistory);

  const handleBackCurrentVerion = async () => {
    await handleFetchVersionApplication(
      applicationDetailReview.histories[0].historyId
    );
    setState({
      selectedVersion: applicationDetailReview.histories.length,
    });
  };

  const buttonMenu: MenuProps["items"] = [
    {
      className: "menu-step-item",
      label: (
        <div className="step-item">
          <div className="step-item-left">
            <Phone size={20} /> Contact by phone
          </div>
          {state.isCalledPhone && (
            <div className="step-item-right">
              <Tooltip
                title="You’ve contacted this candidate by phone"
                placement="right"
              >
                <img src={CheckIcon} alt="check-icon" />
              </Tooltip>
            </div>
          )}
        </div>
      ),
      key: "0",
      onClick: () => {
        state.statusId === 3
          ? showNotification({
              type: "warning",
              message: "Action Unavailable",
              description:
                "This action is unavailable as the application has already been accepted.",
            })
          : handleOpenModal("isOpenPhoneModal", true);
      },
    },
    {
      className: "menu-step-item",
      label: (
        <div className="step-item">
          <div className="step-item-left">
            <EnvelopeSimple size={20} /> Send an email
          </div>
          {state.isSentEmail && (
            <div className="step-item-right">
              <Tooltip
                title="You’ve sent an email to the candidate."
                placement="right"
              >
                <img src={CheckIcon} alt="check-icon" />
              </Tooltip>
            </div>
          )}
        </div>
      ),
      key: "1",
      onClick: () => {
        state.statusId === 3
          ? showNotification({
              type: "warning",
              message: "Action Unavailable",
              description:
                "This action is unavailable as the application has already been accepted.",
            })
          : handleOpenModal("isOpenEmailModal", true);
      },
    },
    {
      type: "divider",
    },
    {
      className: "menu-step-item",
      label: (
        <div className="step-item">
          <div className="step-item-left">
            <CalendarPlus size={20} /> Schedule an interview
          </div>
          {state.isSentScheduleInterview && (
            <div className="step-item-right">
              <Tooltip
                title="You’ve sent an interview schedule to the candidate"
                placement="right"
              >
                <img src={CheckIcon} alt="check-icon" />
              </Tooltip>
            </div>
          )}
        </div>
      ),
      key: "2",
      onClick: () => {
        state.statusId === 3
          ? showNotification({
              type: "warning",
              message: "Action Unavailable",
              description:
                "This action is unavailable as the application has already been accepted.",
            })
          : handleOpenModal("isOpenScheduleModal", true);
      },
    },
    {
      className: "menu-step-item",
      label: (
        <div className="step-item">
          <div className="step-item-left">
            <ProfileOutlined
              style={{
                fontSize: "17px",
                paddingInlineStart: "1px",
                marginInlineEnd: "2px",
              }}
            />
            Send an offer letter
          </div>
          {state.isAccepted && (
            <div className="step-item-right">
              <Tooltip
                title="You’ve sent an offer letter to the candidate."
                placement="right"
              >
                <img src={CheckIcon} alt="check-icon" />
              </Tooltip>
            </div>
          )}
        </div>
      ),
      key: "3",
      onClick: () => {
        {
          state.statusId === 3
            ? showNotification({
                type: "warning",
                message: "Action Unavailable",
                description:
                  "This action is unavailable as the application has already been accepted.",
              })
            : handleOpenModal("isOpenConfirmModal", true);
        }
      },
    },
  ];

  const handleInputChange = (
    groupKey: string,
    key: string,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setState((prevState: any) => ({
      ...prevState,
      [groupKey]: {
        ...prevState[groupKey],
        [key]: e.target.value,
        errors: { ...prevState[groupKey].errors, [key]: "" },
      },
    }));
  };

  const handleCountryChange = (groupKey: string, value: string) => {
    const country = getCountry(value);
    if (country) {
      setState((prevState: any) => ({
        ...prevState,
        [groupKey]: {
          ...prevState[groupKey],
          selectedCountry: {
            countryCode: country.countryCode,
            phoneCode: country.phoneCode,
            flag: country.flag,
          },
        },
      }));
    }
  };

  const handleSelectChange = (groupKey: string, key: string, value: any) => {
    setState((prevState: any) => ({
      ...prevState,
      [groupKey]: {
        ...prevState[groupKey],
        [key]: value,
        errors: { ...prevState[groupKey].errors, [key]: "" },
      },
    }));
  };

  const handlePhoneNumberChange = (
    groupKey: string,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const phoneNumber = e.target.value;
    const sanitizedValue = phoneNumber.replace(/[^0-9()-\s]/g, "");
    setState((prevState: any) => ({
      ...prevState,
      [groupKey]: {
        ...prevState[groupKey],
        phoneValue: sanitizedValue,
        errors: { ...prevState[groupKey].errors, phoneValue: "" },
      },
    }));
  };

  const handleClickViewStudent = () => {
    window.open(
      window.location.origin +
        PATH_ROUTER.STUDENT +
        "?id=" +
        detailApplication.user.individualId,
      "_blank"
    );
  };

  useEffect(() => {
    handleGetActions();
    loadingPageAction();
  }, [detailApplication]);

  useEffect(() => {
    const element = document.getElementsByClassName(
      "content-title"
    )[0] as HTMLElement;
    if (element) {
      const elementHeight = element.offsetHeight;
      const elementForm = document.getElementsByClassName(
        "form-application"
      )[0] as HTMLElement;
      if (elementForm) {
        const heightForm = `calc(100vh - 377px - ${elementHeight}px)`;
        elementForm.style.height = heightForm;
      }
    }
    fetchListTimezone();
  }, []);

  return (
    <>
      <ModalComponent
        className="modal-success-reject"
        open={state.isOpenSuccessRejectModal}
        onCancel={() => {
          handleOpenModal("isOpenSuccessRejectModal", false),
            safeNavigate(PATH_ROUTER.APPLICATION);
        }}
        width={370}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="ok-btn"
              title="OK"
              size="large"
              type="primary"
              onClick={() => {
                handleOpenModal("isOpenSuccessRejectModal", false),
                  safeNavigate(PATH_ROUTER.APPLICATION);
              }}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <img
            width={120}
            className="success-icon"
            src={SuccessIcon}
            alt="success-icon"
          />
          <div className="title">Rejected!</div>
          <div className="title-content">You have rejected this candidate.</div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-reject"
        open={state.isOpenRejectModal}
        onCancel={() => handleOpenModal("isOpenRejectModal", false)}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="confirm-btn"
              title="Confirm"
              size="large"
              type="primary"
              onClick={handleRejectApplication}
            />
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              size="large"
              type="default"
              onClick={() => handleOpenModal("isOpenRejectModal", false)}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Reject this candidate?</div>
          <div className="title-content">
            Are you sure you want to reject this candidate? Please confirm that
            you will not be moving forward with this candidate in the O-CA
            program.
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-phone"
        open={state.isOpenPhoneModal}
        onCancel={() => handleOpenModal("isOpenPhoneModal", false)}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="ok-btn"
              title="OK"
              type="primary"
              onClick={handlePhoneCall}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Phone call</div>
          <div className="title-content">
            <InputDefault
              title="Candidate's phone number"
              type="phone-number"
              valueSelect={getCountry(
                detailApplication.phoneNumber.countryCode || "US"
              )}
              value={detailApplication.phoneNumber.phoneValue}
              option={countriesOption}
              disabled
            />
            <div className="notice-form">
              <sup>*</sup>If the candidate doesn't answer, consider sending a
              brief text message introducing yourself and asking for their
              available times.
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-email"
        open={state.isOpenEmailModal}
        onCancel={() => handleOpenModal("isOpenEmailModal", false)}
        centered
        width={680}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              type="default"
              onClick={() => handleOpenModal("isOpenEmailModal", false)}
            />
            <ButtonComponent
              className="send-btn"
              title="Send"
              type="primary"
              onClick={handleSendingEmail}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Sending an email</div>
          <div className="title-content">
            <div className="notification">
              Provide your contact info and message to arrange a pre-interview
              call with the candidate.
            </div>
            <div className="double-input">
              <InputDefault
                title="Contact number"
                type="phone-number"
                required
                onChange={(e) => handlePhoneNumberChange("emailModal", e)}
                onChangeSelect={(e) => handleCountryChange("emailModal", e)}
                valueSelect={state.emailModal.selectedCountry}
                value={state.emailModal.phoneValue}
                option={countriesOption}
                errorMsg={state.emailModal.errors.phoneValue}
              />
              <InputDefault
                title="Contact email"
                type="input"
                disabled
                value={state.emailModal.email}
                onChange={(e) => handleInputChange("emailModal", "email", e)}
                errorMsg={state.emailModal.errors.email}
              />
            </div>
            <InputDefault
              title="Your message"
              type="text-area"
              required
              maxLength={1000}
              showCount
              maxRows={8}
              value={state.emailModal.emailMsg}
              onChange={(e) => handleInputChange("emailModal", "emailMsg", e)}
              errorMsg={state.emailModal.errors.emailMsg}
            />
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-schedule"
        open={state.isOpenScheduleModal}
        onCancel={() => handleOpenModal("isOpenScheduleModal", false)}
        centered
        width={680}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              size="large"
              type="default"
              onClick={() => handleOpenModal("isOpenScheduleModal", false)}
            />
            <ButtonComponent
              className="send-btn"
              title="Send"
              size="large"
              type="primary"
              onClick={handleSendingScheduleInterview}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Schedule an interview</div>
          <div className="title-content">
            <InputDefault
              title="Position"
              type="input"
              value={state.scheduleModal.position}
              disabled
            />
            <div className="double-input">
              <InputDefault
                title="Interview Date"
                type="date-picker"
                required
                onChange={(value) =>
                  handleSelectChange("scheduleModal", "interviewDate", value)
                }
                value={state.scheduleModal.interviewDate}
                errorMsg={state.scheduleModal.errors.interviewDate}
              />
              <InputDefault
                title="Interview Time"
                type="time-picker"
                required
                onChange={(value) =>
                  handleSelectChange("scheduleModal", "interviewTime", value)
                }
                value={state.scheduleModal.interviewTime}
                errorMsg={state.scheduleModal.errors.interviewTime}
              />
            </div>
            <InputDefault
              title="Time zone"
              type="select"
              required
              placeholder="Select time zone"
              option={state.timezonesOption}
              filterOption={handleSearch}
              onChangeSelect={(value) =>
                handleSelectChange("scheduleModal", "timezone", value)
              }
              showSearch
              errorMsg={state.scheduleModal.errors.timezone}
            />
            <InputDefault
              title="Interview location"
              type="input"
              required
              placeholder="Enter the online meeting link or the interview address"
              value={state.scheduleModal.interviewLocation}
              onChange={(e) =>
                handleInputChange("scheduleModal", "interviewLocation", e)
              }
              errorMsg={state.scheduleModal.errors.interviewLocation}
            />
            <div className="double-input">
              <InputDefault
                title="Contact number"
                type="phone-number"
                required
                onChange={(e) => handlePhoneNumberChange("scheduleModal", e)}
                onChangeSelect={(e) => handleCountryChange("scheduleModal", e)}
                valueSelect={state.scheduleModal.selectedCountry}
                value={state.scheduleModal.phoneValue}
                option={countriesOption}
                errorMsg={state.scheduleModal.errors.phoneValue}
              />
              <InputDefault
                title="Contact email"
                type="input"
                disabled
                value={state.scheduleModal.email}
                onChange={(e) => handleInputChange("scheduleModal", "email", e)}
                errorMsg={state.scheduleModal.errors.email}
              />
            </div>
            <InputDefault
              title="Your message"
              type="text-area"
              required
              maxLength={1000}
              showCount
              maxRows={8}
              placeholder={
                "Please write the message you would like to send, including:\n• Interview Process\n• What should be prepared\n• Interview duration"
              }
              value={state.scheduleModal.emailMsg}
              onChange={(e) =>
                handleInputChange("scheduleModal", "emailMsg", e)
              }
              errorMsg={state.scheduleModal.errors.emailMsg}
            />
            <div className="notice-form">
              <sup>*</sup>This email will be send to both of you and candidate
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-confirm"
        open={state.isOpenConfirmModal}
        onCancel={() => handleOpenModal("isOpenConfirmModal", false)}
        centered
        width={458}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              onClick={() => {
                handleOpenModal("isOpenConfirmModal", false);
              }}
            />
            <ButtonComponent
              className="application-confirm-btn"
              title="Confirm"
              type="primary"
              onClick={() => {
                handleOpenModal("isOpenConfirmModal", false);
                handleAccepting();
              }}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Confirm Sending the Offer Letter?</div>
          <div className="title-content">
            This will send the offer letter to the candidate. Once sent, it
            cannot be undone. Are you sure you want to proceed?
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-success"
        open={state.isOpenSuccessModal}
        width={420}
        onCancel={() => handleOpenModal("isOpenSuccessModal", false)}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="view-guideline-btn"
              title="View onboarding guidelines"
              type="primary"
              onClick={() => {
                handleOpenModal("isOpenSuccessModal", false);
                handleOpenModal("isOpenGuidelineModal", true);
              }}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <img src={Congratulation} alt="congratulation-icon" />
          <div className="title">Congratulation!</div>
          <div className="title-content">
            You’ve successfully accepted a candidate! <br />
            Let's move forward with the onboarding process
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-application-guideline"
        open={state.isOpenGuidelineModal}
        onCancel={() => {
          handleOpenModal("isOpenGuidelineModal", false);
          safeNavigate(PATH_ROUTER.APPLICATION);
        }}
        width={443}
        centered
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="ok-btn"
              title="OK"
              type="primary"
              onClick={() => {
                handleOpenModal("isOpenGuidelineModal", false);
                onBackToHome();
              }}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">Onboarding Guidelines</div>
          <div className="candidate-info">
            <div className="candidate-info-avatar">
              <img
                src={detailApplication.user?.avatarUrl || EmptyStudentAvatar}
                alt="candidate-avatar"
                height={64}
                width={64}
              />
            </div>
            <div className="candidate-info-detail">
              <div className="name">{detailApplication.user?.name}</div>
              <div className="email">
                <EnvelopeSimple size={16} />
                {detailApplication.user?.email}
              </div>
              {detailApplication.phoneNumber.phoneValue && (
                <div className="phone-number">
                  <Phone size={16} />
                  {formatPhoneNumberWithHyphens(
                    detailApplication.phoneNumber.countryCode,
                    detailApplication.phoneNumber.phoneValue
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="title-content">
            <div className="description">
              Follow these steps to give your candidate a smooth onboarding
              experience.
            </div>
            <div className="steps">
              <span className="steps-item">
                Step 1:
                <a
                  href="https://likelion.notion.site/Step-1-Send-the-Offer-Letter-13144860a4f480eb89e3e28ea5e1be88"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Send the Offer Letter
                </a>
              </span>
              <span className="steps-item">
                Step 2:
                <a
                  href="https://likelion.notion.site/Step-2-Sign-Employment-Contract-13144860a4f48090b326f4d4d44d11c5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign Employment Contract
                </a>
              </span>
              <span className="steps-item">
                Step 3:
                <a
                  href="https://likelion.notion.site/Step-3-Check-Work-Authorization-13144860a4f4802c902fe0c1027fe13b"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Check Work Authorization
                </a>
              </span>
              <span className="steps-item">
                Step 4:
                <a
                  href="https://likelion.notion.site/Step-4-Prepare-for-Working-Together-13144860a4f4803bab4ce26170393cf7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prepare for working together
                </a>
              </span>
            </div>
            <span className="notice">
              <sup>*</sup>If you need more detailed guidance or next steps,
              please click{" "}
              <a
                href="https://likelion.notion.site/LION-UP-Guidelines-13544860a4f4807eb7fdc846a6b2bb89"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </span>
          </div>
        </div>
      </ModalComponent>
      <div className="background-application-form-review">
        <div className="header">
          <ButtonComponent
            className="back-btn"
            title="Application"
            type="link"
            onClick={onBackToHome}
            icon={<ArrowLeft size={24} />}
            iconPosition="start"
          />
        </div>
        <div className="content-review">
          <div className="content-title">
            <div className="content-title-left">
              <div className="job-title">
                {detailApplication.jobTitle.delta.company}
                {"  "}
                {detailApplication.jobNegotiable && (
                  <span className="title-sub">(Negotiable)</span>
                )}
              </div>
              {state.listHistory.length > 1 && (
                <div className="revised-history">
                  <div className="revised-history-title">
                    Revised application version history:
                  </div>
                  <Dropdown
                    overlayClassName="menu-version"
                    menu={{ items: versionMenu }}
                    trigger={["click"]}
                  >
                    <div className="revised-history-version">
                      Version {state.selectedVersion}
                      <span className="caret-down-icon">
                        <CaretDown size={12} color="#fff" />
                      </span>
                    </div>
                  </Dropdown>
                </div>
              )}
            </div>
            <div className="content-title-right">
              {renderStatus(state.statusId)}
            </div>
          </div>
          <div className="info-candidate">
            <div className="info-detail">
              <img
                src={detailApplication.user?.avatarUrl || EmptyStudentAvatar}
                alt="avatar"
                className="candidate-avatar"
                width={56}
                height={56}
              />
              <div className="candidate-description">
                <div className="title">{detailApplication.user?.name}</div>
                {detailApplication.user?.educations?.[0]?.schoolName && (
                  <div className="candidate-info-education">
                    <GraduationCap size={16} color="#FF7710" />
                    {detailApplication.user.educations[0].schoolName}
                  </div>
                )}
              </div>
            </div>
            <ButtonComponent
              className="view-btn"
              title="View profile"
              icon={<ExportOutlined />}
              iconPosition="end"
              onClick={handleClickViewStudent}
            />
          </div>
          <div className="form-application">
            <InputPrefix
              value={detailApplication.jobTitle.delta.candidate}
              title="Job Title"
              subTitle={detailApplication.jobNegotiable && "(Negotiable)"}
              valuePrefix={detailApplication.jobTitle.delta.company}
              type="input"
              readOnly
            />
            <InputDefault
              title="Job Type"
              value={getLabelByValue(
                JobTypeOptions,
                detailApplication.jobType.id
              )}
              type="input"
              readOnly
              info={
                detailApplication.jobType.id === 1
                  ? {
                      title: "What is the O-CA Program",
                      description:
                        "The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties.",
                    }
                  : undefined
              }
            />
            <div className="double-input">
              <InputPrefix
                value={
                  detailApplication.workPeriodStart.delta.candidate
                    ? formatDate(
                        detailApplication.workPeriodStart.delta.candidate
                      )
                    : ""
                }
                title="Start working date"
                subTitle={detailApplication.jobNegotiable && "(Negotiable)"}
                type="input"
                readOnly
                valuePrefix={formatDate(
                  detailApplication.workPeriodStart.delta.company
                )}
              />
              <InputPrefix
                value={
                  detailApplication.workPeriodEnd.delta.candidate
                    ? formatDate(
                        detailApplication.workPeriodEnd.delta.candidate
                      )
                    : ""
                }
                title="End working date"
                subTitle={detailApplication.jobNegotiable && "(Negotiable)"}
                type="input"
                readOnly
                valuePrefix={formatDate(
                  detailApplication.workPeriodEnd.delta.company
                )}
              />
            </div>
            <div className="double-input">
              <InputPrefix
                value={getLabelByValue(
                  WorkTypeOptions,
                  detailApplication.workplaceType.delta.candidate
                )}
                readOnly
                title="Workplace type"
                subTitle={detailApplication.jobNegotiable && "(Negotiable)"}
                type="input"
                valuePrefix={getLabelByValue(
                  WorkTypeOptions,
                  detailApplication.workplaceType.delta.company
                )}
              />
              <InputPrefix
                value={detailApplication.workHoursPerWeek.delta?.candidate}
                title="Hours per week"
                subTitle={detailApplication.jobNegotiable && "(Negotiable)"}
                valuePrefix={detailApplication.workHoursPerWeek.delta?.company}
                readOnly
                type="input"
              />
            </div>
            <InputDefault
              value={detailApplication.job.description}
              title="About the job"
              readOnly
              type="text-area"
              maxRows={3}
            />
            <InputPrefix
              title="Task"
              type="multiple-input-quill"
              value={_.map(detailApplication.tasks, (task) => ({
                ...task.delta.company,
                newTask: task.delta.candidate?.description || "",
                isRemove:
                  task.negotiable &&
                  _.isEmpty(task.delta.candidate.description),
              }))}
              subTitle={detailApplication.jobNegotiable && "(Negotiable)"}
              readOnly
            />
            <InputDefault
              value={_.map(
                detailApplication.job.qualifications,
                (qualification) => qualification.description
              ).join("\n")}
              title="Minimum Qualifications"
              readOnly
              maxRows={2}
              type="text-area"
            />
            <div className="resume">
              <div className="resume-title">Resume</div>
              <div className="resume-content">
                <div className="resume-content-left">
                  <div className="resume-name">
                    {detailApplication.selectedAttachment.name}
                  </div>
                  <div className="resume-upload-date">
                    Upload on{" "}
                    {formatDate(
                      detailApplication.selectedAttachment.uploadDate
                    )}
                  </div>
                </div>
                <div className="resume-content-right">
                  <ButtonComponent
                    className="view-btn"
                    icon={<EyeOutlined style={{ fontSize: 20 }} />}
                    onClick={() =>
                      handleViewResume(detailApplication.selectedAttachment.id)
                    }
                  />
                </div>
              </div>
            </div>
            {detailApplication.phoneNumber.phoneValue && (
              <InputDefault
                value={detailApplication.phoneNumber.phoneValue}
                valueSelect={getCountry(
                  detailApplication.phoneNumber.countryCode || "US"
                )}
                title="Phone number"
                type="phone-number"
                option={countriesOption}
                readOnly
              />
            )}
            {detailApplication.portfolio && (
              <InputDefault
                value={detailApplication.portfolio}
                title="Portfolio"
                type="input"
                addonBefore="https://"
                readOnly
              />
            )}
            {_.map(detailApplication.personalWebsites, (website) => (
              <InputDefault
                value={website}
                title="Personal website"
                type="input"
                addonBefore="https://"
                readOnly
              />
            ))}
            {detailApplication.introduction && (
              <InputDefault
                value={detailApplication.introduction}
                title="Self-Introduction"
                type="text-area"
                maxRows={2}
                readOnly
              />
            )}
          </div>
          <div className="action">
            <div className="action-left"></div>
            <div className="action-right">
              {state.selectedVersion ===
              applicationDetailReview.histories.length ? (
                <>
                  {state.statusId === 2 && (
                    <ButtonComponent
                      className="reject-btn"
                      title="Reject"
                      onClick={() => handleOpenRejectModal(true)}
                    />
                  )}
                  {(state.statusId === 2 || state.statusId === 3) && (
                    <Dropdown
                      overlayClassName="menu-step"
                      menu={{ items: buttonMenu }}
                      trigger={["click"]}
                      placement="topRight"
                    >
                      <ButtonComponent
                        className="select-btn"
                        type="primary"
                        icon={<CaretUp />}
                        iconPosition="end"
                        title="Select next step"
                      />
                    </Dropdown>
                  )}
                </>
              ) : (
                <ButtonComponent
                  className="cancel-btn"
                  title="Back to current verision"
                  onClick={handleBackCurrentVerion}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default applicationFormReview;
