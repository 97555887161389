import auth from "../../../utils/auth";
import CompanyProfile from "./companyProfile/companyProfile";
import StudentProfile from "./studentProfile/studentProfile";

interface ProfileProps {
  handleOpen: () => void;
}

const Profile: React.FC<ProfileProps> = ({ handleOpen }) => {
  const isCompanyUser = auth.isCompanyUser();

  return isCompanyUser ? (
    <CompanyProfile handleOpen={handleOpen} />
  ) : (
    <StudentProfile handleOpen={handleOpen} />
  );
};

export default Profile;
