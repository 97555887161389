import { PlusOutlined } from "@ant-design/icons";
import { Checkbox, Dropdown, MenuProps, Tag } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import _ from "lodash";
import {
  Archive,
  ArrowRight,
  ArrowUDownLeft,
  BellRinging,
  CaretDown,
  PencilSimple,
  Trash,
} from "phosphor-react";
import { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { SuccessIconGif } from "../../../../assets/gif";
import {
  EditCircleIcon,
  EllipsisIcon,
  EmptyBackground,
  EmptyCompanyLogo,
  PlusCircleIcon,
  SuccessIcon,
} from "../../../../assets/svg";
import ButtonComponent from "../../../../components/button/button";
import ModalComponent from "../../../../components/modal/modal";
import { showNotification } from "../../../../components/notification/notification";
import { LOADING_TYPES } from "../../../../constants/loadingTypes";
import {
  CompanySize,
  CompanyStatus,
} from "../../../../constants/selectOptions";
import type { CompanyProfile } from "../../../../interfaces/profile";
import { fetchCompanyProfile } from "../../../../services/fetchCompanyProfile";
import { fetchDetailJob } from "../../../../services/fetchDetailJob";
import { fetchListJob } from "../../../../services/fetchListJob";
import { handleCloseJob } from "../../../../services/handleCloseJob";
import { handleDeleteJob } from "../../../../services/handleDeleteJob";
import { handlePostJob } from "../../../../services/handlePostJob";
import { handleReopenJob } from "../../../../services/handleReopenJob";
import { handleUpdateCompanyProfile } from "../../../../services/handleUpdateCompanyProfile";
import { handleUpdateStatusCompany } from "../../../../services/handleUpdateStatusCompany";
import { handleUpdateTag } from "../../../../services/handleUpdateTag";
import updateGotoData from "../../../../store/actions/goto";
import loadingPage from "../../../../store/actions/loading";
import updateReloadPage from "../../../../store/actions/reloadPage";
import { calculateDaysDiff, getUUIDFromURL } from "../../../../utils";
import auth from "../../../../utils/auth";
import useActions from "../../../../utils/customHook/useActions";
import { useSetState } from "../../../../utils/customHook/useSetState";
import AddTagModal from "../addTagModal";
import { EditRef } from "./companyProfile.d";
import { newCreateJobFormatter } from "./companyProfile.h";
import "./companyProfile.s.scss";
import EditJobPostingModal from "./editJobPostingModal";
import EditProfileModal from "./editProfileModal";
import JobPostingModal from "./jobPostingModal";
import ViewDetailJobModal from "./viewDetailJobModal";

const createJobPostingModalInitial = {
  titleValue: "",
  titleNegotiable: true,
  jobTypeId: null,
  startWorkingDateValue: null,
  startWorkingDateNegotiable: true,
  endWorkingDateValue: null,
  endWorkingDateNegotiable: true,
  workplaceTypeValue: null,
  workplaceTypeNegotiable: true,
  hoursPerWeekValue: "",
  hoursPerWeekNegotiable: true,
  tasksValue: "",
  tasksNegotiable: true,
  aboutJob: "",
  qualifications: "",
  errors: {},
};

interface ProfileProps {
  handleOpen: () => void;
}

const CompanyProfile: React.FC<ProfileProps> = ({ handleOpen }) => {
  const dispatch = useDispatch();
  const loadingPageAction = useActions(loadingPage);
  const meGotoRedux = useSelector((state: any) => state.goto.me);
  const editRef = useRef<EditRef | null>(null);
  const checkCloseAllJob = useRef(false);

  const [state, setState] = useSetState({
    dataListSelectModal: {},
    openCreateJobModal: false,
    openAddTagModal: false,
    companyCultureTags: [],
    companyCultureTagsTemp: [],
    languageTags: [],
    languageTagsTemp: [],
    focusAreaTags: [],
    focusAreaTagsTemp: [],
    profile: {},
    createJobPostingModal: createJobPostingModalInitial,
    listJobPosting: [],
    clickedId: null,
    isOpenUpdateJobModal: false,
    isOpenSuccessModal: false,
    isOpenEditModal: false,
    isOpenCreateJobSuccessModal: false,
    isClosing: false,
    isOpenEditJobModal: false,
    timesOpen: 0,
    timesOpenViewJob: 0,
    isSubmitting: false,
    isOpenViewDetailJobModal: false,
    isOpenRemindModal: false,
    isOpenComplianceModal: false,
    isAgreed: false,
    viewJobDetail: {},
    errorMsgEditModal: {},
    listEmptyInput: [],
  });

  const { profile }: { profile: CompanyProfile } = state || {};

  const handleOpenModal = (keyModal: string, isOpen: boolean) => {
    setState({ [keyModal]: isOpen });
  };

  const handleSelectCompany = (key: string, value: any[]) => {
    setState({ [key]: value });
  };

  const handleTimeOpen = () => {
    setState({ timesOpen: state.timesOpen + 1 });
  };

  const handleTimeOpenViewJob = () => {
    setState({ timesOpenViewJob: state.timesOpenViewJob + 1 });
  };

  const handleUpdateState = (key: string, value: any) => {
    setState({ [key]: value });
  };

  const handleShowModalCreateJob = () => {
    if (isMobile) {
      handleOpen();
      return;
    }
    if (
      !meGotoRedux.name ||
      !meGotoRedux.location?.cityId ||
      _.isEmpty(meGotoRedux.industryTags)
    ) {
      handleOpenModal("isOpenRemindModal", true);
    } else {
      setState({
        openCreateJobModal: true,
        createJobPostingModal: { ...state.createJobPostingModal, errors: {} },
      });
    }
  };

  const handleCloseModalCreateJob = () => {
    // Reset form data when closing
    setState({
      openCreateJobModal: false,
      createJobPostingModal: createJobPostingModalInitial,
    });
  };

  const handleCloseModalAddTag = () => {
    setState({ openAddTagModal: false });
  };

  const renderColorStatus = (statusId: number) => {
    switch (statusId) {
      case 1:
      case 3:
        return {
          color: "#027A48",
          backgroundColor: "#D1FADF",
        };
      case 2:
        return {
          color: "#DF8600",
          backgroundColor: "#FEE49A",
        };
      default:
        return {
          color: "#8F8F8F",
          backgroundColor: "#F5F5F5",
        };
    }
  };

  const validatesCreateJobPostingModal = () => {
    const {
      titleValue,
      jobTypeId,
      startWorkingDateValue,
      endWorkingDateValue,
      workplaceTypeValue,
      hoursPerWeekValue,
      tasksValue,
      aboutJob,
      qualifications,
    } = state.createJobPostingModal;

    const errors: Record<string, string> = {};
    const missingFields: string[] = [];
    const isNotEmpty = (value: string) =>
      value.replace(/\s|\n/g, "").length > 0;

    // Mapping giữa key và label để hiển thị trong notification
    const fieldLabels: Record<string, string> = {
      titleValue: "Job Title",
      jobTypeId: "Job Type",
      startWorkingDateValue: "Start Working Date",
      endWorkingDateValue: "End Working Date",
      workplaceTypeValue: "Workplace Type",
      hoursPerWeekValue: "Hours Per Week",
      tasksValue: "Tasks",
      aboutJob: "About Job",
      qualifications: "Minimum Qualifications",
    };

    // Validate và thu thập các trường còn thiếu
    if (!titleValue?.trim()) {
      errors.titleValue = "Field is required.";
      missingFields.push(fieldLabels.titleValue);
    }
    if (!jobTypeId) {
      errors.jobTypeId = "Field is required.";
      missingFields.push(fieldLabels.jobTypeId);
    }
    if (!startWorkingDateValue) {
      errors.startWorkingDateValue = "Field is required.";
      missingFields.push(fieldLabels.startWorkingDateValue);
    }
    if (!endWorkingDateValue) {
      errors.endWorkingDateValue = "Field is required.";
      missingFields.push(fieldLabels.endWorkingDateValue);
    } else if (
      startWorkingDateValue &&
      dayjs(startWorkingDateValue).isAfter(dayjs(endWorkingDateValue), "day")
    ) {
      errors.endWorkingDateValue = "End date must be after the start date.";
      missingFields.push("Valid End Working Date");
    }
    if (!workplaceTypeValue) {
      errors.workplaceTypeValue = "Field is required.";
      missingFields.push(fieldLabels.workplaceTypeValue);
    }
    if (!hoursPerWeekValue) {
      errors.hoursPerWeekValue = "Field is required.";
      missingFields.push(fieldLabels.hoursPerWeekValue);
    }
    if (!isNotEmpty(tasksValue)) {
      errors.tasksValue = "Field is required.";
      missingFields.push(fieldLabels.tasksValue);
    }
    if (!isNotEmpty(aboutJob)) {
      errors.aboutJob = "Field is required.";
      missingFields.push(fieldLabels.aboutJob);
    }
    if (!isNotEmpty(qualifications)) {
      errors.qualifications = "Field is required.";
      missingFields.push(fieldLabels.qualifications);
    }

    // Cập nhật state với tất cả lỗi
    setState({
      createJobPostingModal: {
        ...state.createJobPostingModal,
        errors,
      },
    });

    return Object.keys(errors).length === 0;
  };

  const handleShowModalAddTag = (
    type: "company-culture" | "languages" | "focus-areas"
  ) => {
    if (isMobile) {
      handleOpen();
      return;
    }
    let renderTagSelectModal = {};
    switch (type) {
      case "company-culture":
        renderTagSelectModal = {
          typeId: 2,
          titleModal: "Add Company Culture Tags",
          description: `These tags will help showcase your company's culture on your profile and in job postings.`,
          titleSelect: "Company Culture",
          placeholder: "Type a value or trait and press Enter to create a tag",
          onChange: (value: any[]) =>
            handleSelectCompany("companyCultureTagsTemp", value),
        };
        break;
      case "languages":
        renderTagSelectModal = {
          typeId: 3,
          titleModal: "Add Languages Tags",
          description:
            "These tags will help showcase your languages on your profile and in job postings.",
          titleSelect: "Languages",
          placeholder: "Type a language and press Enter to create a tag",
          onChange: (value: string[]) =>
            handleSelectCompany("languageTagsTemp", value),
        };
        break;
      case "focus-areas":
        renderTagSelectModal = {
          typeId: 4,
          titleModal: "Add Focus Areas Tags",
          description:
            "These tags will help showcase your focus areas on your profile and in job postings.",
          titleSelect: "Focus Areas",
          placeholder: "Type an interest and press Enter to create a tag",
          onChange: (value: string[]) =>
            handleSelectCompany("focusAreaTagsTemp", value),
        };
        break;

      default:
        break;
    }
    setState({
      openAddTagModal: true,
      dataListSelectModal: renderTagSelectModal,
    });
  };

  const handleInputChange = (groupKey: string, key: string, value: any) => {
    setState((prevState: any) => ({
      ...prevState,
      [groupKey]: {
        ...prevState[groupKey],
        [key]: value,
        errors: {
          ...prevState[groupKey].errors,
          [key]: "",
        },
      },
    }));
  };

  const getListJobPosting = async (id: number) => {
    try {
      const requestBody = { companyId: id };
      const result = await fetchListJob(0, 100, requestBody);
      const data = result?.content || [];
      setState({ listJobPosting: data });
      dispatch(updateGotoData("me", { listJob: data }));
      if (checkCloseAllJob.current) {
        if (
          _.filter(data, { jobStatusId: 2 }).length === data.length &&
          profile.statusId !== 2
        ) {
          handleUpdateStatus(2);
        }
        checkCloseAllJob.current = false;
      }
    } catch (error) {
      console.error("Error:", error);
      setState({ listJobPosting: [] });
    }
  };

  const getJobDetail = async (jobId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      const result = await fetchDetailJob(jobId);
      if (result) {
        setState({
          viewJobDetail: result,
          isOpenViewDetailJobModal: true,
          clickedId: jobId,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleCreateJob = async () => {
    try {
      if (!state.openCreateJobModal) {
        return; // Don't proceed if modal isn't open
      }

      loadingPageAction(LOADING_TYPES.CREATING);

      // Create a local copy of the modal data to ensure we're using the latest state
      const currentModalData = { ...state.createJobPostingModal };

      if (validatesCreateJobPostingModal()) {
        const requestBody = newCreateJobFormatter(currentModalData);

        // Add error handling for empty or invalid data
        if (!requestBody || Object.keys(requestBody).length === 0) {
          console.error("Invalid form data");
          return;
        }

        const isSuccess = await handlePostJob(requestBody);
        if (isSuccess) {
          setState({
            createJobPostingModal: createJobPostingModalInitial,
            isAgreed: false,
          });
          handleCloseModalCreateJob();
          handleOpenModal("isOpenCreateJobSuccessModal", true);
          getListJobPosting(profile.id);
          if (profile.statusId !== 1) {
            handleUpdateStatus(1);
          }
          dispatch(updateReloadPage("home", true));
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleClosedJob = async (jobId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.CLOSING);
      const isSuccess = await handleCloseJob(jobId);
      if (isSuccess) {
        setState({
          isOpenUpdateJobModal: false,
          isOpenSuccessModal: true,
        });
        checkCloseAllJob.current = true;
        getListJobPosting(profile.id);
        dispatch(updateReloadPage("home", true));
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleReopenedJob = async (jobId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.OPENING);
      const isSuccess = await handleReopenJob(jobId);
      if (isSuccess) {
        handleOpenModal("isOpenSuccessModal", true);
        getListJobPosting(profile.id);
        if (profile.statusId !== 1) {
          handleUpdateStatus(1);
        }
        dispatch(updateReloadPage("home", true));
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleDeletedJob = async (jobId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.DELETING);
      const isSuccess = await handleDeleteJob(jobId);
      if (isSuccess) {
        setState({
          isOpenUpdateJobModal: false,
          isOpenSuccessModal: true,
          isOpenViewDetailJobModal: false,
        });
        getListJobPosting(profile.id);
        dispatch(updateReloadPage("home", true));
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleUpdateCompanyTag = async (categoryId: number) => {
    try {
      loadingPageAction(LOADING_TYPES.UPDATING);
      const { companyCultureTagsTemp, languageTagsTemp, focusAreaTagsTemp } =
        state;
      const data =
        categoryId === 2
          ? companyCultureTagsTemp
          : categoryId === 3
          ? languageTagsTemp
          : focusAreaTagsTemp;

      // Thêm placeholder nếu không có tags
      const placeholderTag = {
        id: null,
        name: "Placeholder",
        categoryId,
      };

      const finalData = data.length ? data : [placeholderTag];

      const tags = _.map(finalData, (item) => ({
        tagId: item.id,
        name: item.name,
        categoryId,
      }));
      const requestUpdateTag = {
        entityTypeId: 6,
        entityId: profile.id,
        tags,
      };
      const isSuccess = await handleUpdateTag(requestUpdateTag);
      if (isSuccess) {
        const updateMeRedux = {};
        if (categoryId === 2) {
          _.assign(updateMeRedux, { companyCultureTags: data });
        } else if (categoryId === 3) {
          _.assign(updateMeRedux, { languageTags: data });
        } else {
          _.assign(updateMeRedux, { focusAreaTags: data });
        }
        dispatch(updateGotoData("me", updateMeRedux));
        setState({
          [categoryId === 2
            ? "companyCultureTags"
            : categoryId === 3
            ? "languageTags"
            : "focusAreaTags"]: data,
          openAddTagModal: false,
        });
        showNotification({
          type: "success",
          message: "Update Successfully!",
          description:
            "The content you just saved has been successfully update.",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleUpdateAllCompanyTag = async () => {
    try {
      loadingPageAction(LOADING_TYPES.UPDATING);
      if (editRef.current) {
        const data = editRef.current.getTagsData();
        const { companyCultureTags, languageTags, focusAreaTags } = data;

        // Thêm placeholder tag cho API nhưng không hiển thị trên UI
        const placeholderTag = {
          id: null,
          name: "Placeholder",
          categoryId: 1,
          isPlaceholder: true, // thêm flag để đánh dấu
        };

        const finalCompanyCultureTags = companyCultureTags.length
          ? companyCultureTags
          : [placeholderTag];
        const finalLanguageTags = languageTags.length
          ? languageTags
          : [placeholderTag];
        const finalFocusAreaTags = focusAreaTags.length
          ? focusAreaTags
          : [placeholderTag];

        const mapTags = (items: any[], categoryId: number) => {
          return items.map((item) => ({
            tagId: item.id || null,
            name: item.name.replace(/#/g, "").trim(),
            categoryId: categoryId,
          }));
        };

        const tags = [
          ...mapTags(finalCompanyCultureTags, 2),
          ...mapTags(finalLanguageTags, 3),
          ...mapTags(finalFocusAreaTags, 4),
        ];

        const requestUpdateTag = {
          entityTypeId: 6,
          entityId: profile.id,
          tags,
        };

        const isSuccess = await handleUpdateTag(requestUpdateTag);
        if (isSuccess) {
          // Lọc bỏ placeholder tags khi update state và redux
          const filteredCompanyCultureTags = finalCompanyCultureTags.filter(
            (tag) => !tag.isPlaceholder
          );
          const filteredLanguageTags = finalLanguageTags.filter(
            (tag) => !tag.isPlaceholder
          );
          const filteredFocusAreaTags = finalFocusAreaTags.filter(
            (tag) => !tag.isPlaceholder
          );

          dispatch(
            updateGotoData("me", {
              companyCultureTags: filteredCompanyCultureTags,
              languageTags: filteredLanguageTags,
              focusAreaTags: filteredFocusAreaTags,
            })
          );

          setState({
            companyCultureTags: filteredCompanyCultureTags,
            languageTags: filteredLanguageTags,
            focusAreaTags: filteredFocusAreaTags,
            companyCultureTagsTemp: filteredCompanyCultureTags,
            languageTagsTemp: filteredLanguageTags,
            focusAreaTagsTemp: filteredFocusAreaTags,
          });

          showNotification({
            type: "success",
            message: "Update Successfully!",
            description:
              "The content you just saved has been successfully update.",
          });
          handleOpenModal("isOpenEditModal", false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const getCompanyProfile = async () => {
    try {
      loadingPageAction(LOADING_TYPES.LOADING);
      const profile = await fetchCompanyProfile();
      auth.setAvatar(profile?.logoUrl);

      // Filter out placeholder tags
      const filteredProfile = {
        ...profile,
        companyCultureTags:
          profile?.companyCultureTags?.filter(
            (tag) => tag.name !== "Placeholder"
          ) || [],
        languageTags:
          profile?.languageTags?.filter((tag) => tag.name !== "Placeholder") ||
          [],
        focusAreaTags:
          profile?.focusAreaTags?.filter((tag) => tag.name !== "Placeholder") ||
          [],
        industryTags:
          profile?.industryTags && profile.industryTags.length > 0
            ? [profile.industryTags[profile.industryTags.length - 1]]
            : [],
      };

      dispatch(updateGotoData("me", filteredProfile));
      setState({
        profile: filteredProfile,
        companyCultureTags: filteredProfile?.companyCultureTags || [],
        companyCultureTagsTemp: filteredProfile?.companyCultureTags || [],
        languageTags: filteredProfile?.languageTags || [],
        languageTagsTemp: filteredProfile?.languageTags || [],
        focusAreaTags: filteredProfile?.focusAreaTags || [],
        focusAreaTagsTemp: filteredProfile?.focusAreaTags || [],
      });

      if (filteredProfile?.id) {
        getListJobPosting(filteredProfile.id);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const handleUpdateProfile = async () => {
    try {
      loadingPageAction(LOADING_TYPES.UPDATING);
      if (editRef.current) {
        const data = editRef.current.getProfileData();

        // Validation checks...
        const errors: Record<string, string> = {};
        if (_.isEmpty(data.name)) {
          errors.name = "Field is required.";
        }
        if (
          !data.city ||
          (typeof data.city === "object" && !data.city?.cityId) ||
          (typeof data.city === "string" && data.city.trim() === "")
        ) {
          errors.city = "Field is required.";
        }
        if (!data.industry?.name) {
          errors.industry = "Field is required.";
        }

        if (!_.isEmpty(errors)) {
          setState({ errorMsgEditModal: errors });
          // Call scrollToError after setting state
          setTimeout(() => {
            editRef.current?.scrollToError();
          }, 0);

          showNotification({
            type: "error",
            message: "Invalid Input",
            description:
              "Some fields have incorrect values. Please review and correct them before proceeding.",
          });
          return;
        }

        setState({ errorMsgEditModal: {} });

        const requestBody = {
          logoUuid: data?.logoUuid || getUUIDFromURL(profile.logoUrl),
          backgroundUuid:
            data?.backgroundUuid || getUUIDFromURL(profile.backgroundUrl),
          name: data.name,
          ...(data?.industry?.name && {
            industryTags: [
              {
                tagId: data?.industry?.id || null,
                name: data?.industry?.name,
                categoryId: 1,
              },
            ],
          }),
          oneLineProfile: data.oneLineProfile,
          about: data.companyOverview,
          statusId: data.statusId,
          companySizeId: data.companySizeId,
          cityId: data.city?.cityId || data.location?.cityId,
          ...(data.website?.trim() && { website: data.website.trim() }),
        };

        const response = await handleUpdateCompanyProfile(requestBody);

        // Kiểm tra response
        if (response === true) {
          // Update thành công
          await getCompanyProfile();
          showNotification({
            type: "success",
            message: "Update Successfully!",
            description:
              "The content you just saved has been successfully updated.",
          });
          handleOpenModal("isOpenEditModal", false);
        } else {
          // Update thất bại, response là error message
          showNotification({
            type: "error",
            message: "Update Failed",
            description:
              response ||
              "Failed to update profile. Please check your input and try again.",
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      showNotification({
        type: "error",
        message: "Update Failed",
        description: "An unexpected error occurred. Please try again.",
      });
    } finally {
      loadingPageAction();
    }
  };

  const handleSaveEditProfile = () => {
    if (editRef.current) {
      const activeKey = editRef.current.getActiveKey();
      if (activeKey === "1") {
        handleUpdateProfile();
      } else {
        handleUpdateAllCompanyTag();
      }
    }
  };

  const handleUpdateStatus = async (statusId: number) => {
    try {
      // loadingPageAction(LOADING_TYPES.UPDATING);
      const isSuccess = await handleUpdateStatusCompany(statusId);
      if (isSuccess) {
        dispatch(updateGotoData("me", { statusId }));
        setState({ profile: { ...state.profile, statusId } });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // loadingPageAction();
    }
  };

  const statusDropdown: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <div
          className={classNames(
            "status-item",
            profile?.statusId === 1 && "status-item-active"
          )}
        >
          Now hiring
        </div>
      ),
      onClick: () => handleUpdateStatus(1),
    },
    {
      key: "1",
      label: (
        <div
          className={classNames(
            "status-item",
            profile?.statusId === 2 && "status-item-active"
          )}
        >
          Hiring soon
        </div>
      ),
      onClick: () => handleUpdateStatus(2),
    },
    {
      key: "2",
      label: (
        <div
          className={classNames(
            "status-item",
            profile?.statusId === 3 && "status-item-active"
          )}
        >
          Open to coffee chats
        </div>
      ),
      onClick: () => handleUpdateStatus(3),
    },
    {
      key: "3",
      label: (
        <div
          className={classNames(
            "status-item",
            profile?.statusId === 4 && "status-item-active"
          )}
        >
          None
        </div>
      ),
      onClick: () => handleUpdateStatus(4),
    },
  ];

  const actionDropdownClose: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <>
          <Archive size={20} />
          Close job
        </>
      ),
      onClick: () => {
        setState({ isClosing: true });
        handleOpenModal("isOpenUpdateJobModal", true);
      },
    },
    {
      key: "1",
      label: (
        <>
          <Trash size={20} color="#B42318" />{" "}
          <span style={{ color: "#B42318" }}>Delete job</span>
        </>
      ),
      onClick: () => {
        setState({ isClosing: false });
        handleOpenModal("isOpenUpdateJobModal", true);
      },
    },
  ];

  const actionDropdownReopen: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <>
          <ArrowUDownLeft size={20} />
          Reopen job
        </>
      ),
      onClick: () => {
        setState({ isClosing: null });
        handleReopenedJob(state.clickedId);
      },
    },
    {
      key: "1",
      label: (
        <>
          <Trash size={20} color="#B42318" />{" "}
          <span style={{ color: "#B42318" }}>Delete job</span>
        </>
      ),
      onClick: () => {
        setState({ isClosing: false });
        handleOpenModal("isOpenUpdateJobModal", true);
      },
    },
  ];

  useEffect(() => {
    if (!meGotoRedux.id) {
      getCompanyProfile();
    } else {
      setState({
        profile: meGotoRedux,
        companyCultureTags: meGotoRedux.companyCultureTags || [],
        companyCultureTagsTemp: meGotoRedux.companyCultureTags || [],
        languageTags: meGotoRedux.languageTags || [],
        languageTagsTemp: meGotoRedux.languageTags || [],
        focusAreaTags: meGotoRedux.focusAreaTags || [],
        focusAreaTagsTemp: meGotoRedux.focusAreaTags || [],
        listJobPosting: meGotoRedux.listJob || [],
      });
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(profile)) {
      const listEmptyInput = [];
      if (!profile.name) {
        listEmptyInput.push("Company name");
      }
      if (_.isEmpty(profile.industryTags)) {
        listEmptyInput.push("Industry");
      }
      if (!profile.location?.cityId) {
        listEmptyInput.push("Address (City Name)");
      }
      setState({ listEmptyInput });
    }
  }, [state.profile]);

  return (
    <>
      <ModalComponent
        className="modal-remind"
        onCancel={() => handleOpenModal("isOpenRemindModal", false)}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              onClick={() => handleOpenModal("isOpenRemindModal", false)}
            />
            <ButtonComponent
              className="edit-btn"
              title="Edit profile"
              onClick={() => {
                handleOpenModal("isOpenRemindModal", false);
                handleOpenModal("isOpenEditModal", true);
                handleTimeOpen();
              }}
            />
          </div>
        }
        open={state.isOpenRemindModal}
        width={410}
        zIndex={1502}
      >
        <div className="modal-content-custom">
          <div className="title">
            Add required info to your profile before posting a job
          </div>
          <div className="title-content">
            Please complete the following required information to create a job
            posting:
            <ul>
              {_.map(state.listEmptyInput, (item) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-compliance-agreement"
        onCancel={() => handleOpenModal("isOpenComplianceModal", false)}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              onClick={() => handleOpenModal("isOpenComplianceModal", false)}
            />
            <ButtonComponent
              className={classNames("edit-btn", !state.isAgreed && "disabled")}
              title="Submit"
              disabled={!state.isAgreed}
              onClick={() => {
                handleOpenModal("isOpenComplianceModal", false);
                handleCreateJob();
              }}
            />
          </div>
        }
        open={state.isOpenComplianceModal}
        width={532}
        zIndex={1502}
      >
        <div className="modal-content-custom">
          <div className="title">O-CA Program Compliance Agreement</div>
          <div className="title-content">
            To ensure compliance with the O-CA Program regulations, please
            review the following requirements before submitting your job
            posting:
            <div className="guideline">
              <div className="guideline-title">Educational Purpose</div>
              <div className="guideline-content">
                The O-CA Program should primarily focus on education and
                training, ensuring that the employee(student) is the primary
                beneficiary.
              </div>
            </div>
            <div className="guideline">
              <div className="guideline-title">Mutual Agreement</div>
              <div className="guideline-content">
                A clear agreement, preferably documented in writing, must state
                that the employee(student) will be unpaid before it begins.
              </div>
            </div>
            <div className="guideline">
              <div className="guideline-title">Hourly Limit</div>
              <div className="guideline-content">
                Students are not allowed to work more than 20 hours per week
                during the semester unless explicitly authorized by their
                school.
              </div>
            </div>
            <Checkbox
              className="agree"
              checked={state.isAgreed}
              onChange={() => setState({ isAgreed: !state.isAgreed })}
            >
              I understand and agree to follow the O-CA Program guidelines.
            </Checkbox>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        title={
          <div
            style={{ textAlign: "center", fontSize: "20px", fontWeight: 600 }}
          >
            Create a job posting
          </div>
        }
        centered
        onCancel={handleCloseModalCreateJob}
        footer={
          <div>
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              onClick={handleCloseModalCreateJob}
            />
            <ButtonComponent
              className="send-btn"
              title="Submit"
              onClick={() => {
                if (state.createJobPostingModal.jobTypeId === 1) {
                  handleOpenModal("isOpenComplianceModal", true);
                  return;
                }
                handleCreateJob();
              }}
            />
          </div>
        }
        className="modal-form-create-job-posting"
        open={state.openCreateJobModal}
        zIndex={1051}
        children={
          <JobPostingModal
            key={state.openCreateJobModal ? "open" : "closed"}
            defaultData={state.createJobPostingModal}
            onChange={handleInputChange}
          />
        }
        width={680}
      />
      <ModalComponent
        title={null}
        width={680}
        centered
        onCancel={() => handleOpenModal("isOpenViewDetailJobModal", false)}
        className="modal-view-detail-job"
        open={state.isOpenViewDetailJobModal}
        footer={null}
        zIndex={1051}
        children={
          <ViewDetailJobModal
            defaultData={state.viewJobDetail}
            listJobPosting={state.listJobPosting}
            actionReopen={actionDropdownReopen}
            actionClose={actionDropdownClose}
            timesOpenViewJob={state.timesOpenViewJob}
          />
        }
      />
      <ModalComponent
        className="modal-add-tag-company"
        open={state.openAddTagModal}
        title={state.dataListSelectModal.titleModal}
        centered
        width={680}
        onCancel={handleCloseModalAddTag}
        footer={
          <div className="footer-status-post">
            <ButtonComponent
              onClick={handleCloseModalAddTag}
              className="cancel-btn"
              title="Cancel"
            />
            <ButtonComponent
              className="save-btn"
              title="Save"
              onClick={() =>
                handleUpdateCompanyTag(state.dataListSelectModal.typeId)
              }
            />
          </div>
        }
        children={
          <AddTagModal
            typeId={state.dataListSelectModal.typeId}
            description={state.dataListSelectModal.description}
            titleSelect={state.dataListSelectModal.titleSelect}
            placeholder={state.dataListSelectModal.placeholder}
            value={
              state.dataListSelectModal.typeId === 2
                ? state.companyCultureTagsTemp
                : state.dataListSelectModal.typeId === 3
                ? state.languageTagsTemp
                : state.focusAreaTagsTemp
            }
            onChange={state.dataListSelectModal.onChange}
            entityTypeId={6}
          />
        }
      />
      <ModalComponent
        title={
          <div
            style={{ textAlign: "center", fontSize: "20px", fontWeight: 600 }}
          >
            Edit Profile
          </div>
        }
        open={state.isOpenEditModal}
        centered
        width={840}
        onCancel={() => handleOpenModal("isOpenEditModal", false)}
        footer={
          <>
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              onClick={() => handleOpenModal("isOpenEditModal", false)}
            />
            <ButtonComponent
              className="save-btn"
              title="Save"
              onClick={handleSaveEditProfile}
            />
          </>
        }
        className="modal-form-edit-profile"
        children={
          <EditProfileModal
            ref={editRef}
            defaultData={profile}
            listJobPosting={state.listJobPosting}
            handleOpenModal={handleOpenModal}
            handleUpdateState={handleUpdateState}
            handleReopenJob={handleReopenedJob}
            handleCreateJob={handleShowModalCreateJob}
            handleViewJobDetail={getJobDetail}
            handleTimeOpenViewJob={handleTimeOpenViewJob}
            companyCultureTagsTemp={state.companyCultureTagsTemp}
            languageTagsTemp={state.languageTagsTemp}
            focusAreaTagsTemp={state.focusAreaTagsTemp}
            timesOpen={state.timesOpen}
            errors={state.errorMsgEditModal}
          />
        }
      />
      <ModalComponent
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 62px 0 22px",
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            Job Posting
            <ButtonComponent
              className="post-job-btn"
              onClick={handleShowModalCreateJob}
              title="Post a job"
            />
          </div>
        }
        open={state.isOpenEditJobModal}
        width={680}
        footer={null}
        onCancel={() => handleOpenModal("isOpenEditJobModal", false)}
        className="modal-form-edit-list-job"
        children={
          <EditJobPostingModal
            listJobPosting={state.listJobPosting}
            handleOpenModal={handleOpenModal}
            handleUpdateState={handleUpdateState}
            handleReopenJob={handleReopenedJob}
            handleViewJobDetail={getJobDetail}
            handleTimeOpenViewJob={handleTimeOpenViewJob}
            timesOpen={state.timesOpen}
          />
        }
      />
      <ModalComponent
        className="modal-update-success"
        open={state.isOpenSuccessModal}
        onCancel={() => handleOpenModal("isOpenSuccessModal", false)}
        width={370}
        zIndex={1052}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="ok-btn"
              title="OK"
              size="large"
              type="primary"
              onClick={() => {
                handleOpenModal("isOpenSuccessModal", false);
                setState({ isClosing: false });
              }}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <img src={SuccessIcon} alt="success-icon" />
          <div className="title">
            {!!state.isClosing === true
              ? "Closed!"
              : state.isClosing === false
              ? "Deleted!"
              : "Opened!"}
          </div>
          <div className="title-content">
            {!!state.isClosing === true ? (
              "The job posting has been successfully closed."
            ) : state.isClosing === false ? (
              "The job posting has been successfully deleted."
            ) : (
              <>
                The job posting has been successfully opened.
                <br /> This position is now accepting applications.
              </>
            )}
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-create-success"
        open={state.isOpenCreateJobSuccessModal}
        onCancel={() => handleOpenModal("isOpenCreateJobSuccessModal", false)}
        width={473}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="ok-btn"
              title="OK"
              size="large"
              type="primary"
              onClick={() =>
                handleOpenModal("isOpenCreateJobSuccessModal", false)
              }
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <img width={110} src={SuccessIconGif} alt="success-gif" />
          <div className="title">Job posting created successfully</div>
          <div className="title-content">
            Your job posting is live! It’s now visible to potential candidates.
            <br />
            You can manage or edit it anytime from your job listings section.
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        className="modal-update"
        open={state.isOpenUpdateJobModal}
        zIndex={1052}
        onCancel={() => handleOpenModal("isOpenUpdateJobModal", false)}
        footer={
          <div className="modal-footer-custom">
            <ButtonComponent
              className="confirm-btn"
              title={state.isClosing ? "Close job" : "Delete job"}
              size="large"
              type="primary"
              onClick={() =>
                state.isClosing
                  ? handleClosedJob(state.clickedId)
                  : handleDeletedJob(state.clickedId)
              }
            />
            <ButtonComponent
              className="cancel-btn"
              title="Cancel"
              size="large"
              type="default"
              onClick={() => handleOpenModal("isOpenUpdateJobModal", false)}
            />
          </div>
        }
      >
        <div className="modal-content-custom">
          <div className="title">
            {state.isClosing ? "Close this job?" : "Delete this job?"}
          </div>
          <div className="title-content">
            {state.isClosing ? (
              <>
                Are you sure you want to close this job posting? <br />
                Closing this job will stop accepting new applications and mark
                it as <br />
                "Closed" in your job listings.
              </>
            ) : (
              <>
                Are you sure you want to delete this job posting from your
                listings?
                <br />
                This action is permanent and cannot be undone
              </>
            )}
          </div>
        </div>
      </ModalComponent>
      <div className="company-profile">
        <div className="company-information">
          <div className="company-header">
            <div className="company-header-background">
              <img
                src={profile?.backgroundUrl || EmptyBackground}
                alt="background-profile"
              />
            </div>
            <div className="company-header-text">
              <div className="company-header-avatar">
                <img
                  src={profile?.logoUrl || EmptyCompanyLogo}
                  alt="logo-profile"
                  height={106}
                  width={106}
                />
              </div>
              <div className="company-header-info">
                <div className="company-header-info-name">{profile?.name}</div>
                {profile?.statusId && (
                  <Dropdown
                    overlayClassName="status-menu"
                    trigger={["click"]}
                    menu={{ items: statusDropdown }}
                    placement="bottomRight"
                  >
                    <div
                      className="company-header-info-status"
                      style={{
                        color: renderColorStatus(profile?.statusId).color,
                        backgroundColor: renderColorStatus(profile?.statusId)
                          .backgroundColor,
                      }}
                    >
                      <BellRinging
                        color={renderColorStatus(profile?.statusId).color}
                      />
                      <span>
                        {
                          _.find(
                            CompanyStatus,
                            (status) => status.id === profile?.statusId
                          )?.name
                        }
                      </span>
                      <CaretDown
                        size={14}
                        color={renderColorStatus(profile?.statusId).color}
                      />
                    </div>
                  </Dropdown>
                )}
              </div>
              {profile?.oneLineProfile && (
                <div className="company-header-description">
                  {profile.oneLineProfile}
                </div>
              )}
              <div className="company-header-meta-info">
                <ul>
                  {!_.isEmpty(profile?.industryTags) && (
                    <li>
                      {
                        profile.industryTags[profile.industryTags.length - 1]
                          ?.name
                      }
                    </li>
                  )}
                  {profile?.location?.city && (
                    <li>
                      {_.compact([
                        profile?.location?.city,
                        profile?.location?.state,
                        profile?.location?.country,
                      ]).join(", ")}
                    </li>
                  )}
                  {profile?.companySizeId && (
                    <li>
                      {
                        _.find(
                          CompanySize,
                          (size) => size.id === profile?.companySizeId
                        )?.name
                      }
                    </li>
                  )}
                </ul>
              </div>
              <div className="company-header-action">
                <ButtonComponent
                  className="post-job-btn"
                  onClick={() => {
                    if (isMobile) {
                      handleOpen();
                      return;
                    }
                    handleShowModalCreateJob();
                  }}
                  title="Post a job"
                  iconPosition="end"
                  icon={<PlusOutlined />}
                />
                <ButtonComponent
                  className="edit-profile-btn"
                  title="Edit profile"
                  iconPosition="end"
                  icon={<PencilSimple size={18} />}
                  onClick={() => {
                    if (isMobile) {
                      handleOpen();
                      return;
                    }
                    handleOpenModal("isOpenEditModal", true);
                    handleTimeOpen();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="company-detail">
            <div className="company-detail-about">
              <div className="profile-title">About</div>
              <div className="profile-detail">
                {profile?.companyOverview || (
                  <ButtonComponent
                    className="add-btn"
                    title="Add bio"
                    type="link"
                    onClick={() => {
                      if (isMobile) {
                        handleOpen();
                        return;
                      }
                      handleOpenModal("isOpenEditModal", true);
                      handleTimeOpen();
                    }}
                  />
                )}
              </div>
            </div>
            <div className="company-detail-website">
              <div className="profile-title">Website</div>
              <div className="profile-detail">
                {profile?.website ? (
                  <a
                    href={`https://${profile.website}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {profile.website}
                  </a>
                ) : (
                  <ButtonComponent
                    className="add-btn"
                    title="Add website"
                    type="link"
                    onClick={() => {
                      if (isMobile) {
                        handleOpen();
                        return;
                      }
                      handleOpenModal("isOpenEditModal", true);
                      handleTimeOpen();
                    }}
                  />
                )}
              </div>
            </div>
            <div className="company-detail-industry">
              <div className="profile-title">Company industry</div>
              <div className="profile-detail">
                {!_.isEmpty(profile?.industryTags) ? (
                  _.map(profile.industryTags, "name").join(", ")
                ) : (
                  <ButtonComponent
                    className="add-btn"
                    title="Add your industry"
                    type="link"
                    onClick={() => {
                      if (isMobile) {
                        handleOpen();
                        return;
                      }
                      handleOpenModal("isOpenEditModal", true);
                      handleTimeOpen();
                    }}
                  />
                )}
              </div>
            </div>
            <div className="company-detail-size">
              <div className="profile-title">Company size</div>
              <div className="profile-detail">
                {profile?.companySizeId ? (
                  _.find(
                    CompanySize,
                    (size) => size.id === profile?.companySizeId
                  )?.name
                ) : (
                  <ButtonComponent
                    className="add-btn"
                    title="Add company size"
                    type="link"
                    onClick={() => {
                      if (isMobile) {
                        handleOpen();
                        return;
                      }
                      handleOpenModal("isOpenEditModal", true);
                      handleTimeOpen();
                    }}
                  />
                )}
              </div>
            </div>
            {/* <div className="company-detail-address">
              <div className="profile-title">Address</div>
              <div className="profile-detail">
                {_.compact([
                  profile?.location?.city,
                  profile?.location?.country,
                ]).join(", ") || (
                  <ButtonComponent
                    className="add-btn"
                    title="Add address"
                    type="link"
                    onClick={() => {
                      handleOpenModal("isOpenEditModal", true);
                      handleTimeOpen();
                    }}
                  />
                )}
              </div>
            </div> */}
          </div>
          <div className="company-tags">
            <div className="company-culture-tag">
              <div className="title-tags">
                Company Culture
                <ButtonComponent
                  icon={
                    <img
                      src={
                        state.companyCultureTags?.length > 0
                          ? EditCircleIcon
                          : PlusCircleIcon
                      }
                      alt="plus-icon"
                    />
                  }
                  type="link"
                  onClick={() => {
                    handleShowModalAddTag("company-culture");
                  }}
                />
              </div>
              {state.companyCultureTags?.length > 0 && (
                <div className="detail-tags">
                  {_.map(state.companyCultureTags, (tag) => (
                    <Tag>{tag.name}</Tag>
                  ))}
                </div>
              )}
            </div>
            <div className="languages-tag">
              <div className="title-tags">
                Languages
                <ButtonComponent
                  icon={
                    <img
                      src={
                        state.languageTags?.length > 0
                          ? EditCircleIcon
                          : PlusCircleIcon
                      }
                      alt="plus-icon"
                    />
                  }
                  type="link"
                  onClick={() => {
                    handleShowModalAddTag("languages");
                  }}
                />
              </div>
              {state.languageTags?.length > 0 && (
                <div className="detail-tags">
                  {_.map(state.languageTags, (tag) => (
                    <Tag>{tag.name}</Tag>
                  ))}
                </div>
              )}
            </div>
            <div className="focus-areas-tag">
              <div className="title-tags">
                Focus Areas
                <ButtonComponent
                  icon={
                    <img
                      src={
                        state.focusAreaTags?.length > 0
                          ? EditCircleIcon
                          : PlusCircleIcon
                      }
                      alt="plus-icon"
                    />
                  }
                  type="link"
                  onClick={() => {
                    handleShowModalAddTag("focus-areas");
                  }}
                />
              </div>
              {state.focusAreaTags?.length > 0 && (
                <div className="detail-tags">
                  {_.map(state.focusAreaTags, (tag) => (
                    <Tag>{tag.name}</Tag>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* JobPostings */}
        <div className="company-job-posting">
          <div className="company-job-posting-title">
            <h3>Job posting</h3>
            {state.listJobPosting.length !== 0 && (
              <ButtonComponent
                className="edit-job-btn"
                icon={<img src={EditCircleIcon} alt="edit-icon" />}
                onClick={() => {
                  if (isMobile) {
                    handleOpen();
                    return;
                  }
                  handleOpenModal("isOpenEditJobModal", true);
                  handleTimeOpen();
                }}
              />
            )}
          </div>
          <div className="company-job-posting-content">
            {state.listJobPosting.length === 0 ? (
              <div className="empty-job">
                No job postings yet.
                <br />
                Click 'Post a Job' to get started.
              </div>
            ) : (
              _.map(state.listJobPosting.slice(0, 4), (job) => (
                <div className="company-job-posting-content-item">
                  <div className="content-item-logo">
                    <img
                      src={job?.logoUrl || EmptyCompanyLogo}
                      width={56}
                      height={56}
                      alt="logo"
                      className="active-profile"
                      onClick={() => {
                        if (isMobile) return;
                        getJobDetail(job.jobId);
                        handleTimeOpenViewJob();
                      }}
                    />
                  </div>
                  <div className="content-item-text">
                    <div>
                      <h4>
                        <span
                          className="active-profile"
                          onClick={() => {
                            if (isMobile) return;
                            getJobDetail(job.jobId);
                            handleTimeOpenViewJob();
                          }}
                        >
                          {job.jobTitle}
                        </span>
                        {job.negotiable && (
                          <span className="title-sub">{"  "}(Negotiable)</span>
                        )}
                      </h4>
                      <p className="content-item-text-location">
                        {_.compact([job.cityName, job.countryName]).join(", ")}
                      </p>
                      <p className="content-item-text-created-at">
                        <div
                          className={classNames(
                            "job-status",
                            job.jobStatusId === 1 ? "open" : "close"
                          )}
                        >
                          <div className="status-shape" />
                          <span>
                            {job.jobStatusId === 1 ? "Open" : "Close"}
                          </span>
                        </div>
                        <span>{calculateDaysDiff(job.postDateTime, true)}</span>
                      </p>
                      <p className="content-item-text-applicant">{`${
                        job.applicantCount
                      } ${
                        job.applicantCount > 1 ? "applicants" : "applicant"
                      }`}</p>
                    </div>
                    {!isMobile && (
                      <div className="content-item-action">
                        <Dropdown
                          overlayClassName="job-action"
                          trigger={["click"]}
                          menu={{
                            items:
                              job.jobStatusId === 1
                                ? actionDropdownClose
                                : actionDropdownReopen,
                          }}
                        >
                          <ButtonComponent
                            className="more-action-btn"
                            icon={
                              <img src={EllipsisIcon} alt="ellipsis-icon" />
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              setState({ clickedId: job.jobId });
                            }}
                          />
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
          {state.listJobPosting.length > 5 && (
            <div className="company-job-posting-show-all">
              <button
                onClick={() => {
                  if (isMobile) {
                    handleOpen();
                    return;
                  }
                  handleOpenModal("isOpenEditJobModal", true);
                  handleTimeOpen();
                }}
              >
                Show all job <ArrowRight />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
