import { Checkbox, Tooltip } from "antd";

interface ICheckBoxProps {
  tooltipContent: string;
  checkBoxContent: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const CheckBoxTooltip = ({
  checkBoxContent,
  tooltipContent,
  checked,
  onChange,
}: ICheckBoxProps) => {
  return (
    <Checkbox checked={checked} onChange={(e) => onChange(e.target.checked)}>
      <Tooltip placement="right" title={tooltipContent}>
        {checkBoxContent}
      </Tooltip>
    </Checkbox>
  );
};

export default CheckBoxTooltip;
