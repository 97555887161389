/* eslint-disable @typescript-eslint/no-explicit-any */

import Cookies from "js-cookie";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import DrawerComponent from "../../components/drawer/drawer";
import Header from "../../components/header/header";
import SideBar from "../../components/sideBar/sideBar";
import { PATH_ROUTER } from "../../constants";
import { isTokenExpired } from "../../utils";
import { useSetState } from "../../utils/customHook/useSetState";
import { safeNavigate } from "../../utils/helper";
import "./dashboard.s.scss";

const Dashboard = () => {
  const [state, setState] = useSetState({
    collapsed: false,
    openDrawer: false,
  });

  const toggleCollapsed = () => {
    setState((prevState: any) => ({ collapsed: !prevState.collapsed }));
  };

  const toggleDrawer = () => {
    setState({ openDrawer: !state.openDrawer });
  };

  useEffect(() => {
    const token = Cookies.get("user_token");
    if (!!token) {
      if (isTokenExpired(token)) {
        safeNavigate(PATH_ROUTER.SIGN_IN);
      }
    } else {
      safeNavigate(PATH_ROUTER.SIGN_IN);
    }
  }, []);

  return (
    <div className="dashboard-layout">
      <div className="dashboard-content">
        <Header toggleDrawer={toggleDrawer} />
        <div className="content">
          <SideBar
            collapsed={state.collapsed}
            className="nav-bar"
            toggleCollapsed={toggleCollapsed}
          />
          <div className="content-detail">
            <Outlet />
          </div>
        </div>
        <DrawerComponent
          open={state.openDrawer}
          placement="left"
          size="large"
          closeable={true}
          onclose={toggleDrawer}
          className="dashboard-drawer"
          content={
            <SideBar
              toggleCollapsed={toggleCollapsed}
              collapsed={false}
              className="drawer-side-bar"
              onSelect={toggleDrawer}
            />
          }
        />
      </div>
    </div>
  );
};

export default Dashboard;
