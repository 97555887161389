import { AxiosResponse } from "axios";
import { VolunteerWork } from "../interfaces/profile";
import axios from "./axiosConfig";

export const handleUpdateVolunteerWork = async (
  id: VolunteerWork["id"],
  requestBody: Partial<VolunteerWork>
): Promise<boolean | void> => {
  try {
    const response: AxiosResponse<boolean> = await axios.patch(
      `volunteer-works/${id}`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
