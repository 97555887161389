import { Career } from "../interfaces/profile";
import axios from "./axiosConfig";

export const fetchCandidateCareers = async (): Promise<Career[] | void> => {
  try {
    const response = await axios.get<Career[]>("careers");
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
