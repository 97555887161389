import { ProfileOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FORMAT_DATE } from "../../constants";
import { Notification } from "../../interfaces";
import loadingPage from "../../store/actions/loading";
import useActions from "../../utils/customHook/useActions";
import { safeNavigate } from "../../utils/helper";
import "./noticedPage.s.scss";

const NoticedPage = () => {
  const loadingPageAction = useActions(loadingPage);
  const meGotoRedux = useSelector((state: any) => state.goto.me);

  const [listNotification, setListNotification] = useState<Notification[]>([]);

  useEffect(() => {
    loadingPageAction();
    setListNotification(meGotoRedux.notifications);
  }, []);

  return (
    <div className="noticed-page">
      <div className="noticed-page-header">Notifications</div>
      <div className="noticed-page-content">
        {listNotification.map((item) => (
          <div
            className="noticed-page-item"
            onClick={() => safeNavigate(item.redirectPage)}
          >
            <div className="noticed-item-icon">
              <ProfileOutlined />
              {!item.viewed && <div className="shape-unread" />}
            </div>
            <div className="noticed-page-item-content">
              <div className="noticed-page-item-title">
                <h5>{item.title}</h5>
                <span>
                  {dayjs(item.createdDate).isValid()
                    ? dayjs(item.createdDate).format(FORMAT_DATE.MMM_D_YYYY)
                    : "Invalid date"}
                </span>
              </div>
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NoticedPage;
