import Cookies from "js-cookie";
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GoogleIcon, LinkedinIcon, Logo } from "../../assets/svg";
import ButtonComponent from "../../components/button/button";
import config from "../../config";
import { PATH_ROUTER } from "../../constants";
import { LOADING_TYPES } from "../../constants/loadingTypes";
import { Job } from "../../interfaces/home";
import { fetchCandidateProfile } from "../../services/fetchCandidateProfile";
import { fetchCompanyProfile } from "../../services/fetchCompanyProfile";
import { fetchListJob } from "../../services/fetchListJob";
import updateGotoData from "../../store/actions/goto";
import loadingPage from "../../store/actions/loading";
import updateReloadPage from "../../store/actions/reloadPage";
import { clearAllCookies } from "../../utils";
import auth from "../../utils/auth";
import useActions from "../../utils/customHook/useActions";
import { safeNavigate } from "../../utils/helper";
import "./signIn.s.scss";

const LoginPage = () => {
  const dispatch = useDispatch();
  const loadingPageAction = useActions(loadingPage);

  const linkedinAuthUrl = `${config.apiUrl}oauth2/authorization/linkedin`;
  const googleAuthUrl = `${config.apiUrl}oauth2/authorization/google`;

  const getListJobPosting = async (id: number) => {
    try {
      const requestBody = { companyId: id };
      const data = await fetchListJob(0, 100, requestBody);
      return data?.content || [];
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const getCompanyProfile = async () => {
    try {
      loadingPageAction(LOADING_TYPES.LOGGING_IN);
      let listJob: Job[] = [];
      const profile = await fetchCompanyProfile();
      auth.setAvatar(profile?.logoUrl);
      if (profile?.id) {
        listJob = await getListJobPosting(profile.id);
      }
      return { ...profile, listJob };
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const getCandidateProfile = async () => {
    try {
      loadingPageAction(LOADING_TYPES.LOGGING_IN);
      const profile = await fetchCandidateProfile();
      auth.setAvatar(profile?.avatarUrl);
      return profile;
    } catch (error) {
      console.error("Error:", error);
    } finally {
      loadingPageAction();
    }
  };

  const loginByLinkedin = () => {
    const width = 500;
    const height = 600;
    const left = window.screenX + window.outerWidth / 2 - width / 2;
    const top = window.screenY + window.outerHeight / 2 - height / 2;
    window.open(
      linkedinAuthUrl,
      "Login by Linkedin",
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };

  // *: For production
  const loginByGoogle = () => {
    const width = 500;
    const height = 600;
    const left = window.screenX + window.outerWidth / 2 - width / 2;
    const top = window.screenY + window.outerHeight / 2 - height / 2;
    window.open(
      googleAuthUrl,
      "Login by Google",
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };

  // *: For developer
  // const loginByGoogle = async () => {
  //   const idToken =
  //     "eyJhbGciOiJSUzI1NiIsImtpZCI6IjJjOGEyMGFmN2ZjOThmOTdmNDRiMTQyYjRkNWQwODg0ZWIwOTM3YzQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiI0MjIwNjY1MjU4OTEtZmFjYTFucTlrdTRzMm4yOG52aXU2ZGRzcGZwaGxjc2wuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiI0MjIwNjY1MjU4OTEtZmFjYTFucTlrdTRzMm4yOG52aXU2ZGRzcGZwaGxjc2wuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMDcxMjMzNTA1NzgyMTMwODAyNzkiLCJlbWFpbCI6InRodGllbjAxMTBAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiJrdWp1SEhnbzAwZTJadElxM2lnUDZRIiwibm9uY2UiOiJibVJGUUgtQ2Z2NEtLZkJTNzZpZ0RTazZ6UDZOTjhOSlg1ekVBWlN0azFNIiwibmFtZSI6IlRow6FpIEhvw6BuZyBUaeG6v24iLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jS1ZXenRXNXpMc2NKQ0lJbXNQZ3lnQWRSVnctWVV0UnI0SkJtckhNdFZxdW8xRXp3PXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IlRow6FpIEhvw6BuZyIsImZhbWlseV9uYW1lIjoiVGnhur9uIiwiaWF0IjoxNzM0MDY1NjMzLCJleHAiOjE3MzQwNjkyMzN9.Wf8Q1bQYJDqMW2vRM2LGOXUJHNmk2a4vCmV9A5mU1gmBS-S48SJsRtJGjag1QxjkfJKokbcBIymjRmIRWQEHWbnpszG64YX-4maMiiEK6W-sDydJHiJ5Sanlgw2aMC4GSN9yBpI6F03n8hOfuyUqkoeUK8hkinOlWO0Ve3G--_f33VGrTnq_KJYG_vFaAjVZXUNZXbJCG1344EWLIBpmiN8P0EBTxiGhzN5rgLDbJzE7t28IE27ZhKfagdiwR5modY5LFvf9XMn6mZmIALM0XGHdTfB_dF59vsmugBHMycQtqcQuDQeVuitnKhL0HduIxLzsQvw4V6PqBFnkStPKPw";
  //   const email = "test@likelion.net";
  //   Cookies.set("user_token", idToken);
  //   Cookies.set("user_email", encodeURIComponent(email), {
  //     path: "/",
  //     secure: true,
  //   });
  //   // Set accout type
  //   auth.setCandidateUser(true);
  //   const profile = await getCandidateProfile();
  //   // auth.setCompanyUser(true);
  //   // const profile = await getCompanyProfile();
  //   dispatch(updateGotoData("me", profile));
  //   dispatch(updateReloadPage("home", true));
  //   dispatch(updateReloadPage("application", true));
  //   safeNavigate(PATH_ROUTER.HOME);
  // };

  useEffect(() => {
    const handleReceiveData = async (event: MessageEvent) => {
      if (event.origin === window.location.origin) {
        const { params } = event.data;
        if (!_.isEmpty(params)) {
          Cookies.set("user_token", params.idToken, {
            path: "/",
            secure: true,
          });
          Cookies.set("user_email", encodeURIComponent(params.email), {
            path: "/",
            secure: true,
          });
          if (params.account_type !== "0") {
            const updateMeRedux = {};
            if (params.account_type === "1") {
              auth.setCandidateUser(true);
              const profile = await getCandidateProfile();
              _.assign(updateMeRedux, { ...profile });
            } else {
              auth.setCompanyUser(true);
              const profile = await getCompanyProfile();
              _.assign(updateMeRedux, { ...profile });
            }
            dispatch(updateGotoData("me", updateMeRedux));
            dispatch(updateReloadPage("home", true));
            dispatch(updateReloadPage("application", true));
            safeNavigate(PATH_ROUTER.HOME);
          } else {
            safeNavigate(PATH_ROUTER.SIGN_UP);
          }
        }
      }
    };
    loadingPageAction();
    window.addEventListener("message", handleReceiveData);

    return () => {
      window.removeEventListener("message", handleReceiveData);
    };
  }, []);

  useEffect(() => {
    const updateHomeRedux = {
      cityId: 0,
      stateId: 0,
      countryId: 1,
      listJob: [],
      jobDetail: {},
      count: null,
      page: 1,
    };
    const updateApplicationRedux = {
      statusId: -1,
      listApplication: [],
      applicationDetail: {},
      count: null,
    };
    dispatch(updateGotoData("home", updateHomeRedux));
    dispatch(updateGotoData("application", updateApplicationRedux));
    dispatch(updateGotoData("me", {}));
    dispatch(updateReloadPage("home", true));
    dispatch(updateReloadPage("application", true));
    auth.clearLocalStorage();
    clearAllCookies();
  }, []);

  return (
    <div className="background-login">
      <div className="header">
        <img src={Logo} alt="logo" />
      </div>
      <div className="login">
        <div className="login-title">Welcome to Lion-Up</div>
        <div className="login-subtitle">Please sign in or sign up below.</div>
        <ButtonComponent
          title="Continue with Linkedin"
          className="login-linkedin"
          size="large"
          onClick={loginByLinkedin}
          icon={<img src={LinkedinIcon} alt="linkedin" />}
        />
        <div className="login-space">
          <span>or</span>
        </div>
        <ButtonComponent
          title="Continue with Google"
          className="login-google"
          size="large"
          onClick={loginByGoogle}
          icon={<img src={GoogleIcon} alt="google" />}
        />
        <div className="login-policy">
          By signing up or singing in, you accept to our
          <br />
          <strong>
            <a
              href="https://www.notion.so/likelion/Terms-of-Service-12a44860a4f480beac1ef2577393bf3e"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </strong>{" "}
          and{" "}
          <strong>
            <a
              href="https://likelion.notion.site/LION-UP-s-Privacy-Policy-12a44860a4f480748424ddb6f85efd88"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </strong>
          .
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
