import { Tabs, type TabsProps } from "antd";
import classNames from "classnames";
import _ from "lodash";
import { useEffect, useRef } from "react";
import { EmptyCompanyLogo } from "../../../../assets/svg";
import ButtonComponent from "../../../../components/button/button";
import { calculateDaysDiff } from "../../../../utils";
import useMergeState from "../../../../utils/customHook/useMergeState";
import "./editJobPostingModal.s.scss";

interface EditJobPostingModalProps {
  listJobPosting: any[];
  handleOpenModal: (key: string, isOpen: boolean) => void;
  handleUpdateState: (key: string, value: any) => void;
  handleReopenJob: (id: number) => void;
  handleViewJobDetail: (id: number) => void;
  handleTimeOpenViewJob: () => void;
  timesOpen: number;
}

const EditJobPostingModal: React.FC<EditJobPostingModalProps> = ({
  listJobPosting,
  handleOpenModal,
  handleUpdateState,
  handleReopenJob,
  handleViewJobDetail,
  handleTimeOpenViewJob,
  timesOpen,
}) => {
  const listJobRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useMergeState({
    activeKey: "1",
    listAllJob: [],
    listOpenJob: [],
    listClosedJob: [],
  });

  const scrollToTop = () => {
    if (listJobRef.current) {
      listJobRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `All job (${state.listAllJob?.length})`,
      children: (
        <div ref={listJobRef} className="job-posting">
          <div className="company-job-posting-content">
            {_.map(state.listAllJob, (job) => (
              <div className="company-job-posting-content-item">
                <div className="content-item-logo">
                  <img
                    className="active-profile"
                    src={job?.logoUrl || EmptyCompanyLogo}
                    width={56}
                    height={56}
                    alt="logo"
                    onClick={() => {
                      handleViewJobDetail(job.jobId);
                      handleTimeOpenViewJob();
                    }}
                  />
                </div>
                <div className="content-item-text">
                  <div>
                    <h4
                      className="active-profile"
                      onClick={() => {
                        handleViewJobDetail(job.jobId);
                        handleTimeOpenViewJob();
                      }}
                    >
                      {job.jobTitle}
                    </h4>
                    <div className="content-item-text-location">
                      {_.compact([job.cityName, job.countryName]).join(", ")}
                    </div>
                    <div className="content-item-text-created-at">
                      <div
                        className={classNames(
                          "job-status",
                          job.jobStatusId === 1 ? "open" : "close"
                        )}
                      >
                        <div className="status-shape" />
                        <span>{job.jobStatusId === 1 ? "Open" : "Close"}</span>
                      </div>
                      <span>{calculateDaysDiff(job.postDateTime, true)}</span>
                    </div>
                    <div className="content-item-text-applicant">{`${
                      job.applicantCount
                    } ${
                      job.applicantCount > 1 ? "applicants" : "applicant"
                    }`}</div>
                  </div>
                  <div className="content-item-action">
                    <ButtonComponent
                      className="delete-btn"
                      title="Delete"
                      onClick={() => {
                        handleUpdateState("clickedId", job.jobId);
                        handleUpdateState("isClosing", false);
                        handleOpenModal("isOpenUpdateJobModal", true);
                      }}
                    />
                    {job.jobStatusId === 2 ? (
                      <ButtonComponent
                        className="reopen-btn"
                        title="Reopen"
                        onClick={() => {
                          handleReopenJob(job.jobId);
                          handleUpdateState("isClosing", null);
                        }}
                      />
                    ) : (
                      <ButtonComponent
                        className="close-btn"
                        title="Close"
                        onClick={() => {
                          handleUpdateState("clickedId", job.jobId);
                          handleUpdateState("isClosing", true);
                          handleOpenModal("isOpenUpdateJobModal", true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: `Open (${state.listOpenJob?.length})`,
      children: (
        <div ref={listJobRef} className="job-posting">
          <div className="company-job-posting-content">
            {_.map(state.listOpenJob, (job) => (
              <div className="company-job-posting-content-item">
                <div className="content-item-logo">
                  <img
                    className="active-profile"
                    src={job?.logoUrl || EmptyCompanyLogo}
                    width={56}
                    height={56}
                    alt="logo"
                    onClick={() => {
                      handleViewJobDetail(job.jobId);
                      handleTimeOpenViewJob();
                    }}
                  />
                </div>
                <div className="content-item-text">
                  <div>
                    <h4
                      className="active-profile"
                      onClick={() => {
                        handleViewJobDetail(job.jobId);
                        handleTimeOpenViewJob();
                      }}
                    >
                      {job.jobTitle}
                    </h4>
                    <div className="content-item-text-location">
                      {_.compact([job.cityName, job.countryName]).join(", ")}
                    </div>
                    <div className="content-item-text-created-at">
                      <div
                        className={classNames(
                          "job-status",
                          job.jobStatusId === 1 ? "open" : "close"
                        )}
                      >
                        <div className="status-shape" />
                        <span>{job.jobStatusId === 1 ? "Open" : "Close"}</span>
                      </div>
                      <span>{calculateDaysDiff(job.postDateTime, true)}</span>
                    </div>
                    <div className="content-item-text-applicant">{`${
                      job.applicantCount
                    } ${
                      job.applicantCount > 1 ? "applicants" : "applicant"
                    }`}</div>
                  </div>
                  <div className="content-item-action">
                    <ButtonComponent
                      className="delete-btn"
                      title="Delete"
                      onClick={() => {
                        handleUpdateState("clickedId", job.jobId);
                        handleUpdateState("isClosing", false);
                        handleOpenModal("isOpenUpdateJobModal", true);
                      }}
                    />
                    {job.jobStatusId === 2 ? (
                      <ButtonComponent
                        className="reopen-btn"
                        title="Reopen"
                        onClick={() => {
                          handleReopenJob(job.jobId);
                          handleUpdateState("isClosing", null);
                        }}
                      />
                    ) : (
                      <ButtonComponent
                        className="close-btn"
                        title="Close"
                        onClick={() => {
                          handleUpdateState("clickedId", job.jobId);
                          handleUpdateState("isClosing", true);
                          handleOpenModal("isOpenUpdateJobModal", true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: `Closed (${state.listClosedJob?.length})`,
      children: (
        <div ref={listJobRef} className="job-posting">
          <div className="company-job-posting-content">
            {_.map(state.listClosedJob, (job) => (
              <div className="company-job-posting-content-item">
                <div className="content-item-logo">
                  <img
                    className="active-profile"
                    src={job?.logoUrl || EmptyCompanyLogo}
                    width={56}
                    height={56}
                    alt="logo"
                    onClick={() => {
                      handleViewJobDetail(job.jobId);
                      handleTimeOpenViewJob();
                    }}
                  />
                </div>
                <div className="content-item-text">
                  <div>
                    <h4
                      className="active-profile"
                      onClick={() => {
                        handleViewJobDetail(job.jobId);
                        handleTimeOpenViewJob();
                      }}
                    >
                      {job.jobTitle}
                    </h4>
                    <div className="content-item-text-location">
                      {_.compact([job.cityName, job.countryName]).join(", ")}
                    </div>
                    <div className="content-item-text-created-at">
                      <div
                        className={classNames(
                          "job-status",
                          job.jobStatusId === 1 ? "open" : "close"
                        )}
                      >
                        <div className="status-shape" />
                        <span>{job.jobStatusId === 1 ? "Open" : "Close"}</span>
                      </div>
                      <span>{calculateDaysDiff(job.postDateTime, true)}</span>
                    </div>
                    <div className="content-item-text-applicant">{`${
                      job.applicantCount
                    } ${
                      job.applicantCount > 1 ? "applicants" : "applicant"
                    }`}</div>
                  </div>
                  <div className="content-item-action">
                    <ButtonComponent
                      className="delete-btn"
                      title="Delete"
                      onClick={() => {
                        handleUpdateState("clickedId", job.jobId);
                        handleUpdateState("isClosing", false);
                        handleOpenModal("isOpenUpdateJobModal", true);
                      }}
                    />
                    {job.jobStatusId === 2 ? (
                      <ButtonComponent
                        className="reopen-btn"
                        title="Reopen"
                        onClick={() => {
                          handleReopenJob(job.jobId);
                          handleUpdateState("isClosing", null);
                        }}
                      />
                    ) : (
                      <ButtonComponent
                        className="close-btn"
                        title="Close"
                        onClick={() => {
                          handleUpdateState("clickedId", job.jobId);
                          handleUpdateState("isClosing", true);
                          handleOpenModal("isOpenUpdateJobModal", true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setState({
      listAllJob: listJobPosting,
      listOpenJob: _.filter(listJobPosting, (item) => item.jobStatusId === 1),
      listClosedJob: _.filter(listJobPosting, (item) => item.jobStatusId !== 1),
    });
  }, [listJobPosting]);

  useEffect(() => {
    scrollToTop();
    setState({ activeKey: "1" });
  }, [timesOpen]);

  return (
    <div>
      <Tabs
        items={items}
        activeKey={state.activeKey}
        onChange={(key) => setState({ activeKey: key })}
      />
    </div>
  );
};

export default EditJobPostingModal;
