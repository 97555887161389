import { combineReducers } from "@reduxjs/toolkit";
import goto from "./goto";
import loading from "./loading";
import reloadPage from "./reloadPage";

const reducers = combineReducers({
  loading,
  goto,
  reloadPage,
});

const rootReducer = (state: any, action: any) => {
  return reducers(state, action);
};

export default rootReducer;
