import dayjs from "dayjs";
import _ from "lodash";
import { PostJobRequest } from "../../../../interfaces/profile";

const newCreateJobFormatter = (data: any) => {
  const dataFormatted: PostJobRequest = {
    title: {
      value: data.titleValue.trim(),
      negotiable: data.titleNegotiable,
    },
    jobTypeId: data.jobTypeId,
    startWorkingDate: {
      value: dayjs(data.startWorkingDateValue).toISOString(),
      negotiable: data.startWorkingDateNegotiable,
    },
    endWorkingDate: {
      value: dayjs(data.endWorkingDateValue).toISOString(),
      negotiable: data.endWorkingDateNegotiable,
    },
    workplaceType: {
      value: data.workplaceTypeValue,
      negotiable: data.workplaceTypeNegotiable,
    },
    hoursPerWeek: {
      value: Number(data.hoursPerWeekValue.trim()),
      negotiable: data.hoursPerWeekNegotiable,
    },
    tasks: {
      value: _.chain(data.tasksValue)
        .split("\n")
        .filter((line) => line.trim() !== "")
        .value(),
      negotiable: data.tasksNegotiable,
    },
    qualifications: _.chain(data.qualifications)
      .split("\n")
      .filter((line) => line.trim() !== "")
      .value(),
    aboutJob: data.aboutJob,
  };
  return dataFormatted;
};

export { newCreateJobFormatter };
