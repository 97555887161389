import axios from "./axiosConfig";

export const handleSignOut = async () => {
  try {
    const response = await axios.post(
      "logout",
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {}
};
