const JobTypeOptions = [
  {
    value: 1,
    label: "O-CA Program",
    info: {
      title:
        "The O-CA Program is an unpaid educational initiative and serves as a capstone program. It is free for both parties.",
    },
  },
  { value: 2, label: "Internship" },
  { value: 3, label: "Full-time" },
  { value: 4, label: "Part-time" },
];

const ApplicationTermsOptions = [
  { value: false, label: "Non-negotiable" },
  { value: true, label: "Negotiable" },
];

const WorkTypeOptions = [
  { value: 1, label: "Remote" },
  { value: 2, label: "Hybrid" },
  { value: 3, label: "Onsite" },
];

const ApplicationTab = [
  { value: -1, label: "All" },
  // { value: 0, label: "Interested" },
  { value: 1, label: "Applied" },
  { value: 2, label: "In Progress" },
  { value: 3, label: "Accepted" },
  { value: 5, label: "Closed" },
];

const CompanySize = [
  {
    id: 1,
    name: "1-10 employees",
  },
  {
    id: 2,
    name: "11-25 employees",
  },
  {
    id: 3,
    name: "26-50 employees",
  },
  {
    id: 4,
    name: "51-300 employees",
  },
  {
    id: 5,
    name: "300+ employees",
  },
];

const CompanyStatus = [
  { id: 1, name: "Now hiring" },
  { id: 2, name: "Hiring soon" },
  { id: 3, name: "Open to coffee chats" },
  { id: 4, name: "None" },
];

const StudentStatus = [
  { id: 1, name: "Looking for internship" },
  { id: 2, name: "Looking for O-CA program" },
  { id: 3, name: "Looking for Coffee Chat" },
  { id: 4, name: "Looking for Full-time job" },
  { id: 5, name: "Looking for Part-time job" },
];

export {
  ApplicationTab,
  ApplicationTermsOptions,
  CompanySize,
  CompanyStatus,
  JobTypeOptions,
  StudentStatus,
  WorkTypeOptions,
};
