import { CompanyProfile } from "../interfaces/profile";
import axios from "./axiosConfig";

export const fetchCompanyProfileId = async (
  companyId: number
): Promise<CompanyProfile | void> => {
  try {
    const response = await axios.get<CompanyProfile>(`companies/${companyId}`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
