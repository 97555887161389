import { QuestionCircleOutlined } from "@ant-design/icons";
import { Menu, MenuProps, message, Tooltip } from "antd";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  ApplicationActiveIcon,
  ApplicationInactiveIcon,
  HomeActiveIcon,
  HomeInactiveIcon,
  MiniSizeIcon,
  ProfileActiveIcon,
  ProfileInactiveIcon,
} from "../../assets/svg";
import { PATH_ROUTER } from "../../constants";
import "./sideBar.s.scss";
import { useCopy } from "../../utils/customHook/useCopy";
import { Copy } from "phosphor-react";
type MenuItem = Required<MenuProps>["items"][number];

interface IPropsSideBar {
  collapsed: boolean;
  className: string;
  onSelect?: MenuProps["onSelect"];
  toggleCollapsed: () => void;
}

const SideBar: React.FC<IPropsSideBar> = ({
  collapsed,
  className,
  onSelect,
  toggleCollapsed,
}) => {
  const location = useLocation();
  const { copied, copy } = useCopy();
  const [messageApi, contextHolder] = message.useMessage();
  const items: MenuItem[] = [
    {
      key: "1",
      label: (
        <NavLink
          className={({ isActive }) => {
            return isActive ? "active-item-sidebar" : "";
          }}
          to="home"
        >
          {location.pathname === PATH_ROUTER.HOME ? (
            <span className="anticon">
              <img
                width={24}
                height={24}
                src={HomeActiveIcon}
                alt="home-active-icon"
              />
            </span>
          ) : (
            <span className="anticon">
              <img
                width={24}
                height={24}
                src={HomeInactiveIcon}
                alt="home-inactive-icon"
              />
            </span>
          )}
          <span className="content-navlink">Home</span>
        </NavLink>
      ),
    },
    {
      key: "2",
      label: (
        <NavLink
          className={({ isActive }) => {
            return isActive || location.pathname == "/dash-board/"
              ? "active-item-sidebar"
              : "";
          }}
          to="/dash-board/application"
        >
          {location.pathname === PATH_ROUTER.APPLICATION ? (
            <span className="anticon">
              <img
                width={24}
                height={24}
                src={ApplicationActiveIcon}
                alt="application-active-icon"
              />
            </span>
          ) : (
            <span className="anticon">
              <img
                width={24}
                height={24}
                src={ApplicationInactiveIcon}
                alt="application-inactive-icon"
              />
            </span>
          )}
          <span className="content-navlink">Application</span>
        </NavLink>
      ),
    },
    {
      key: "3",
      label: (
        <NavLink
          className={({ isActive }) => {
            return isActive ? "active-item-sidebar" : "";
          }}
          to="/dash-board/profile"
        >
          {location.pathname === PATH_ROUTER.PROFILE ? (
            <span className="anticon">
              <img
                width={24}
                height={24}
                src={ProfileActiveIcon}
                alt="profile-active-icon"
              />
            </span>
          ) : (
            <span className="anticon">
              <img
                width={24}
                height={24}
                src={ProfileInactiveIcon}
                alt="profile-inactive-icon"
              />
            </span>
          )}
          <span className="content-navlink">Profile</span>
        </NavLink>
      ),
    },
    // {
    //   key: "4",
    //   icon:
    //     selectedKey === "4" ? (
    //       <BookmarkSimple size={16} weight="fill" />
    //     ) : (
    //       <BookmarkSimple size={16} />
    //     ),
    //   label: "Saved",
    // },
  ];
  return (
    <div className="menu-navbar">
      <Menu
        className={className}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        inlineCollapsed={collapsed}
        items={items}
        onSelect={onSelect}
      />
      <button onClick={toggleCollapsed} className="collapse-navbar">
        <img src={MiniSizeIcon} width={15} height={18} alt="" />
      </button>
      <div className="guideline-navbar">
        {collapsed ? (
          <Tooltip title="Guideline" placement="right">
            <a
              href="https://likelion.notion.site/LION-UP-Guidelines-13544860a4f4807eb7fdc846a6b2bb89"
              target="_blank"
              rel="noopener noreferrer"
              className="guide-icon"
            >
              <QuestionCircleOutlined />
            </a>
          </Tooltip>
        ) : (
          <div className="group-link">
            <Tooltip
              title={
                <div className="help-content">
                  <p className="help-text">Support/Contact email</p>
                  <div className="help-highlight-box">
                    <p className="help-highlight">simba@likelion.net</p>
                    <button
                      className="help-icon"
                      onClick={() => {
                        copy("simba@likelion.net");
                        messageApi.open({
                          type: "success",
                          content: "Support/Contact email copied!",
                        });
                      }}
                    >
                      <Copy />
                    </button>
                    {contextHolder}
                  </div>
                </div>
              }
              placement="top"
            >
              <p className="help">Help</p>
            </Tooltip>
            <a
              href="https://likelion.notion.site/What-is-the-O-CA-Program-d491f370b66c4fa084c6ae78843c17c2"
              target="_blank"
              rel="noopener noreferrer"
              className="program"
            >
              What is O-CA Program?
            </a>
            <a
              href="https://likelion.notion.site/LION-UP-Guidelines-13544860a4f4807eb7fdc846a6b2bb89"
              target="_blank"
              rel="noopener noreferrer"
              className="guide"
            >
              <QuestionCircleOutlined />
              Guideline
            </a>
            <p className="power-by">
              Powered by <span>LIKELION</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBar;
