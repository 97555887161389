import { AxiosResponse } from "axios";
import { ApplicationResponse } from "../interfaces/application";
import axios from "./axiosConfig";

export const fetchListApplication = async (
  page: number,
  pageSize: number,
  requestBody: { statusId: number }
): Promise<ApplicationResponse | void> => {
  try {
    const response: AxiosResponse<ApplicationResponse> = await axios.post(
      `applications?page=${page}&size=${pageSize}`,
      requestBody
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
